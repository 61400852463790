const ac_kr = {
    300001: '{timeout}초 후 다시 보내기',
    300002: '인증번호 보내기',
    300003: '보안 인증',
    300004: '계정 안전을 위해 아래 인증을 하세요.',
    300005: '인증 코드',
    300006: '핸드폰',
    300007: '핸드폰 인증번호',
    300008: '이메일 인증번호',
    300009: '제출하기',
    300010: '6자리 인증번호를 핸드폰{phone}로 보내드리겠습니다.',
    300011: 'SuperEx/Google/Microsoft의 인증 코드 6자리를 입력하세요.',
    300012: '6자리 인증번호를 이메일{email}로 보내드리겠습니다.',
    300013: '확인',
    300014: '취소',
    300015: '6자리 인증번호를 입력하세요.',
    300016: '공인 광고주',
    300017: '마진',
    300018: '인증된 사용자',
    300019: '일반 사용자',
    300020: '이메일 인증',
    300021: '모바일 인증',
    300022: '입증',
    300023: '광고주가 되다',
    300024: '릴리스 본드',
    300025: '30일 주문율',
    300026: '30일 주문 수량',
    300027: '평균 출시 시간',
    300028: '평균 릴리즈: 지난 30일 동안 코인 판매 시 돈을 받고 코인이 릴리즈되는 평균 확인 시간',
    300029: '분',
    300030: '평균 결제',
    300031: '생성된 계정',
    300032: '하늘',
    300033: '총계를 단수로',
    300034: ' 회',
    300035: '구입',
    300036: '팔다',
    300037: '현재까지 첫 거래',
    300038: '30일 거래량 상당액',
    300039: '총 거래량 상당',
    300040: 'P2P 결제 수단',
    300041: '결제수단: P2P 거래에서 디지털화폐 판매 시 고객님께서 추가하신 결제수단이 고객님의 결제수단으로 구매자에게 표시됩니다. 최대 {num}개의 결제 수단을 추가할 수 있습니다.',
    300042: '결제 수단 추가',
    300043: '편집하다',
    300044: '삭제',
    300045: '결제 수단 없음',
    300046: '이 결제 수단을 삭제하시겠습니까?',
    300047: '신원 확인을 완료하지 않았습니다.',
    300048: '거래 이용자의 계정 보안을 위해 먼저 본인 인증을 완료해주세요.',
    300049: '아직 인증되지 않음',
    300050: '인증하러 가기',
    300051: '결제 방법 선택',
    300052: '권장 결제 수단',
    300053: '데이터 없음',
    300054: '모든 결제 수단',
    300055: '검색',
    300056: '닉네임 설정',
    300057: '계정 닉네임을 설정해주세요.실명은 사용하지 않는 것을 권장합니다.닉네임의 길이는 2~10자입니다.',
    300058: '올리기',
    300059: '*(지원) PG/PEG/PNG/BMP 형식, 5MB 미만',
    300060: '다시 업로드',
    300061: '알림: 디지털 통화를 판매할 때 선택한 지불 방법이 구매자에게 표시됩니다. 정보가 올바르게 입력되었는지 확인하십시오.',
    300062: '결제 수단 추가를 선택 해제하시겠습니까?',
    300063: '수정을 종료하면 현재 수정 사항이 저장되지 않습니다.',
    300064: '신청서 제출 완료',
    300065: '독점 로고',
    300066: '거래 신뢰도를 높이는 광고주 전용 인증마크',
    300067: '일대일 서비스',
    300068: '거래 효율성을 높이기 위해 광고에 대한 일대일 서비스를 제공합니다.',
    300069: '지역 / 국가',
    300070: '게시 가능한 피아트 통화',
    300071: '신청 조건',
    300072: '신원 확인 완료',
    300073: 'P2P 계정의 {currency} 금액이 {num}보다 큽니다.',
    300074: '옮기다',
    300075: '신청 실패',
    300076: '즉시 적용',
    300077: '검토 중',
    300078: '채권이 풀렸나요?',
    300079: "1. 입금 해제 후 P2P 가맹점의 권익을 향유할 수 없습니다.",
    300080: '2. 예치금이 해제되면 진열대에 붙은 광고가 있을 수 없으며 거래되고 있는 주문도 있을 수 없습니다.',
    300081: '아직 아님',
    300082: '가맹점 신청 포털이 폐쇄되었습니다.',
    300083: '내가 참조',
    300084: '진행 중',
    300085: '모든 주문',
    300086: '통화',
    300087: '모든 통화',
    300088: '주문 유형',
    300089: '모두',
    300090: '구입',
    300091: '팔다',
    300092: '상태',
    300093: '모든 주',
    300094: '결제 대기 중',
    300095: '유급의',
    300096: '완성된',
    300097: '취소 된',
    300098: '항소',
    300099: '데이트',
    300100: '필터링 재설정',
    300101: '',
    300102: '주문하다',
    300103: '광고주 세부정보',
    300104: '사용자 상세 정보',
    300105: '온라인 구매',
    300106: '상인에게서 구매',
    300107: '상인에게 판매',
    300108: '가격',
    300109: '결제수단',
    300110: '상한/수량',
    300111: '거래',
    300112: '수량',
    300113: '한도액',
    300114: '다루기 위해서',
    300115: '네',
    300116: '유형/통화',
    300117: '일시불',
    300118: '별명',
    300119: '운영하다',
    300120: '공인 광고주 신청',
    300121: '기본 정보',
    300122: '이름',
    300123: '전화',
    300124: '내 전화번호를 입력하세요.',
    300125: '지역번호',
    300126: '우편',
    300127: '이메일 주소를 입력해주세요',
    300128: '긴급 연락처',
    300129: '비상연락처를 입력해주세요',
    300130: '비상연락처',
    300131: '긴급연락처를 입력해주세요',
    300132: '나와의 관계',
    300133: '동급생',
    300134: '가족',
    300135: '다른',
    300136: '거주지 주소',
    300137: '영구 거주지 주소를 입력하세요.',
    300138: "전화 : 직원이 일일 민원 접수를 처리하고 고객님과 신속하게 정보를 확인하기 위해 사용합니다. 이 전화를 열어두시기 바랍니다.",
    300139: '긴급연락처 및 거주지 주소 : 안전한 거래를 위해 광고주의 KYC 정보를 추가로 확인하기 위해 사용됩니다.',
    300140: '개인 신분증',
    300141: '예시',
    300142: '*사진 촬영 시 신분증 앞부분을 잡고 팔과 상체를 촬영하세요. 얼굴과 신분증의 모든 정보가 선명해 가려지지 않습니다.',
    300143: '*.jpg/.jpeg/.png 형식만 지원하며, 최대 크기는 10M 이하입니다.',
    300144: '부동산 증명서/임대차 계약서/수도 및 전기 요금 목록을 업로드하십시오. (셋 중 택1)',
    300145: '주소 증명',
    300146: '*재산 증명서, 임대 계약서 및 공과금 청구서는 귀하의 주소 정보가 진실하고 유효한지 확인하기 위한 것입니다. 증명서의 주소가 기본 정보의 영구 주소와 다른 경우 이유를 설명하도록 요청합니다.',
    300147: '*부동산 증명서에는 소유자의 이름, 재산의 소재지 및 발급 기관의 인감이 표시되어야 하며, 임대 계약서에는 임차인의 이름, 임차인의 주소, 소유자의 인감 및 인감이 표시될 수 있습니다. 양 당사자의 ID 번호, 수도 및 전기 요금 목록이 최근의 것이어야 하며 지불 주소가 보일 수 있어야 합니다. 업로드된 이미지는 장애물이 없고 반사 없이 명확하게 보여야 합니다.',
    300148: '영상인증',
    300149: '증명서 앞부분을 잡고 아래의 예시 문구를 소리내어 읽어준 후 동영상을 녹화해 주세요. (음성 및 이미지가 선명한지 확인)',
    300150: '나(이름), 인증서 번호(인증 번호), 나의 자금 출처는 합법적이고 신뢰할 수 있으며 비트코인과 같은 디지털 자산을 자발적으로 거래합니다. 나는 디지털 통화와 잠재적 위험을 완전히 이해합니다. 나는 위험에 저항할 수 있는 능력이 있으며 의지가 있습니다. 모든 위험을 감수합니다. 또한 본인은 이 계정의 모든 작업이 본인에 의해 수행되며 이 계정에서 발생하는 모든 법적 책임은 본인이 개인적으로 부담함을 확인합니다.',
    300151: '인증 영상 올려주세요',
    300152: '* 최대 동영상 지원은 100M, 권장 재생 시간은 3분 이내, 해상도는 360P 이상',
    300153: '* 비디오 형식은 .MP4/.WMV/.DGG/.MOV를 지원합니다.',
    300154: '친절한 팁',
    300155: '{currency} 잔액이 {num} 미만입니다.',
    300156: '신청서를 제출하다',
    300157: '<span>&nbsp;{num}&nbsp;{currency}&nbsp;</span>를 광고주의 보증금으로 동결하는 데 동의하고 <a href="https://support.superex.com/hc/en-001/articles/15269117544345">《"광고 퍼블리셔 계약"》</a>에 동의합니다.',
    // 300158: '《广告发布方协议》',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',
    300161: '평균 지불: 지난 30일 동안 디지털 통화 구매에 대한 평균 지불 시간',
    300162: '취급 수수료 0',
    300163: '최근 일주일',
    300164: '최근 1개월',
    300165: '최근 3개월',
    300166: '거래를 시작하기 전에 본인 확인을 완료하십시오.',
    300167: '오늘 {num} 번 주문이 취소되어 거래를 계속할 수 없습니다. UTC{Symbol}{string}포인트 이후에 다시 시도해 주세요.',
    300168: '보류 중인 주문이 상한선({num})에 도달했습니다. 진행 중인 주문을 먼저 완료하십시오.',
    300169: '광고주가 보류 중인 주문 한도에 도달했습니다. 나중에 작업하거나 다른 광고를 시도하십시오.',
    300170: '광고주의 요구사항을 충족하지 못했습니다. 다른 광고를 시도하십시오.',
    300171: '설정 성공',
    300172: '상대방 상세 정보 보기',
    300173: '서로 연락',
    300174: 'gif/jpg/png 형식의 그림만 지원',
    300175: '사진 업로드 크기는 5MB를 초과할 수 없습니다!',
    300176: '올리기 성공',
    300177: '업로드에 실패했습니다. 나중에 다시 시도하십시오.',
    300178: '닉네임을 설정해 주세요.',
    300179: '닉네임 길이 2-10자',
    300180: '최대 {num}개의 결제 수단을 추가할 수 있습니다.',
    300181: '휴대폰 번호 형식이 잘못되었습니다.',
    300182: '메일박스 형식이 잘못되었습니다.',
    300183: '2-30자 필요',
    300184: '주소 길이는 5-120자 사이여야 합니다.',
    300185: '본인과의 관계를 선택하세요',
    300186: '신분증 사진이 업로드되지 않았습니다.',
    300187: '주소 증명 사진이 업로드되지 않았습니다',
    300188: '비디오 인증이 업로드되지 않았습니다.',
    300189: '커밋 성공',
    300190: '올바른 비디오 형식을 업로드하십시오.',
    300191: '동영상 크기는 100 MB를 초과할 수 없습니다.',
    300192: '올리기 실패',
    300193: '{num}분',
    300194: '거래를 시작하기 전에 본인 확인을 완료하십시오.',
    300195: '복사 성공',
    300196: '복사 실패',
    300197: '시간',
    300198: '광고 허가',
    300199: '광고주는 광고를 자유롭게 게시할 수 있으므로 구매 및 판매가 보다 유연하고 편리해집니다.',
    300200: '서로 연락',
    300201: '여권사진을 올려주세요',
    300202: '성공적으로 삭제',
    300203: '업로드',
    300204: '아래의 결제수단으로 판매자에게 결제가 되었는지 확인해주세요.',
    300205: '주문 번호',
    300206: '생성 시간',
    300207: '총 가격',
    300208: '{expiresTimeMinute}분 이내에 광고주에게 결제해 주시면 초과 주문은 자동으로 취소됩니다.',
    300209: '송금 완료 후 반드시 아래 버튼을 클릭하여 판매자에게 대금 수령을 알리십시오. 그렇지 않으면 결제 시간이 만료된 후 주문이 자동으로 취소되어 자산 손실이 발생합니다!',
    300210: '결제 완료, 다음 단계',
    300211: '주문 취소',
    300212: '구매자 결제 대기 중',
    300213: '구매자 지불은 <span style="color: #ffc72b;"> 3분 </span> 이내에 수신될 것으로 예상됩니다.',
    300214: '입금 확인했습니다',
    300215: '거래에 문제가 있을 경우 상대방에게 직접 연락하여 처리하는 것이 가장 효과적인 방법입니다. 양 당사자의 협상 및 확인을 위해 채팅 창에 지불 바우처 및 계정 정보를 업로드할 수 있습니다.',
    300216: '항소 통지',
    300217: '민원을 제기하는 것은 귀하의 자산을 직접 복구하는 데 도움이 되지 않으므로 고객 서비스가 개입하여 상황에 따라 처리할 때까지 기다리십시오.',
    300218: '민원처리는 고객센터 개입 후 12~48시간이 소요되오니 민원처리 진행상황에 유의하시기 바랍니다.',
    300219: '악의적인 불만은 플랫폼의 정상적인 운영을 방해하는 행위이며, 상황이 심각할 경우 계정이 정지됩니다.',
    300220: '다음은 판매자의 결제수단으로, 결제시에는 플랫폼을 떠나 실명계좌를 이용하여 상대방에게 송금을 하셔야 합니다.',
    300221: '결제 확인',
    300222: '아래의 결제수단으로 판매자에게 결제가 되었는지 확인해주세요. 지불하지 않은 경우 지불을 클릭하지 마십시오.',
    300223: '알림: 본인은 지불할 때 이체를 독립적으로 완료하기 위해 플랫폼을 떠나야 하며 SuperEx가 자동으로 금액을 공제하지 않는다는 것을 이해합니다.',
    300224: 'SuperEx 실명인증과 일치하는 계좌로 결제를 진행하였습니다.',
    300225: '취소',
    300226: '확신하는',
    300227: '&nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp; 안에 결제해주세요',
    300228: '지불 방법',
    300229: '판매자가 결제를 확인하기를 기다리는 중',
    300230: '결제를 완료하셨다면 상대방에게 연락하여 확인 부탁드립니다.',
    300231: '항소',
    300232: '취소',
    300233: '반드시 수취계좌에 로그인하여 "사용가능잔액"에 입금되었는지 확인하시기 바랍니다.',
    300234: '결제가 제대로 된 것을 확인했습니다',
    300235: '결제를 확인해주세요',
    300236: '주문 항소',
    300237: '상대방이 결제했습니다. 영수증을 확인하세요.',
    300238: '뒤로 돌아가기',
    300239: '{quantity} {currency} 매도에 성공했습니다.',
    300240: '마치다',
    300241: '자산 보기',
    300242: '성공적인 거래',
    300243: '您已成功',
    300244: '지불 방법',
    300245: '지불 방법',
    300246: '알람',
    300247: '1. 다른 사람의 구매를 돕는 사기, 돈세탁, 고리대금업, 모금사업, 차익거래, 온라인 데이트, 불법 및 범죄 행위에 참여하지 마십시오. 2. 불법자금(도박, 펀드거래, 사기 등)을 이용한 디지털화폐 거래를 금지합니다.',
    300248: '<span style="color:#FFC72B">{appealTime}</span>이후에 항소할 수 있습니다.',
    300249: '1. 판매자에게 이미 대금을 지불하셨다면 거래를 취소하지 마시기 바랍니다.',
    300250: '2. 취소 규정: 구매자가 당일 취소 건 {num} 건을 누적하여 같은 날 다른 주문을 할 수 없습니다',
    300251: '항소 사유는 양 당사자와 고객 서비스에 동시에 표시되므로 개인 정보 보호에 유의하십시오.',
    300252: '어떠한 이유로든 코인의 방출을 강요하지 마시고, 손실을 방지하기 위해 결제 확인 후 디지털 자산을 방출하십시오.',
    300253: '항소 이유(필수)',
    300254: '담당자',
    300255: '연락처 정보',
    300256: '항소하고 싶다',
    300257: '항소 이유를 선택하세요',
    300258: '불만 설명',
    300259: '가능한 한 완전하게 불만 사항을 설명하십시오.',
    300260: '이메일 주소를 입력하세요',
    300261: '주문이 취소되었습니다.',
    300262: '결제 시간이 초과되어 주문이 자동으로 취소되었습니다.',
    300263: '결제 수단을 볼 수 없습니다.',
    300264: '의심스러운 경우 고객 서비스에 문의하십시오.',
    300265: '항소가 철회되었습니까?',
    300266: '1. 이의신청 철회 후에도 거래는 계속될 수 있으며, 주문취소는 되지 않습니다.',
    300267: '2. 다른 문제가 발생하면 다시 이의신청을 할 수 있습니다.',
    300268: '필요하지 않다',
    300269: '항소 철회',
    300270: '{appealMinMinute}분 이내에 항소할 수 없음',
    300271: '{quantity}{currency}를 성공적으로 구매하셨습니다.',
    300272: '{quantity}{currency} 매도에 성공했습니다.',
    300273: '불만사항 설명은 비워둘 수 없습니다.',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_kr