/*
 * @Description: gsap 插件
 * @Author: F-Stone
 * @LastEditTime: 2023-10-16 14:04:36
 */
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { DrawSVGPlugin } from "./gsap-draw-svg-plugin";
import { gsap } from "gsap";

gsap.registerPlugin(
  ScrollToPlugin,
  ScrollTrigger,
  MotionPathPlugin,
  DrawSVGPlugin,
  TextPlugin
);

export { gsap, ScrollTrigger };
