import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: () => import('@/views/buycoin'),
    redirect: '/buy_USDT',
    children: [
      {
        path: '/buy_USDT',
        name: 'coin',
        component: () => import('@/views/buycoin/buycoin')
      },
      {
        path: '/order/:action/:source', // 下单
        component: () => import('@/views/buycoin/order')
      },
      {
        path: '/myads',
        component: () => import('@/views/myads/myads')
      },
      {
        path: '/complaint/:action/:source', // 申诉
        component: () => import('@/views/buycoin/complaint')
      },
    ]
  },
  {
    path: '/releaseAdvertising/:id?',
    component: () => import('@/views/myads/release')
  },
  {
    path:'/authAdvertising',
    component:()=>import('@/views/authAdvertising')
  },
  {
    path: '/becomeMember',
    component: () => import('@/views/becomeMember')
  },
  {
    path: '/myorder',
    component: () => import("@/views/order")
  },
  {
    path: '/addetail/:id',
    component: () => import('@/views/order/addetail')
  },
  {
    path: '/usercenter',
    component: () => import('@/views/order/userormerchantcenter')
  },
  {
    path: '/payment',
    component: () => import('@/views/order/paymentmethods')
  },
  {
    path: '/thirdParty',
    name: 'thirdParty',
    component: () => import('@/views/thirdParty')
  },
  {
    path: '*',
    name: 'errorPage',
    component: () =>
        import('../views/errorPage')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next)=>{
  if(to.name!==from.name){
    if(to.query.invitationCode){
      store.dispatch('setInvitationCode',to.query.invitationCode)
    }
  }
  next()
})
export default router
