import {getCookie,setCookie} from "@/utils/common";
let languageList = ['ru','en','en-GB','en-US','en-AU','en-CA','en-IN','en-IE','en-NZ','en-ZA','en-GB-oxendict','vi','fr','fr-CA','fr-FR','fr-CH','uk','fa','ja','es','es-AR','es-CL','es-CO','es-CR','es-HN','es-419','es-MX','es-PE','es-ES','es-US','es-UY','es-VE','ko','zh','zh-CN','zh-HK','zh-TW']
let currentLanguage = getCookie('language')
if(!currentLanguage){
    let currentlang = window.navigator.language.split('-')[0]
    let data = languageList.find(item=>item === currentlang ) || 'en'
    if(data == 'zh'){
        setCookie('language','zh-TC')
    }else{
        setCookie('language',data)
    }
}
