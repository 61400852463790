const ac_zh = {
    300001: '{timeout}秒后重发',
    300002: '发送验证码',
    300003: '安全验证',
    300004: '为了您的账户安全,请完成以下验证操作',
    300005: '身份验证码',
    300006: '手机',
    300007: '短信验证码',
    300008: '邮箱验证码',
    300009: '提交',
    300010: '6位验证码将发送至您的手机{phone}',
    300011: '请输入6位SuperEx/谷歌/微软验证码',
    300012: '6位验证码将发送至您的邮箱{email}',
    300013: '确定',
    300014: '取消',
    300015: '请输入6位验证码',

    300016: '认证广告方',
    300017: '保证金',
    300018: '认证用户',
    300019: '普通用户',
    300020: '邮箱认证',
    300021: '手机认证',
    300022: '身份认证',
    300023: '成为广告方',
    300024: '释放保证金',
    300025: '30日成单率',
    300026: '30日成单数',
    300027: '平均放行',
    300028: '平均放行：近30日卖币时收款后放币的平均确认时间',
    300029: '分钟',
    300030: '平均付款',
    300031: '账户已创建',
    300032: '天',
    300033: '总成单数',
    300034: '次',
    300035: '买',
    300036: '卖',
    300037: '首次交易至今',
    300038: '30日交易折合',
    300039: '总交易量折合',
    300040: 'P2P收款方式',
    300041: '收款方式：您添加的收款方式将在P2P交易出售数字货币时向买方展示作为您的收款方式，请务必使用您本人的实名账户确保买方可以顺利给您转账。您最多可添加{num}种收款方式。',
    300042: '添加收款方式',
    300043: '编辑',
    300044: '删除',
    300045: '暂无收款方式',
    300046: '确定删除此收款方式吗？',
    300047: '您尚未完成身份认证',
    300048: '为了您与您交易用户的账号安全，请先完成身份认证',
    300049: '暂不认证',
    300050: '去认证',
    300051: '选择收款方式',
    300052: '推荐收款方式',
    300053: '暂无数据',
    300054: '全部收款方式',
    300055: '搜索',
    300056: '设置昵称',
    300057: '请设置您的账号昵称,建议不要使用真实姓名.昵称长度2~10个字符.',

    300058: '上传',
    300059: '* (支持) PG/PEG/PNG/BMP格式,小于5MB',
    300060: '重新上传',
    300061: '温馨提示：当您出售数字货币时，您选择的收款方式将向买方展示。请确认信息填写准确无误',
    300062: '是否要退出添加收款方式?',
    300063: '退出修改后,您当前的编辑将不被保存',
    300064: '申请已提交',

    300065: '专属标识',
    300066: '广告方专属认证标识,增强交易信任',
    300067: '一对一服务',
    300068: '我们针对广告提供一对一服务,提高交易效率',
    300069: '所在地区/国家',
    300070: '可发布的法币',
    300071: '申请条件',
    300072: '完成身份验证',
    300073: 'P2P账户{currency}数量大于{num}',
    300074: '去划转',
    300075: '申请失败',
    300076: '立即申请',
    300077: '审核中',
    300078: '是否释放保证金?',
    300079: "1.释放保证金后，将不会享有P2P商户权益",
    300080: '2.释放保证金时不能有已上架广告，也不能有正在交易的订单',
    300081: '暂时不要',
    300082: '商户申请入口已关闭',
    300083: '我知道了',

    300084: '进行中',
    300085: '全部订单',
    300086: '币种',
    300087: '所有币种',
    300088: '订单类型',
    300089: '全部',
    300090: '购买',
    300091: '出售',
    300092: '状态',
    300093: '所有状态',
    300094: '待付款',
    300095: '已付款',
    300096: '已完成',
    300097: '已取消',
    300098: '申诉中',
    300099: '日期',
    300100: '重置筛选',
    // 300101: '暂无数据',
    300102: '订单',
    300103: '广告主详情',
    300104: '用户详情',
    300105: '在线购买',
    300106: '向Ta购买',
    300107: '向Ta出售',
    300108: '价格',
    300109: '支付方式',
    300110: '限额/数量',
    300111: '交易',
    300112: '数量',
    300113: '限额',
    300114: '去处理',
    300115: '好的',
    300116: '类型/币种',
    300117: '总额',
    300118: '对方昵称',
    300119: '操作',

    300120: '认证广告方申请',
    300121: '基本信息',
    300122: '姓名',
    300123: '电话',
    300124: '请输入本人电话',
    300125: '区号',
    300126: '邮箱',
    300127: '请输入邮箱',
    300128: '紧急联系人',
    300129: '请输入紧急联系人',
    300130: '紧急联系人电话',
    300131: '请输入紧急联系人电话',
    300132: '与本人关系',
    300133: '同学',
    300134: '家人',
    300135: '其他',
    300136: '常住地址',
    300137: '请输入您本人常住地址',
    300138: '电话：用于工作人员处理日常申诉订单与您快速核实信息，请务必保持该电话畅通。',
    300139: '紧急联系人与常住地址：用于进一步核实广告方KYC信息，确保交易更安全。',
    300140: '身份证明',
    300141: '示例',
    300142: '*拍摄时，手持本人证件正面，将持证的手臂和上半身整个拍进照片，脸部、证件上所有信息清晰且不能被遮挡',
    300143: '*仅支持 .jpg/.jpeg/.png格式，最大不超过10M',
    300144: '请上传房产证/租房合同/水电费清单（三选一）',
    300145: '地址证明',
    300146: '*房产证、租房合同、水电费清单是为了确保您的地址信息真实有效；证件中地址如与基本信息中所填常住地址不同，我们会要求您说明原因',
    300147: '*房产证需可见房主姓名、房产地及填发单位盖章；租房合同可见租房人姓名、租房地址、业主盖章及双方身份证号；水电费清单需近日期并可见缴费地址，请确保上传的图片无遮挡且清晰可见、无反光。',
    300148: '视频认证',
    300149: '请手持证件正面，朗读以下范文并进行视频录制（保证声音和影像清晰)',
    300150: '本人（姓名），证件号（证件号码），我的资金来源合法可靠，自愿交易比特币等数字资产，本人充分了解数字货币及潜在风险，本人具有抗风险的能力并愿意承担一切风险。另外，我确定此账号所有操作均为本人操作，该账号所产生的一切法律责任，由我个人承担。',
    300151: '请上传认证视频',
    300152: '*视频最大支持100M，建议时长3分钟以下，分辨率超过360P',
    300153: '*视频格式支持.MP4/.OGG/.WEBM',
    300154: '温馨提示',
    300155: '您的{currency}余额不足{num}',
    300156: '提交申请',
    300157: '同意冻结<span>&nbsp;{num}&nbsp;{currency}&nbsp;</span>作为广告方保证金，且同意<a>《广告发布方协议》</a>',
    // 300158: '《广告发布方协议》',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',

    300161: '平均付款:近30日购买数字货币平均付款的时间',
    300162: '0手续费',
    300163: '最近7天',
    300164: '最近1月',
    300165: '最近3月',
    300166: '开始交易前，请先完成身份认证',
    300167: '您的今日订单取消次数已达{num}次，无法继续交易，请在UTC{Symbol}{string}点之后再尝试。',
    300168: '您的待处理订单已达上限（{num}个）。请先完成正在进行的订单',
    300169: '该广告主待处理订单已达上限，请稍后操作或尝试其他广告。',
    300170: '您未满足广告方要求，请尝试其他广告',
    300171: '设置成功',
    300172: '查看对方详情',
    300173: '联系对方',
    300174: '仅支持jpg/jpeg/png格式的图片',
    300175: '上传图片大小不能超过 5MB!',
    300176: '上传成功',
    300177: '上传失败，请稍后重试',
    300178: '请设置昵称',
    300179: '昵称长度为2-10个字符之间',
    300180: '最多可添加{num}种收款方式',
    300181: '手机号码格式不正确',
    300182: '邮箱格式不正确',
    300183: '需2-30个字符',
    300184: '地址长度需在5-120个字符之间',
    300185: '请选择与本人关系',
    300186: '身份证明照片未上传',
    300187: '地址证明照片未上传',
    300188: '视频认证未上传',
    300189: '提交成功',
    300190: '请上传正确的视频格式',
    300191: '视频大小不能超过100MB',
    300192: '上传失败',
    300193: '{num}分钟',
    300194: '开始交易前，请先完成身份认证',
    300195: '复制成功',
    300196: '复制失败',
    300197: '时间',
    300198: '广告发布权限',
    300199: '广告方可以自由发布广告，买卖更灵活、更便捷',
    300200: '联系',
    300201: '请上传手持证件照片',
    300202: '删除成功',
    300203: '上传中',
    300204: '请确认已通过以下支付方式向卖家付款',
    300205: '订单号',
    300206: '创建时间',
    300207: '总价',
    300208: '请在{expiresTimeMinute}分钟内付款给广告方，超时订单将自动取消',
    300209: '转账完成后请务必点击下方按钮，通知卖家收款，否则付款时间到期后订单会自动取消，造成资产损失！',
    300210: '我已完成付款，下一步',
    300211: '取消订单',
    300212: '等待买家付款',
    300213: '预计<span style="color: #ffc72b;"> 3分钟 </span>内收到买家付款',
    300214: '我已确认收款',
    300215: '如存在交易问题，直接与对方联系处理是最有效的方式。您可在聊天窗口上传付款凭证及账号信息，以供双方协商核实。',
    300216: '申诉须知',
    300217: '发起申诉无法帮您直接追回资产，请等待客服介入后视情况处理。',
    300218: '客服介入后需要12-48小时的时间来处理申诉，烦请关注申诉处理进度。',
    300219: '恶意申诉属于扰乱平台正常运营秩序的行为，情节严重将冻结账户。',
    300220: '下面是卖家的收款方式，付款时需要您离开平台，使用本人实名账户向对方转账。',
    300221: '确认付款',
    300222: '请确认已通过以下支付方式向卖家付款。若未付款，请勿点击已付款。',
    300223: '提示： 我明白付款时需要离开平台自主完成转账，SuperEx不会自动扣款。',
    300224: '我已使用与SuperEx实名验证相匹配的账户进行付款。',
    300225: '取消',
    300226: '确定',
    300227: '请於&nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp;内付款',
    300228: '付款方式',
    300229: '等待卖家确认收款',
    300230: '如您已经完成付款，请联系对方确认',
    300231: '申诉',
    300232: '撤销',
    300233: '请务必登录收款账号确认“可用余额”中有该笔款项到账。',
    300234: '我已确认收款无误',
    300235: '请确认收款',
    300236: '订单申诉中',
    300237: '对方已付款，请确认收款',
    300238: '返回',
    300239: '您已成功出售{quantity}{currency}',
    300240: '完成',
    300241: '查看资产',
    300242: '交易成功',
    300243: '您已成功',
    300244: '收款方式',
    300245: '付款方式',
    300246: '安全提示',
    300247: '1、不参与帮人代买、洗钱、高利贷、资金盘项目和搬砖套利、网络交友等骗局和任何违法犯罪行为；2、禁止将任何不法资金（如赌博、资金盘、诈骗等）用于数字货币交易行为。',
    300248: '<span style="color:#FFC72B">{appealTime}</span>后可申诉',
    300249: '1.如果您已经向卖家付款，请千万不要取消交易',
    300250: '2.取消规则：买家当日累计{num}笔取消，会限制当日买入功能',
    300251: '申诉原因将同时展示给双方和客服，请注意隐私信息保护。',
    300252: '请不要相信任何催促放币的理由，确认收款项后再释放数字资产，避免造成损失',
    300253: '申诉理由（必填）',
    300254: '联系人',
    300255: '联系方式',
    300256: '我要申诉',
    300257: '请选择申诉理由',
    300258: '申诉描述',
    300259: '请尽可能完整的描述申诉信息',
    300260: '请输入邮件地址',
    300261: '订单已取消',
    300262: '您付款超时，订单已自动取消',
    300263: '不可查看支付方式',
    300264: '如有疑问请联系客服',
    300265: '是否撤销申诉？',
    300266: '1.撤销申诉后可以继续交易，订单不会被取消。',
    300267: '2.如遇到其他问题，您可以再次申诉。',
    300268: '暂不需要',
    300269: '撤销申诉',
    300270: '{appealMinMinute}分钟内不能申诉',
    300271: '您已成功购买{quantity}{currency}',
    300272: '您已成功出售{quantity}{currency}',
    300273: '申诉描述不可为空',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_zh;