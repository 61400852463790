import Vue from 'vue'
import Vuex from 'vuex'
import C2C from './modules/c2c'
import user from './modules/user'
import getter from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    enum: {}
  },
  getters: {
    getEnum(state) {
      return key => {
        return state.enum[key]
      }
    },
    ...getter
  },
  mutations: {
    setEnum(state, payload) {
      state.enum = payload
    }
  },
  actions: {
  },
  modules: {user, C2C}
})