const ac_pt = {
  300001: 'Reenviar em {timeout}s',
  300002: 'Enviar código',
  300003: 'Verificação de Segurança',
  300004: 'Para a segurança da sua conta, conclua as seguintes operações de verificação.',
  300005: 'Código de autenticação',
  300006: 'Móvel',
  300007: 'Código de verificação via SMS',
  300008: 'Código de verificação de e-mail',
  300009: 'enviar',
  300010: 'Código de 6 dígitos será enviado para{phone}',
  300011: 'Insira o código de verificação de 6 dígitos do SuperEx/Google/Microsoft  ',
  300012: 'Código será enviado para{email}',
  300013: 'Confirmar',
  300014: 'Cancelar',
  300015: 'Digite o código de verificação de 6 dígitos',

  300016: 'Anunciantes Certificados',
  300017: 'Depósito de Segurança',
  300018: 'Usuário Verificado',
  300019: 'Usuário Normal',
  300020: 'Verificação de Email',
  300021: 'Verificação por Telefone',
  300022: 'Verificação de Identidade',
  300023: 'Tornar-se Anunciante',
  300024: 'Liberar depósito de segurança',
  300025: 'Taxa de Sucesso em 30 dias',
  300026: 'Pedidos em 30 dias',
  300027: 'Tempo médio de liberação',
  300028: 'Tempo médio de liberação: o tempo médio de confirmação dos ativos liberados após o recebimento do pagamento ao vender nos últimos 30 dias.',
  300029: 'minutos',
  300030: 'Pagamento médio',
  300031: 'Conta criada',
  300032: 'Dia',
  300033: 'Total de ordens',
  300034: 'vezes',
  300035: 'B',
  300036: 'V',
  300037: 'Primeira transação até agora',
  300038: 'Conversão de transações em 30 dias',
  300039: 'Volume total de transações convertido',
  300040: 'Método de Pagamento P2P',
  300041: 'Método de pagamento: O método de pagamento que você adicionar será exibido ao comprador como seu método de pagamento ao vender criptomoedas em uma transação P2P. Certifique-se de usar sua própria conta verificada para garantir que o comprador possa transferir dinheiro para você sem problemas. Você pode adicionar até {num} métodos de pagamento.',
  300042: 'Adicionar Método de Pagamento',
  300043: 'Editar',
  300044: 'Excluir',
  300045: 'Sem Método de Pagamento',
  300046: 'Excluir este método de pagamento?',
  300047: 'Você não concluiu a verificação de identidade.',
  300048: 'Para a segurança da sua conta com outros usuários de negociação, por favor, complete primeiro a verificação de identidade.',
  300049: 'Não verificado',
  300050: 'Verificar conta',
  300051: 'Escolher Método de Pagamento',
  300052: 'Método de Pagamento Recomendado',
  300053: 'Sem dados',
  300054: 'Todos os métodos de pagamento',
  300055: 'Buscar',
  300056: 'Definir apelido',
  300057: 'Defina seu apelido de conta. Recomenda-se não usar seu nome real. O comprimento do apelido deve ser de 2 a 10 caracteres.',

  300058: 'Carregar',
  300059: '*(Suporta) formatos PG/PEG/PNG/BMP, menos de 5MB',
  300060: 'Reenviar',
  300061: 'Lembrete: Ao vender criptomoedas, o método de pagamento escolhido será exibido ao comprador; confirme se as informações estão corretas.',
  300062: 'Você deseja sair da adição de um método de pagamento?',
  300063: 'Suas edições atuais não serão salvas ao sair das modificações.',
  300064: 'Aplicação enviada',

  300065: 'Logotipo exclusivo',
  300066: 'Selo de certificação exclusivo para anunciantes que aumenta a confiança nas transações',
  300067: 'Serviço individual',
  300068: 'Oferecemos atendimento personalizado para publicidade, melhorando a eficiência das transações.',
  300069: 'Região/país',
  300070: 'Moeda fiduciária emitível',
  300071: 'Condições de aplicação',
  300072: 'Complete a verificação de identidade',
  300073: 'Quantidade de {currency} na conta P2P é maior que {num}',
  300074: 'Transferir',
  300075: 'Aplicação falhou',
  300076: 'Aplicar Agora',
  300077: 'Em avaliação',
  300078: 'O depósito foi liberado?',
  300079: "1. Após liberar o depósito, você não terá os direitos e interesses dos comerciantes P2P. ",
  300080: '2. Ao liberar o depósito, não pode haver anúncios ou pedidos em negociação.',
  300081: 'Não por agora',
  300082: 'Portal de solicitação de comerciantes está fechado',
  300083: 'Entendi',

  300084: 'Processando',
  300085: 'Todas as ordens',
  300086: 'Moeda digital',
  300087: 'Todas as criptomoedas',
  300088: 'Tipo de pedido',
  300089: 'Tudo',
  300090: 'Comprar',
  300091: 'Vender',
  300092: 'Status',
  300093: 'Todos os status',
  300094: 'Pagamento pendente',
  300095: 'Pago',
  300096: 'Concluído',
  300097: 'Cancelado',
  300098: 'Apelação em andamento',
  300099: 'Data',
  300100: 'Redefinir filtro',
  // 300101: '暂无数据',
  300102: 'Pedidos',
  300103: 'Detalhes do Anunciante',
  300104: 'Detalhes do usuário',
  300105: 'Comprar online',
  300106: 'Comprar do comerciante',
  300107: 'Venda ao comerciante',
  300108: 'Preço',
  300109: 'Método de Pagamento',
  300110: 'Limite / Quantidade',
  300111: 'Troca',
  300112: 'Quantidade',
  300113: 'Limite',
  300114: 'Processar',
  300115: 'OK',
  300116: 'Tipo / Moeda',
  300117: 'Total Geral',
  300118: 'Apelido do outro partido',
  300119: 'Operar',

  300120: 'Aplicação de Anunciante Certificado',
  300121: 'Informações Básicas',
  300122: 'Nome Completo',
  300123: 'Telefone',
  300124: 'Digite o número de telefone',
  300125: 'Código de área',
  300126: 'Email',
  300127: 'Digite o endereço de e-mail',
  300128: 'Contato de emergência',
  300129: 'Digite o contato de emergência',
  300130: 'Número de contato de emergência',
  300131: 'Digite o número do contato de emergência',
  300132: 'Relação',
  300133: 'Colega de classe',
  300134: 'Família',
  300135: 'Outro',
  300136: 'Endereço de Residência Permanente',
  300137: 'Digite o endereço de residência permanente.',
  300138: 'Telefone: Usado pela equipe para processar ordens de reclamação diárias e verificar rapidamente as informações com você. Por favor, mantenha este número de telefone disponível. ',
  300139: 'Contato de emergência e endereço permanente: Utilizado para verificar ainda mais as informações KYC do anunciante, garantindo transações mais seguras.',
  300140: 'Identificação',
  300141: 'Exemplo',
  300142: '*Ao tirar a foto, segure a frente do seu documento de identidade e inclua todo o braço e a parte superior do corpo na foto, com seu rosto e todas as informações no documento de identidade claras e não obstruídas. ',
  300143: '*Somente os formatos .jpg/.jpeg/.png são suportados, com um tamanho máximo de 10MB.',
  300144: 'Por favor, faça o upload do certificado de propriedade/contrato de aluguel/fatura de utilidade (escolha um dos três)',
  300145: 'Comprovante de endereço',
  300146: '*Documentos de propriedade, contrato de aluguel e contas de serviços validam seu endereço. Explicar se diferente do informado',
  300147: '*Documentos devem mostrar: nome do proprietário, endereço, carimbos, nomes, IDs. Contas recentes com endereço.',
  300148: 'Verificação por Vídeo',
  300149: 'Por favor, segure o documento de identificação na frente, leia o texto modelo abaixo em voz alta e faça uma gravação em vídeo (garantindo que o som e a imagem estejam claros).',
  300150: 'Eu (nome), número de identificação (número de identificação), minha fonte de fundos é legal e confiável, eu negoceio voluntariamente ativos digitais como Bitcoin, compreendo plenamente as criptomoedas e os riscos potenciais, tenho a capacidade de resistir a riscos e estou disposto a assumir todos os riscos. Além disso, confirmo que todas as operações desta conta são realizadas por mim e todas as responsabilidades legais decorrentes desta conta serão de minha responsabilidade pessoal.',
  300151: 'Envie o vídeo de verificação',
  300152: '* Vídeo com suporte de até 100M, duração recomendada abaixo de 3 minutos, resolução acima de 360P ',
  300153: '* Formatos de vídeo suportados: .MP4/.WMV/.DGG/.MOV',
  300154: 'Lembrete',
  300155: 'Seu saldo de {currency} é inferior a {num}',
  300156: 'Enviar Aplicação',
  300157: 'Concordo em bloquear <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span> como depósito do anunciante e concordo com o <a href="https://support.superex.com/hc/en-001/articles/15269117544345">《Acordo do Anunciante》</a>.',
  // 300158: '《广告发布方协议》',
  300159: 'Enviar código de verificação por voz para {phone}',
  300160: 'Código de voz',

  300161: 'Pagamento médio: o tempo médio de pagamento para compras de criptomoedas nos últimos 30 dias.',
  300162: '0 Taxas',
  300163: '1 Semana',
  300164: '1 Mês',
  300165: '3 Meses',
  300166: 'Antes de começar a negociar, por favor, conclua a verificação de identidade.',
  300167: 'Seu pedido foi cancelado {num} vezes hoje e você não pode continuar a transação. Tente novamente após UTC{Symbol}{string}.',
  300168: 'Você atingiu o número máximo de ordens pendentes ({num}), por favor, complete a ordem em andamento primeiro.',
  300169: 'Este anunciante atingiu o número máximo de pedidos pendentes, por favor, tente mais tarde ou escolha outro anúncio.',
  300170: 'Você não atendeu aos requisitos do anunciante, tente outro anúncio.',
  300171: 'Configuração bem-sucedida',
  300172: 'Verifique os detalhes da outra parte',
  300173: 'Contactar a outra parte',
  300174: 'Apenas imagens nos formatos jpg/jpeg/png são suportadas.',
  300175: 'O tamanho da imagem não pode exceder 5MB!',
  300176: 'Upload bem-sucedido',
  300177: 'Falha no upload, tente novamente mais tarde.',
  300178: 'Por favor, defina um apelido',
  300179: 'O comprimento do apelido deve estar entre 2 e 10 caracteres.',
  300180: 'Até {num} tipos de métodos de pagamento podem ser adicionados.',
  300181: 'Formato do número de celular está incorreto',
  300182: 'Formato de email incorreto',
  300183: 'Precisa ter entre 2 e 30 caracteres',
  300184: 'O comprimento do endereço deve ter entre 5 e 120 caracteres.',
  300185: 'Selecione a relação com a pessoa',
  300186: 'Foto de ID não enviada',
  300187: 'Foto de comprovante de endereço não enviada',
  300188: 'Verificação de vídeo não enviada',
  300189: 'Submetido com sucesso',
  300190: 'Carregue o formato de vídeo correto',
  300191: 'O tamanho do vídeo não pode exceder 100 MB.',
  300192: 'Falha ao enviar',
  300193: '{num}minuto',
  300194: 'Antes de começar a negociar, por favor, conclua a verificação de identidade.',
  300195: 'Copiado com sucesso',
  300196: 'Cópia falhou',
  300197: 'Tempo',
  300198: 'Permissão de Anúncio',
  300199: 'Os anunciantes podem postar anúncios livremente, tornando a compra e venda mais flexíveis e convenientes.',
  300200: 'Contato',
  300201: 'Por favor, faça o upload de uma foto do seu documento de identidade.',
  300202: 'Excluído com sucesso',
  300203: 'Carregando',
  300204: 'Por favor, confirme que o pagamento foi feito ao vendedor pelo seguinte método de pagamento.',
  300205: 'Número do Pedido',
  300206: 'Tempo de criação',
  300207: 'Preço Total',
  300208: 'Por favor, faça o pagamento ao anunciador dentro de {expiresTimeMinute}; os pedidos serão cancelados automaticamente após esse prazo.',
  300209: 'Após a conclusão da transferência, certifique-se de clicar no botão abaixo para notificar o vendedor sobre o pagamento; caso contrário, o pedido será automaticamente cancelado após o término do prazo de pagamento, resultando em perda de ativos!',
  300210: 'Eu completei o pagamento. Próximo passo.',
  300211: 'Cancelar pedido',
  300212: 'Aguardando pagamento dos compradores',
  300213: 'O pagamento do comprador deve ser recebido em até<span style="color: #ffc72b;"> 3 minutos </span>.',
  300214: 'Confirmo que recebi o valor correto.',
  300215: 'Se houver um problema na transação, entrar em contato diretamente com a outra parte é a maneira mais eficaz. Você pode enviar os comprovantes de pagamento e as informações da conta na janela de chat para que ambas as partes possam negociar e verificar.',
  300216: 'Instruções de Apelação',
  300217: 'Iniciar uma apelação não pode ajudar a recuperar seus ativos diretamente, por favor, aguarde a intervenção do serviço ao cliente para lidar com a situação conforme apropriado. ',
  300218: 'Após a intervenção do serviço ao cliente, leva de 12 a 48 horas para processar a apelação, por favor, preste atenção ao progresso do processamento da apelação. ',
  300219: ' Apelações maliciosas são atos que perturbar a ordem normal de operação da plataforma, e a conta será bloqueada em casos graves.',
  300220: 'Aqui está como o vendedor recebe o pagamento: o pagamento exige que você saia da plataforma e use sua conta verificada para transferir dinheiro para a outra parte.',
  300221: 'Confirmar Pagamento',
  300222: 'Por favor, certifique-se de que pagou o vendedor pelo método de pagamento abaixo. Se não pagou, não clique em Pago.',
  300223: 'Dica: Entendo que, ao fazer um pagamento, preciso sair da plataforma para concluir a transferência por conta própria; a SuperEx não fará descontos automáticos.',
  300224: 'Utilizei uma conta que corresponde à Verificação de Nome Real da SuperEx para fazer um pagamento.',
  300225: 'Cancelar',
  300226: 'Confirmar',
  300227: 'Por favor, pague dentro de &nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp;',
  300228: 'Método de Pagamento',
  300229: 'Aguardando confirmação de pagamento do vendedor',
  300230: 'Se você completou o pagamento, entre em contato com a outra parte para confirmar.',
  300231: 'Apelação',
  300232: 'Cancelar',
  300233: 'Certifique-se de fazer login na sua conta para confirmar que o valor está disponível no seu "Saldo Disponível".',
  300234: 'Confirmo que recebi o valor correto.',
  300235: 'Confirme o recebimento',
  300236: 'Apelação de pedido em andamento',
  300237: 'A outra parte pagou, por favor confirme o recebimento.',
  300238: 'Retornar',
  300239: 'Você vendeu com sucesso {quantity}{currency}.',
  300240: 'Feito',
  300241: 'Ver Ativos',
  300242: 'Transação bem-sucedida',
  300243: 'Você teve sucesso.',
  300244: 'Método de recebimento',
  300245: 'Método de Pagamento',
  300246: 'Dicas de Segurança',
  300247: '"1. Não participe de esquemas e quaisquer atos ilegais ou criminosos, como comprar para outros, lavagem de dinheiro, empréstimos de alta taxa de juros, arbitragem, namoro online, etc. 2. É proibido usar quaisquer fundos ilegais (como jogos de azar, esquemas financeiros, fraudes, etc.) para negociações de criptomoedas."',
  300248: 'Após <span style="color:#FFC72B">{appealTime}</span> você pode recorrer',
  300249: '1. Se você já pagou ao vendedor, não cancele a transação.',
  300250: '2. Regras de cancelamento: se o comprador acumular {num} cancelamentos no mesmo dia, não poderá fazer um novo pedido nesse dia.',
  300251: 'O motivo do recurso será exibido para ambas as partes e o atendimento ao cliente ao mesmo tempo; observe a proteção das informações de privacidade.',
  300252: 'Por favor, não acredite em nenhuma razão para apressar a liberação das moedas. Libere os ativos digitais somente após confirmar o recebimento do pagamento para evitar perdas.',
  300253: 'Motivo do recurso (obrigatório)',
  300254: 'Pessoa de contato',
  300255: 'Dados de contato',
  300256: 'Quero apelar',
  300257: 'Por favor, selecione o motivo do seu apelo',
  300258: 'Descrição do Apelo',
  300259: 'Descreva o apelo da forma mais completa possível.',
  300260: 'Insira seu endereço de e-mail',
  300261: 'Pedido cancelado',
  300262: 'Seu pagamento expirou e seu pedido foi cancelado automaticamente.',
  300263: 'Método de pagamento indisponível',
  300264: 'Se houver dúvidas, entre em contato com o serviço de atendimento ao cliente.',
  300265: 'Apelação cancelada?',
  300266: '1. Você pode continuar a negociar após retirar sua apelação, e seu pedido não será cancelado. ',
  300267: '2. Se encontrar outros problemas, você pode apelar novamente.',
  300268: 'Temporariamente não necessário',
  300269: 'Cancelar apelação',
  300270: 'Você não pode apelar dentro de {appealMinMinute} minutos.',
  300271: 'Você comprou com sucesso {quantity}{currency}',
  300272: 'Você vendeu com sucesso {quantity}{currency}.',
  300273: 'Descrição do apelo não pode estar vazia.',
  300274: '',
  300275: '',
  300276: '',
  300277: '',
  300278: '',
  300279: '',
  300280: '',
  300281: '',
  300282: '',
  300283: '',
  300284: '',
  300285: '',
  300286: '',
  300287: '',
  300288: '',
  300289: '',
  300290: '',
  300291: '',
  300292: '',
  300293: '',
  300294: '',
  300295: '',
  300296: '',
  300297: '',
  300298: '',
  300299: '',
  300300: '',
}//300001
export default ac_pt;