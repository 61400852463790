const ac_ru = {
    300001: '{timeout}s',
    300002: 'Отправить код',
    300003: 'Проверка безопастности',
    300004: 'В целях безопасности вашей учетной записи выполните следующие операции проверки',
    300005: 'Код идентификации',
    300006: 'Телефон',
    300007: 'Мобильный код подтверждения',
    300008: 'Код верификации с почты',
    300009: 'Подать',
    300010: '6-значный код будет отправлен на {phone}',
    300011: 'Пожалуйста, введите 6-значный проверочный код SuperEx/Google/Microsoft',
    300012: 'Код будет отправлен на {email}',
    300013: 'Подтвердить',
    300014: 'Отмена',
    300015: 'Введите 6-значный код проверки',
    300016: 'Сертифицированные рекламодатели',
    300017: 'Гарантийный депозит',
    300018: 'Верифицированный пользователь',
    300019: 'Неверифицированный пользователь',
    300020: 'Верификация электронной почты',
    300021: 'Верификация номера телефона',
    300022: 'Верификация личности',
    300023: 'Стать рекламодателем',
    300024: 'Освобождение гарантийного депозита',
    300025: '30-дневный коэффициент успеха',
    300026: '30-дневные ордера',
    300027: 'Среднее время выпуска',
    300028: 'Среднее время выпуска: среднее время подтверждения активов, выпущенных после получения оплаты при продаже за последние 30 дней',
    300029: 'минут',
    300030: 'Среднее время выпуска',
    300031: 'Создание аккаунта',
    300032: 'дни',
    300033: 'Общее количество ордеров',
    300034: 'раз',
    300035: 'Купить',
    300036: 'Продать',
    300037: 'Первая транзакция на сегодняшний день',
    300038: 'Конвертация транзакций за 30 дней',
    300039: 'Общий объем конвертированных транзакций',
    300040: 'Способ оплаты P2P',
    300041: 'Способ оплаты: Метод оплаты, который вы добавите, будет показан покупателю как ваш метод оплаты при продаже криптовалюты в сделке P2P. Обязательно используйте свой собственный верифицированный аккаунт, чтобы покупатель мог беспрепятственно перевести вам деньги. Вы можете добавить до {num} способов оплаты.',
    300042: 'Добавить способ оплаты',
    300043: 'Изменить',
    300044: 'Удалить',
    300045: 'Нет способа оплаты',
    300046: 'Удалить этот способ оплаты?',
    300047: 'Вы не завершили верификацию личности',
    300048: 'В целях безопасности вашего аккаунта и других торговых пользователей, сначала пройдите верификацию личности',
    300049: 'Неверифицирован',
    300050: 'Пройти верификацию',
    300051: 'Выберите способ оплаты',
    300052: 'Рекомендуемый способ оплаты',
    300053: 'Нет данных',
    300054: 'Все способы оплаты',
    300055: 'Поиск',
    300056: 'Установить никнейм',
    300057: 'Установите никнейм вашего аккаунта. Рекомендуется не использовать ваше настоящее имя. Длина никнейма составляет 2~10 символов.',
    300058: 'Загрузить',
    300059: '* (Поддержка) формата PG/PEG/PNG/BMP, менее 5 МБ',
    300060: 'Загрузите еще раз',
    300061: 'Напоминание: Когда вы продаете криптовалюту, выбранный вами способ оплаты будет показан покупателю, пожалуйста, убедитесь, что информация заполнена правильно.',
    300062: 'Вы хотите отказаться от добавления способа оплаты?',
    300063: 'Ваши текущие правки не будут сохранены после выхода из модификаций',
    300064: 'заявка отправлена',
    300065: 'Эксклюзивный логотип',
    300066: 'Эксклюзивный знак сертификации для рекламодателей для повышения доверия к сделке',
    300067: 'Обслуживание один на один',
    300068: 'Мы предоставляем индивидуальное обслуживание рекламы для повышения эффективности сделки',
    300069: 'Регион/Страна',
    300070: 'Выпускаемая фиатная валюта',
    300071: 'Условия применения',
    300072: 'Завершите проверку личности',
    300073: 'Сумма {currency} на аккаунте P2P больше {num}',
    300074: 'Перевести',
    300075: 'Заявка не прошла',
    300076: 'Подать заявку сейчас',
    300077: 'Под проверкой',
    300078: 'Выпущен ли депозит?',
    300079: '1. После выпуска депозита вы не будете пользоваться правами и интересами торговцев P2P.',
    300080: '2. После освобождения депозита, вы не можете иметь объявления или заказы, которыми торгуют.',
    300081: 'Пока нет',
    300082: 'Портал приложений продавца закрыт',
    300083: 'Хорошо',
    300084: 'В процессе',
    300085: 'Все заказы',
    300086: 'Криптовалюта',
    300087: 'Все криптовалюты',
    300088: 'Тип заказа',
    300089: 'Все',
    300090: 'Купить',
    300091: 'Продать',
    300092: 'Статус',
    300093: 'Все статусы',
    300094: 'Платеж в процессе выполнения',
    300095: 'Оплачено',
    300096: 'Завершено',
    300097: 'Отменено',
    300098: 'Апелляция в процессе рассмотрения',
    300099: 'Дата',
    300100: 'сбросить фильтр',
    300101: '',
    300102: 'Ордеры',
    300103: 'Подробная информация о рекламодателе',
    300104: 'данные пользователя',
    300105: 'Купить онлайн',
    300106: 'Купить у торговца',
    300107: 'Продать торговцу',
    300108: 'Цена',
    300109: 'Способ оплаты',
    300110: 'Лимит / Количество',
    300111: 'Торговля',
    300112: 'Сумма',
    300113: 'Лимит',
    300114: 'Обработать',
    300115: 'Хорошо',
    300116: 'Тип / Валюта',
    300117: 'Общая сумма',
    300118: 'Никнейм другой стороны',
    300119: 'Действовать',
    300120: 'Заявление сертифицированного рекламодателя',
    300121: 'Основная информация',
    300122: 'Полное имя',
    300123: 'Телефон',
    300124: 'Введите номер телефона',
    300125: 'код города',
    300126: 'Электронная почта',
    300127: 'Введите адрес электронной почты',
    300128: 'Экстренный контакт',
    300129: 'Введите контактное лицо для экстренной связи',
    300130: 'Телефон экстренной связи',
    300131: 'Введите экстренный контактный номер',
    300132: 'Отношения',
    300133: 'Одноклассник',
    300134: 'Семья',
    300135: 'Другое',
    300136: 'Адрес постоянного места жительства',
    300137: 'Введите адрес постоянного места жительства',
    300138: 'Телефон: Используется персоналом для обработки ежедневных апелляций, чтобы быстро проверить информацию о вас, пожалуйста, убедитесь, что этот номер телефона доступен.',
    300139: 'Контакт для экстренной связи и постоянный адрес: Используется для дальнейшей проверки KYC информации рекламодателя для обеспечения более безопасных транзакций. ',
    300140: 'Идентификация',
    300141: 'Пример',
    300142: '*При фотографировании держите переднюю часть удостоверения личности и запечатлейте на фото всю руку и верхнюю часть тела, при этом ваше лицо и вся информация на удостоверении личности должны быть видны и не перекрыты.',
    300143: '*Поддерживается только формат .jpg/.jpeg/.png с максимальным размером 10 МБ.',
    300144: 'Пожалуйста, загрузите сертификат на недвижимость/ договор аренды/ счет за коммунальные услуги (выберите один из трех).',
    300145: 'Подтверждение адреса',
    300146: 'Если адрес в документе отличается от постоянного адреса в основной информации, мы попросим вас объяснить причину.',
    300147: 'Пожалуйста, убедитесь, что загружаемые вами фотографии четкие, без отражений и не затемнены.',
    300148: 'Видеоверификация',
    300149: 'Держась за лицевую сторону вашего удостоверения личности, пожалуйста, прочитайте вслух следующий примерный текст и сделайте видеозапись (следите за чистотой голоса и ударением)',
    300150: 'Я (имя), ID номер (номер ID), мой источник средств является законным и надежным, я добровольно торгую цифровыми активами, такими как Биткойн, я полностью понимаю криптовалюту и потенциальные риски, у меня есть способность противостоять рискам и я готов взять на себя все риски. Кроме того, я уверен, что все операции по данному аккаунту осуществляются мной, и все юридические обязанности, возникающие в связи с данным аккаунтом, я несу лично.',
    300151: 'Загрузите видеозапись верификации',
    300152: '* Максимальная поддержка видео составляет 100 М, рекомендуемая продолжительность – менее 3 минут, а разрешение превышает 360 P.',
    300153: '* Поддерживаются форматы видео .MP4/.WMV/.DGG/.MOV',
    300154: 'Напоминание',
    300155: 'Ваш баланс {currency} меньше {num}.',
    300156: 'Подать заявление',
    300157: 'Согласитесь заблокировать <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span> в качестве депозита рекламодателя и согласны с <a href="https://support.superex.com/hc/en-001/articles/15269117544345">《Соглашением рекламодателя》</a>.                                                                                   ',

    // 300158: '《广告发布方协议》',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',

    300161: 'Среднее время оплаты: среднее время оплаты при покупке криптовалюты за последние 30 дней',
    300162: '0 Комиссия',
    300163: '7 дней',
    300164: '1 месяц',
    300165: '3 месяца',
    300166: 'Прежде чем начать торговлю, пожалуйста, пройдите идентификацию личности',
    300167: 'Сегодня ваш заказ был отменен {num} раза, и вы не можете продолжить транзакцию. Повторите попытку через UTC{Symbol}{string} пунктов.',
    300168: 'Вы достигли максимального количества невыполненных ордеров ({num}), пожалуйста, сначала выполните текущий ордер.',
    300169: 'Этот рекламодатель достиг максимального количества невыполненных заказов, пожалуйста, зайдите позже или попробуйте другое объявление.',
    300170: 'Вы не выполнили требования рекламодателя, пожалуйста, попробуйте другое объявление.',
    300171: 'Установка успешна',
    300172: 'Проверьте данные другой стороны',
    300173: 'Свяжитесь с другой стороной',
    300174: 'Поддерживаются только изображения в формате gif/jpg/png',
    300175: 'Размер загружаемого изображения не может превышать 5 МБ!',
    300176: 'Добавлено',
    300177: 'Загрузка не удалась, повторите попытку позже',
    300178: 'Установите никнейм',
    300179: 'Длина никнейма составляет от 2 до 10 символов',
    300180: 'Можно добавить до {num} типов методов оплаты',
    300181: 'Неправильный формат номера мобильного телефона',
    300182: 'Неправильный формат электронного адреса',
    300183: 'Должно быть 2-30 символов',
    300184: 'Длина адреса должна быть от 5 до 120 символов',
    300185: 'Выберите отношения с человеком',
    300186: 'Не загружена фотография ID',
    300187: 'Фотография, подтверждающая адрес, не загружена',
    300188: 'Видеоверификация не загружена',
    300189: 'Отправлено успешно',
    300190: 'Загрузите видео в правильном формате',
    300191: 'Размер видео не может превышать 100 МБ.',
    300192: 'Загрузка не удалась',
    300193: '{num}Минута',
    300194: 'Прежде чем начать торговлю, пожалуйста, пройдите идентификацию личности',
    300195: 'Копирование успешно',
    300196: 'Копия не удалась',
    300197: 'Время',
    300198: 'Разрешение на размещение рекламы',
    300199: 'Рекламодатели могут свободно размещать объявления, делая покупку и продажу более удобной и гибкой',
    300200: 'Контакт',
    300201: 'Для идентификации, пожалуйста, загрузите удостоверение личности с фотографией на руке',
    300202: 'Удалено',
    300203: 'Загрузка',
    300204: 'Подтвердите, что оплата была произведена продавцу следующим способом оплаты',
    300205: 'Номер ордера',
    300206: 'Создано',
    300207: 'Общая цена',
    300208: 'Произведите оплату рекламодателю в течение {expiresTimeMinute}, по истечении этого времени ордеры будут автоматически отменены',
    300209: 'Обязательно нажмите кнопку ниже, чтобы уведомить продавца об оплате после завершения перевода, в противном случае заказ будет автоматически отменен по истечении времени оплаты, что приведет к потере средств!',
    300210: 'Я завершил оплату. Следующий шаг',
    300211: 'Отменить ордер',
    300212: 'Ожидание оплаты от покупателей',
    300213: 'Ожидается, что платеж покупателя будет получен в течение <span style="color: #ffc72b;"> 3минут </span>',
    300214: 'Я подтвердил оплату',
    300215: 'Если возникла проблема со сделкой, связаться с другой стороной напрямую - самый эффективный способ. Вы можете загрузить платежные квитанции и информацию о вашем аккаунте в окно чата, чтобы обе стороны могли провести переговоры и проверить их.',
    300216: 'Инструкции по подаче апелляции',
    300217: 'Инициирование апелляции не может помочь вам вернуть ваши активы напрямую, пожалуйста, дождитесь вмешательства службы поддержки клиентов и решите ситуацию соответствующим образом.发起申诉无法帮您直接追回资产，请等待客服介入后视情况处理。',
    300218: 'Обработка апелляции после вмешательства службы поддержки занимает 12-48 часов, пожалуйста, следите за ходом обработки апелляции.',
    300219: 'Вредоносные апелляции - это действия, нарушающие нормальный порядок работы платформы, в серьезных случаях аккаунт будет заблокирован.',
    300220: 'Вот как продавец получает оплату: Оплата требует, чтобы вы покинули платформу и использовали свой верифицированный аккаунт для перевода денег другой стороне.',
    300221: 'Подтвердить платеж',
    300222: 'Убедитесь, что вы заплатили продавцу указанным ниже способом оплаты. Если вы не оплатили, пожалуйста, не нажимайте кнопку Оплачено.',
    300223: 'Совет: Я понимаю, что при совершении платежа мне необходимо покинуть платформу, чтобы завершить перевод самостоятельно, SuperEx не будет автоматически списывать средства.',
    300224: 'Для совершения платежа я использовал аккаунт, который соответствует требованиям SuperEx по верификации реального имени.',
    300225: 'Отменить',
    300226: 'Подтвердить',
    300227: 'Пожалуйста, оплатите в течение &nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp;',
    300228: 'Способ оплаты',
    300229: 'Ожидание подтверждения получения от продавца',
    300230: 'Если вы завершили оплату, пожалуйста, свяжитесь с другой стороной для подтверждения',
    300231: 'Апелляция',
    300232: 'Отмена',
    300233: 'Обязательно войдите в свой аккаунт, чтобы подтвердить наличие суммы в "Доступном балансе".',
    300234: 'Я подтверждаю, что получил правильную сумму',
    300235: 'Подтвердите получение',
    300236: 'Апелляция по ордеру в процессе',
    300237: 'Другая сторона оплатила, пожалуйста, подтвердите получение',
    300238: 'Вернуться',
    300239: 'Вы успешно продали {quantity} {currency}.',
    300240: 'Завершить',
    300241: 'Просмотреть активы',
    300242: 'Транзакция успешна',
    300243: '您已成功',
    300244: 'Способ оплаты',
    300245: 'Способ оплаты',
    300246: 'Советы по безопасности',
    300247: '1. Не занимайтесь мошенничеством и любыми незаконными и преступными действиями, такими как покупка за других, отмывание денег, кредиты и арбитраж переездов, онлайн-знакомства и т.д. 2. Использование любых незаконных средств (таких как азартные игры, денежный рынок, мошенничество и т.д.) запрещено для торговли криптовалютой.',
    300248: '<span style="color:#FFC72B">{appealTime}</span> может подать апелляцию позже',
    300249: '1. Если вы уже заплатили продавцу, пожалуйста, не отменяйте транзакцию',
    300250: '2. Правила отмены: Если покупатель накопил {num} отмены в тот же день, он не может разместить еще один заказ в тот же день.',
    300251: 'Причина апелляции будет показана обеим сторонам и службе поддержки одновременно, пожалуйста, обратите внимание на защиту информации о конфиденциальности.',
    300252: 'Пожалуйста, не верьте никаким причинам спешки в выпуске монет, и выпускайте криптоактивы после подтверждения получения платежа, чтобы избежать потерь.',
    300253: 'Причина подачи апелляции (обязательно)',
    300254: 'Контактное лицо',
    300255: 'Контактная информация',
    300256: 'Я хочу подать апелляцию',
    300257: 'Пожалуйста, выберите причину вашей апелляции',
    300258: 'Описание апелляции',
    300259: 'Опишите апелляцию как можно подробнее',
    300260: 'Введите свой адрес электронной почты',
    300261: 'Ордер отменен',
    300262: 'Ваш платеж истек по времени, и ордер был автоматически отменен',
    300263: 'Невозможно просмотреть способ оплаты',
    300264: 'Если вы сомневаетесь, пожалуйста, свяжитесь со службой поддержки.',
    300265: 'Апелляция отменена?',
    300266: '1. Вы можете продолжать торговать после отзыва апелляции, и ваш ордер не будет отменен.',
    300267: '2. Если у вас возникнут другие проблемы, вы можете подать апелляцию снова.',
    300268: 'Временно не требуется',
    300269: 'Отменить апелляцию',
    300270: 'Вы не можете подать апелляцию в течение {appealMinMinute} минут',
    300271: 'Вы успешно купили {quantity}{currency}.',
    300272: 'Вы успешно продали {quantity}{currency}.',
    300273: 'Описание обращения не может быть пустым',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_ru;