import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'
import {getCookie} from '@/utils/common'
import locale from 'element-ui/lib/locale'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale:  getCookie('language') || 'en', //初始未选择默认 en 英文
    // locale: 'en',
    messages,
})
locale.i18n((key, value) => i18n.t(key, value))
export default i18n
