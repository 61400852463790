<template>
  <div id="app">
    <headerView />
    <div class="routerDiv">
      <router-view v-if="isRouterAlive"></router-view>
    </div>
    <cookie v-if="!cookieShow" class="indexCookie" />
    <!-- <footerView /> -->
    <SiteMap></SiteMap>
  </div>
</template>
<script>
import headerView from "@/components/headerView";
import footerView from "@/components/footerView";
import SiteMap from '@/components/NewHome/SiteMap'
import cookie from '@/components/cookie'
import { mapGetters } from "vuex";
import { selecter } from "fastjs-next";

export default {
  components: {
    headerView, footerView,cookie,SiteMap
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      isShowTipModel: false,
      cookieShow: false,
    }
  },
  computed: {
    ...mapGetters(['isDirection', 'styleType'])
  },
  created() {
    window.document.documentElement.setAttribute("dir", this.isDirection ? 'rtl' : 'ltr');
    window.document.documentElement.setAttribute("data-theme", this.styleType);
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  },
  mounted() {
    // 防止底部空白
    const cssMg = show => {
      selecter("html")
        .css("background", show && "#1B1C22" || "white")
    }
    cssMg(true)
    // router before
    this.$router.beforeEach((to, from, next) => {
      cssMg(false)
      next()
    })
    // router after
    this.$router.afterEach(() => {
      cssMg(true)
    })
  }
}
</script>
<style lang="scss">
@import "./assets/css/common.css";
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.routerDiv {
  min-height: calc(100vh - #{300px});
}
body {
  background: white;
}
.indexCookie{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
}
</style>
