const ac_en = {
    300001: 'Resend in {timeout}s',
    300002: 'Send Code',
    300003: 'Security Verification',
    300004: 'For your account security, please complete the following verification operations',
    300005: 'Authentication code',
    300006: 'Mobile',
    300007: 'Phone verification code',
    300008: 'Email verification code',
    300009: 'Submit',
    300010: '6-digit code will be sent to {phone}',
    300011: 'Please enter the 6-digit SuperEx/Google/Microsoft verification code',
    300012: 'Code will be sent to {email}',
    300013: 'Confirm',
    300014: 'Cancel',
    300015: 'Enter 6-digit verification code',
    300016: 'Certified Advertisers',
    300017: 'Security Deposit',
    300018: 'Verified User',
    300019: 'Unverified User',
    300020: 'Email Verification',
    300021: 'Phone Verification',
    300022: 'Identity Verification',
    300023: 'Become Advertiser',
    300024: 'Release security deposit',
    300025: '30 Day Success Rate',
    300026: '30 Day Orders',
    300027: 'Average release time',
    300028: 'Average release time: the average confirmation time of assets released after receiving payment when selling in the last 30 days',
    300029: 'minutes',
    300030: 'Average payment',
    300031: 'Account created',
    300032: 'Days',
    300033: 'Total number of orders',
    300034: 'times',
    300035: 'Buy',
    300036: 'Sell',
    300037: 'First transaction to date',
    300038: '30-Day transaction conversion',
    300039: 'Total transaction volume converted',
    300040: 'P2P Payment Method',
    300041: 'Payment method: The payment method you add will be shown to the buyer as your payment method when selling cryptocurrency in a P2P transaction. Make sure to use your own verified account to ensure the buyer can transfer money to you smoothly. You can add up to {num} payment methods.',
    300042: 'Add Payment Method',
    300043: 'Edit',
    300044: 'Delete',
    300045: 'No Payment Method',
    300046: 'Delete this payment method?',
    300047: 'You have not completed identity verification',
    300048: 'For the security of your account with other trading users, please complete identity verification first',
    300049: 'Unverified',
    300050: 'Get Verified',
    300051: 'Select Payment Method',
    300052: 'Recommended Payment Method',
    300053: 'No data',
    300054: 'All Payment Methods',
    300055: 'Search',
    300056: 'Set nickname',
    300057: 'Set your account nickname. It is recommended not to use your real name. The nickname length is 2~10 characters.',
    300058: 'Upload',
    300059: '*(Support) PG/PEG/PNG/BMP format, less than 5MB',
    300060: 'Upload again',
    300061: 'Reminder: When you sell cryptocurrency, the payment method you choose will be displayed to the buyer, please confirm that the information is filled in correctly.',
    300062: 'Do you want to opt out of adding a payment method?',
    300063: 'Your current edits will not be saved after you exit the modifications',
    300064: 'Application submitted',
    300065: 'Exclusive logo',
    300066: 'Exclusive certification mark for advertisers to enhance transaction trust',
    300067: 'One-to-one service',
    300068: 'We provide one-on-one service for advertising to improve transaction efficiency',
    300069: 'Region/country',
    300070: 'Issuable fiat currency',
    300071: 'Application conditions',
    300072: 'Complete identity verification',
    300073: 'Amount of {currency} in the P2P account is greater than {num}',
    300074: 'Transfer',
    300075: 'Application failed',
    300076: 'Apply Now',
    300077: 'Under Review',
    300078: 'Is the deposit released?',
    300079: "1. After releasing the deposit, you will not enjoy the rights and interests of P2P merchants",
    300080: '2. When releasing the deposit, you cannot have ads or orders being traded.',
    300081: 'Not for now',
    300082: 'Merchant application portal is closed',
    300083: 'OK',
    300084: 'In progress',
    300085: 'All orders',
    300086: 'Cryptocurrency',
    300087: 'All cryptocurrency',
    300088: 'Order type',
    300089: 'All',
    300090: 'Buy',
    300091: 'Sell',
    300092: 'Status',
    300093: 'All status',
    300094: 'Payment pending',
    300095: 'Paid',
    300096: 'Completed',
    300097: 'Cancelled',
    300098: 'Appeal in progress',
    300099: 'Date',
    300100: 'Reset filter',
    300101: '',
    300102: 'Orders',
    300103: 'Advertiser Details',
    300104: 'User details',
    300105: 'Buy online',
    300106: 'Buy from the merchant',
    300107: 'Sell to the merchant',
    300108: 'Price',
    300109: 'Payment Method',
    300110: 'Limit / Quantity',
    300111: 'Trade',
    300112: 'Amount',
    300113: 'Limit',
    300114: 'Process',
    300115: 'OK',
    300116: 'Type / Currency',
    300117: 'Total amount',
    300118: 'Nickname of the other party',
    300119: 'Operate',
    300120: 'Certified Advertiser Application',
    300121: 'Basic Information',
    300122: 'Full Name',
    300123: 'Phone',
    300124: 'Enter phone number',
    300125: 'Area code',
    300126: 'Email',
    300127: 'Enter email address',
    300128: 'Emergency contact',
    300129: 'Enter emergency contact person',
    300130: 'Emergency contact number',
    300131: 'Enter emergency contact number',
    300132: 'Relationship',
    300133: 'Classmate',
    300134: 'Family',
    300135: 'Other',
    300136: 'Permanent Residence Address',
    300137: 'Enter permanant residence address',
    300138: "Phone: Used for staff to process daily complaint orders to quickly verify information with you, please make sure to keep this phone number available.",
    300139: 'Emergency contact and permanent address: Used to further verify KYC information of the advertiser to ensure more secure transactions.',
    300140: 'Identification',
    300141: 'Example',
    300142: "*When taking a photo, hold the front of your ID card and take the whole arm and upper body of your ID card into the photo, with your face and all information on your ID card clear and unobstructed.",
    300143: '*Only .jpg/.jpeg/.png format is supported with a maximum 10MB size',
    300144: 'Please upload the real estate certificate/rental contract/utility bill (choose one of the three)',
    300145: 'Proof of address',
    300146: '*If the address in the document is different from the permanent address in the basic information, we will ask you to explain the reason.',
    300147: '*Please make sure that the pictures you upload are clear and without reflections and that they are not obscured.',
    300148: 'Video Verification',
    300149: 'Holding the front of your ID, please read the following model text aloud and make a video recording (ensure clear voice and image are clear)',
    300150: 'I (name), ID number (ID number), my source of funds is legal and reliable, I voluntarily trade digital assets such as Bitcoin, I fully understand cryptocurrency and potential risks, I have the ability to resist risks and am willing to assume all risks. In addition, I am sure that all operations of this account are operated by me, and all legal responsibilities arising from this account shall be borne by me personally.',
    300151: 'Upload the verification video',
    300152: '*Video support up to 100M, recommended length under 3 minutes, resolution over 360P',
    300153: '*MP4/.WMV/.DGG/.MOV',
    300154: 'Reminder',
    300155: 'Your {currency} balance is less than {num}',
    300156: 'Submit Application',
    300157: "Agree to lock <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span> as advertiser deposit and agree to the <a href='https://support.superex.com/hc/en-001/articles/15269117544345'>《Advertiser Agreement》</a>",
    // 300158: '',
    300159: 'Send voice verification code to{phone}',
    300160: 'Voice verification',
    300161: 'Average payment: the average payment time for cryptocurrency purchases in the last 30 days',
    300162: '0 Fee',
    300163: '1 Week',
    300164: '1 Month',
    300165: '3 Month',
    300166: 'Before you start trading, please complete the identity verification',
    300167: 'Your order today has been cancelled {num} times and you are unable to continue trading, please try again after {Symbol}{string} UTC.',
    300168: 'You have reached the maximum number of pending orders ({num}), please complete the order in progress first.',
    300169: 'This advertiser has reached the maximum number of pending orders, please operate later or try another ad.',
    300170: 'You have not met the requirements of the advertiser, please try another advertisement.',
    300171: 'Set up successful',
    300172: 'Check the details of the other party',
    300173: 'Contact the other party',
    300174: 'Only jpg/jpeg/png format images are supported',
    300175: 'Upload image size cannot exceed 5MB!',
    300176: 'Uploded',
    300177: 'Upload failed, try again later',
    300178: 'Set a nickname',
    300179: 'Nickname length is between 2 and 10 characters',
    300180: 'Up to {num} payment method types can be added',
    300181: 'Mobile phone number format is incorrect',
    300182: 'Incorrect email format',
    300183: 'Needs to be 2-30 characters',
    300184: 'Address length needs to be between 5 and 120 characters',
    300185: 'Select the relationship with the person',
    300186: 'ID photo not uploaded',
    300187: 'Address proof photo not uploaded',
    300188: 'Video verfication not uploaded',
    300189: 'Submitted successfully',
    300190: 'Upload the correct video format',
    300191: 'Video size cannot exceed 100 MB',
    300192: 'Upload failed',
    300193: '{num}minutes',
    300194: 'Before you start trading, please complete the identity verification',
    300195: 'Copy Successful',
    300196: 'Copy failed',
    300197: 'Time',
    300198: 'Advertising Permission',
    300199: 'Advertisers can freely post ads, making buying and selling more flexible and convenient',
    300200: 'Contact',
    300201: 'For identification, please upload a handheld photo ID',
    300202: 'Deleted',
    300203: 'Uploading',
    300204: 'Please confirm that payment has been made to the seller by the following payment method',
    300205: 'Order Number',
    300206: 'Created',
    300207: 'Total Price',
    300208: 'Please pay the advertiser within {expiresTimeMinute} minutes. If not completed, orders will be automatically cancelled after 15 minutes.',
    300209: 'Please be sure to click the button below to notify the seller of the payment after the transfer is completed, otherwise the order will be automatically cancelled after the payment time expires, resulting in loss of assets!',
    300210: 'I have completed the payment. Next step',
    300211: 'Cancel the order',
    300212: 'Waiting for payment from buyers',
    300213: 'Buyer payment is expected to be received within <span style="color: #ffc72b;"> 3minutes </span>',
    300214: 'I have confirmed the payment',
    300215: 'If there is a problem with the transaction, contacting the other party directly is the most effective way. You can upload the payment receipts and account information in the chat window for both parties to negotiate and verify.',
    300216: 'Appeal Instructions',
    300217: 'Initiating an appeal cannot help you recover your assets directly, please wait for customer service to intervene and deal with the situation as appropriate.',
    300218: 'It takes 12-48 hours to process the appeal after customer service intervention, please pay attention to the progress of appeal processing.',
    300219: 'Malicious appeals are acts that disturb the normal operation order of the platform, and the account will be blocked in serious cases.',
    300220: 'Here is how the seller receives payment: Payment requires you to leave the platform and use your verified account to transfer money to the other party.',
    300221: 'Confirm Payment',
    300222: 'Please make sure you have paid the seller via the payment method below. If you have not paid, please do not click Paid.',
    300223: 'Tip: I understand that when making a payment I need to leave the platform to complete the transfer on my own, SuperEx will not automatically debit the funds.',
    300224: 'I have used an account that matches SuperEx Real Name Verification to make a payment.',
    300225: 'Cancel',
    300226: 'Confirm',
    300227: 'Please pay within &nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp;',
    300228: 'Payment Method',
    300229: 'Waiting for confirmation of receipt from the seller',
    300230: 'If you have completed the payment, please contact the other party to confirm',
    300231: 'Appeal',
    300232: 'Cancel',
    300233: 'Make sure to log in to your account to confirm that the amount is available in your "Available Balance".',
    300234: 'I confirm that I have received the correct amount',
    300235: 'Confirm receipt',
    300236: 'Order appeal in progress',
    300237: 'The other party has paid, please confirm receipt',
    300238: 'Return',
    300239: 'You have successfully sold {quantity}{currency}.',
    300240: 'Complete',
    300241: 'View Assets',
    300242: 'Transaction Successful',
    300243: 'You have successfully',
    300244: 'Payment Method',
    300245: 'Payment Method',
    300246: 'Security Tips',
    300247: '1. Do not engage in scams and any illegal and criminal acts such as buying for others, money laundering, loans and moving arbitrage, online dating, etc. 2. Using any illegal funds (such as gambling, money market, fraud, etc.) are prohibited for cryptocurrency trading.',
    300248: 'After <span style="color:#FFC72B">{appealTime}</span> you can appeal',
    300249: '1. If you have already paid the seller, please do not cancel the transaction',
    300250: '2. Cancellation rules: If the buyer has accumulated {num} cancellations on the same day,  they cannot place another order on the same day.',
    300251: 'The reason for the appeal will be shown to both parties and customer service at the same time, please note the privacy information protection.',
    300252: 'Please do not believe any reason of rushing to release the coins, and release the crypto assets after confirming the receipt of payment to avoid losses. ',
    300253: 'Reason for appeal (required)',
    300254: 'Contact person',
    300255: 'Contact information',
    300256: 'I want to appeal',
    300257: 'Please select the reason for your appeal',
    300258: 'Appeal description',
    300259: 'Describe the appeal as completely as possible',
    300260: 'Enter your email address',
    300261: 'Order cancelled.',
    300262: 'Your payment has timed out and your order has been automatically cancelled',
    300263: 'Unable to view payment method',
    300264: 'If in doubt, please contact customer service.',
    300265: 'Is the appeal cancelled?',
    300266: '1. You can continue to trade after withdrawing your appeal, and your order will not be cancelled.',
    300267: '2. If you encounter other problems, you can appeal again.',
    300268: 'Temporarily not required',
    300269: 'Cancel appeal',
    300270: 'You cannot appeal within {appealMinMinute} minutes',
    300271: 'You have successfully bought { quantity }{ currency }',
    300272: 'You have successfully sold { quantity }{ currency }',
    300273: 'Appeal description cannot be empty',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_en