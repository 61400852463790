import faLocal from 'element-ui/lib/locale/lang/fa'
import ac_fa from "./activity/ac_fa";

const fa = {
    400000: 'آکادمی',
    400001: 'اخبار',
    400002: 'داده‌ها',
    400003: 'فیلم‌ها',
    400004: 'آکادمی',
    400005: 'ورود',
    400006: 'ثبت نام',
    400007: 'کیف پول',
    400008: 'نمای کلی کیف پول',
    400009: 'حساب بازار اصلی',

    400010: 'حساب P2P',
    400011: 'فیوچرز',
    400012: 'تاریخچه کیف پول',
    400013: 'سفارش‌های بازار آزاد',
    400014: 'سفارشات فیوچرز',
    400015: 'بررسی اجمالی',
    400016: 'تایید هویت',
    400017: 'امنیت حساب',
    400018: 'رفرال',
    400019: 'اولویت ها',

    400020: 'مرکز کوپن',
    400021: 'خارج شدن',
    400022: '<em class="normal">{num}</em> اعلان های معلق',
    400023: 'پاک کردن همه',
    400024: 'بیشتر',
    400025: 'اطلاعاتی وجود ندارد',
    400026: 'برای دانلود برنامه اسکن کنید',
    400027: 'دانلودهای بیشتر',
    400028: 'جزئیات اعلان',
    400029: 'فهمیدم',

    400030: 'P2P',
    400031: 'بازار',
    400032: 'اسپات',
    400033: 'فیوچرز',
    400034: 'Super Start',
    400035: 'آدرس وجود ندارد',
    400036: 'امن ترین پلتفرم تجارت صرافی دیجیتال جهان!',
    400037: 'درباره ما',
    400038: 'مقررات',
    400039: 'حریم خصوصی',

    400040: 'اطلاعیه ها',
    400041: '学习',
    400042: 'مرکز مبتدیان',
    400043: 'تجارت Spot',
    400044: 'فیوچرز',
    400045: 'سوالات متداول',
    400046: 'خدمات',
    400047: 'کارمزدها',
    400048: 'قوانین',
    400049: 'برنامه لیست',

    400050: 'رفرال',
    400051: 'ارتباط با ما',
    400052: 'سوالات متداول',
    400053: 'ارسال درخواست',
    400054: 'مخاطبین تجاری',
    400055: 'همکاری نهادی',
    400056: 'زبان و منطقه ',
    400057: 'ارز',
    400058: 'زبان و منطقه خود را انتخاب کنید',
    400059: 'قیمت ارز خود را انتخاب کنید',

    400060: 'انتقال',
    400061: 'در نقل و انتقالات داخلی، SuperEx هزینه‌ای دریافت نمی کند',
    400062: 'از',
    400063: 'به',
    400064: 'ارز',
    400065: 'تخفیف کارمزد',
    400066: 'کراس',
    400067: 'ایزوله',
    400068: 'اسپلیپ',
    400069: 'موجودی',

    400070: 'ارز دیجیتال',
    400071: 'جستجو کردن',
    400072: 'میزان',
    400073: 'دردسترس',
    400074: 'همه',
    400075: 'انتقال را تایید کنید',
    400076: 'باز',
    400077: 'بعدا امتحان کنید',
    400078: 'حساب فیوچرز را باز کنید',
    400079: 'هنوز حساب فیوچرز باز نکرده‌اید، بنابراین انتقال موقتاً در دسترس نیست.',

    400080: 'بعد از {timeout} ثانیه دوباره بزنید',
    400081: 'کد ارسال شد',
    400082: 'تمام ارزهای دیجیتال',
    400083: 'تمام ارزهای فیات',
    400084: 'همه وضعیت',
    400085: 'منطقه اختیاری',
    400086: 'شخص ثالث',
    400087: 'راهنما',
    400088: 'سفارشات',
    400089: 'مرکز تجاری P2P',

    400090: 'مرکز کاربر P2P',
    400091: 'روش پرداخت',
    400092: 'ارسال آگهی جدید',
    400093: 'تبلیغات من',
    400094: 'بیشتر',
    400095: 'درخواست دادن',
    400096: 'لغو کنید',
    400097: 'شما به طور موقت قادر به ارسال تبلیغات نیستید',
    400098: 'ابتدا برای تبدیل شدن به یک تبلیغ اقدام کنید',
    400099: 'تأیید شود',

    400100: 'تایید نشده',
    400101: 'شما تأیید هویت را تکمیل نکرده اید',
    400102: 'برای امنیت حساب خود با سایر کاربران تجاری، لطفاً ابتدا تأیید هویت را تکمیل کنید',
    400103: 'نام مستعار را تنظیم کنید',
    400104: 'نام مستعار حساب خود را تنظیم کنید، توصیه می شود از نام واقعی خود استفاده نکنید',
    400105: 'متأسفیم، در حال حاضر نمی توانید برای تاجر شدن درخواست دهید.',
    400106: 'راه اندازی با موفقیت انجام شد',
    400107: 'مزایای سوپراکس P2P',
    400108: '0 کارمزد',
    400109: 'در SuperEx P2P، می توانید بیت کوین و سایر ارزهای دیجیتال را به راحتی و به سرعت معامله کنید و طرفی که سفارش می دهد با کارمزد صفر معامله می کند.',

    400110: 'روش های پرداخت انعطاف پذیر',
    400111: 'سوپراکس P2P به فروشندگان اجازه می دهد آزادانه روش های پرداخت را انتخاب کنند.',
    400112: 'تجارت با قیمت های بهتر',
    400113: 'سوپراکس P2P به کاربران این امکان را می دهد که قیمت های خود را تعیین کنند و قیمت مورد نظر خود را برای خرید و فروش ارز دیجیتال انتخاب کنند.',
    400114: 'ارزهای رمزپایه را ایمن معامله کنید',
    400115: 'سوپراکس P2P مکانیسم برداشت وجه نقد T+n را اتخاذ می کند، بازرگانان را به شدت مدیریت می کند، و تراکنش های مشکوک را با داده های بزرگ کنترل ریسک غربال می کند تا از ایمنی دارایی های پلت فرم اطمینان حاصل کند و کاربران بتوانند با خیال راحت معامله کنند.',
    400116: '{amount} جلد',
    400117: '{num} %نرخ معامله',
    400118: 'قیمت',
    400119: 'محدودیت زمانی برای پرداخت',

    400120: 'روش پرداخت فروشنده',
    400121: 'شرایط معامله',
    400122: 'من پرداخت خواهم کرد',
    400123: 'دریافت خواهم کرد',
    400124: 'میخوام بفروشم',
    400125: 'روش پرداخت',
    400126: 'تنظیم روش جمع آوری',
    400127: 'افزودن روش پرداخت',
    400128: 'قیمت واحد مرجع{price} ',
    400129: 'خرید کنید',

    400130: 'فروش',
    400131: 'به منظور حفظ امنیت وجوه، محدودیت برداشت {limit} برای دارایی های خریداری شده وجود خواهد داشت.',
    400132: 'بیشتر بدانید ',
    400133: 'باشه',
    400134: 'با توجه به کشور گواهی خود و مشخصات عملیاتی پلتفرم، لطفاً برای تراکنش ها به ارز محلی خود بروید',
    400135: 'محدودیت خرید: {min}-{max} {fiat}',
    400136: 'محدودیت فروش: {min}-{max} {fiat}',
    400137: 'تبلیغ کنندگان',
    400138: 'محدودیت / مقدار',
    400139: 'تجارت',

    400140: 'میزان',
    400141: 'حد',
    400142: 'روند',
    400143: 'باشه ',
    400144: 'قبل از شروع معامله، لطفاً تأیید هویت را تکمیل کنید',
    400145: 'لغو سفارش شما امروز بیش از {num} برابر شده است و نمی توانید به معامله ادامه دهید. لطفاً بعد از {time} دوباره امتحان کنید',
    400146: 'شما به حداکثر تعداد سفارشات معلق ({num}) رسیده اید، لطفاً ابتدا سفارش در حال انجام را تکمیل کنید.',
    400147: 'این تبلیغ‌کننده به حداکثر تعداد سفارش‌های معلق رسیده است، لطفاً بعداً فعالیت کنید یا تبلیغ دیگری را امتحان کنید.',
    400148: 'شما شرایط آگهی دهنده را برآورده نکرده اید، لطفاً یک آگهی دیگر را امتحان کنید.',
    400149: 'انتخاب',

    400150: 'P2P چگونه کار می کند',
    400151: 'خرید ارز',
    400152: 'فروش ارز',
    400153: 'سفارش خود را ثبت کنید',
    400154: 'پس از ثبت سفارش، دارایی های فروشنده برای آن تراکنش توسط SuperEx P2P مدیریت می شود.',
    400155: 'پرداخت به فروشنده',
    400156: 'از طریق روش پرداخت موجود در اطلاعات تراکنش، پول را برای فروشنده ارسال کنید و پس از تکمیل نقل و انتقال، روی «پرداخت، به فروشنده اطلاع دهید» در SuperEx P2P کلیک کنید.',
    400157: 'ارز دیجیتال خود را دریافت کنید',
    400158: 'هنگامی که فروشنده دریافت پرداخت را تأیید کرد، ارز نگهبانی به شما تعلق می گیرد.',
    400159: 'پرداخت را تایید کنید',

    400160: 'سابقه تراکنش در حساب خود را بررسی کنید و مطمئن شوید که پرداخت را از خریدار دریافت کرده اید.',
    400161: 'ارز دیجیتال ارسال کنید',
    400162: 'پس از تایید دریافت پرداخت، ارز دیجیتال را در سوپراکس P2P برای خریداران ارسال کنید.',
    400163: 'میزان',
    400164: 'مقدار را وارد کنید',
    400165: 'فیات',
    400166: 'تمامی روش های پرداخت',
    400167: 'شناسه آگهی',
    400168: 'تایید',
    400169: 'آیا مطمئن هستید که می خواهید تبلیغ را حذف کنید؟',

    400170: '1. کاربرانی که تبلیغات را حذف می کنند، نمی توانند از طریق تبلیغات با شما معامله کنند ',
    400171: '2. حذف تبلیغات تاثیری بر سفارشات در حال انجام ندارد',
    400172: 'آیا مطمئن هستید که می خواهید این آگهی را حذف کنید؟',
    400173: 'ویرایش کنید',
    400174: 'حذف',
    400175: 'اضافه',
    400176: 'حذف شده',
    400177: 'ایجاد شده',
    400178: 'حداقل حد',
    400179: 'مبلغ باقیمانده / مبلغ سفارش',

    400180: 'قیمت سفارش',
    400181: 'تایپ کنید',
    400182: 'فهرست موفق',
    400183: 'همه انواع',
    400184: 'وضعیت',
    400185: 'شناسه آگهی/ارز/تاریخ',
    400186: 'عمل کند',
    400187: 'با توجه به مشخصات عملیاتی کشور یا منطقه ای که هویت سوپراکس شما در آن تأیید شده است و پلت فرم، لطفاً ارز قانونی پشتیبانی شده را برای تراکنش انتخاب کنید',
    400188: 'الزامات تبلیغ کننده را برآورده نمی کند',
    400189: 'حذف شده',

    400190: 'با موفقیت حذف شد',
    400191: 'ارسال تبلیغات',
    400192: 'ویرایش آگهی',
    400193: 'قیمت ثابت',
    400194: 'قیمت شناور',
    400195: 'درصد تغییرات قیمت',
    400196: 'لطفا وارد کنید',
    400197: 'قیمت ',
    400198: 'قیمت مرجع بازار',
    400199: 'تعیین درصد شناور به شما امکان می دهد با درصدی بالاتر یا کمتر از قیمت مرجع واقعی بازار خرید و فروش کنید.',

    400200: 'قیمت های ثابت با نوسانات بازار تغییر نمی کند',
    400201: 'محدودیت های معاملاتی',
    400202: 'حجم تراکنش را وارد کنید',
    400203: 'تعداد معاملات',
    400204: 'حداقل محدودیت واحد',
    400205: 'حداقل حد تراکنش واحد را وارد کنید',
    400206: 'حداکثر محدودیت واحد',
    400207: 'حداکثر حد مجاز را وارد کنید',
    400208: 'حداکثر',
    400209: 'آگهی نیاز به کارمزدی در حدود {rate}% {currency} دارد که در حال حاضر {available} {currency} موجود است',

    400210: 'روش پرداخت',
    400211: 'حداکثر 3 را انتخاب کنید',
    400212: 'در حال حاضر روش پرداخت وجود ندارد',
    400213: 'محدودیت زمانی پرداخت را انتخاب کنید',
    400214: 'محتوا را وارد کنید',
    400215: 'شرایط معامله کاربر',
    400216: 'افزایش محدودیت کاربر معاملاتی، شانس نمایش آگهی شما را کاهش می دهد',
    400217: 'ثبت نام کامل',
    400218: 'روز بعد',
    400219: 'اکنون راه اندازی کنید',

    400220: 'بعداً راه اندازی کنید',
    400221: 'افشای ریسک',
    400222: 'باید یک عدد وارد کنید',
    400223: 'روش پرداخت را انتخاب کنید',
    400224: 'قیمت شناور را وارد کنید',
    400225: 'قیمت ثابت را وارد کنید',
    400226: 'قیمت از {num}% از بازار منحرف شده است، ادامه انتشار مخاطره آمیز خواهد بود، لطفا تایید کنید که آیا به انتشار تبلیغات ادامه میدهید یا خیر',
    400227: 'حداکثر حد منفرد باید بیشتر از حداقل حد منفرد باشد',
    400228: 'حداکثر حد مجاز نباید از {num} {fiat} بیشتر باشد.',
    400229: 'حداقل حد تراکنش واحد نباید کمتر از {num} {fiat} باشد',

    400230: 'حداقل حد یک سفارش باید کمتر از حداکثر محدودیت تک سفارش باشد',
    400231: 'حداکثر مبلغ تراکنش نباید بیشتر از {num} {currency} باشد.',
    400232: 'انتشار موفق',
    400233: 'تجارت P2P در حال حاضر برای کاربران مجاز کیف پول باز نیست، لطفاً از حساب سوپراکس خود برای ورود به سیستم و تجارت P2P استفاده کنید.',
    400234: 'شبکه DAO',
    400235: 'Roadmap',
    400236: 'دوستان خود را دعوت کنید',
    400237: 'سوالات متداول',
    400238: 'پایگاه ایردراپ',
    400239: 'شبکه اصلی را جستجو کنید',

    400240: 'تست نت را جستجو کنید',
    400241: 'اسناد توسعه دهنده',
    400242: 'مرکز رویدادها',
    400243:' ف  با استفاده از سایت های سوپراکس/و یا حفظ حساب خود، <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/fa/articles/11479184545817">با شرایط استفاده از خدمات</a> بروز شده، بیانیه <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/fa/articles/11479060809881">حریم خصوصی و خط مشی</a><a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/fa/articles/20761343762329"> کوکی ما موافقت می کنید.</a> با کلیک بر روی "X،" تأیید می‌کنید که شرایط و خط‌مشی‌های فوق‌الذکر را خوانده‌اید و با آن موافقت کرده‌اید. ',
    400244: 'خرید تتر | دریافت تتر | سوپراکس P2P',
    400245: 'مرکز وظیفه',
    400246: 'ارز',
    400247: 'دریافت',
    400248: 'مخارج',
    400249: 'دردسترس',

    400250: 'پرداخت می کند',
    400251: 'جزئیات سفارش',
    400252: 'مبلغ (شامل کارمزد تراکنش)',
    400253: 'زمان رسیدن',
    400254: '≈{time} دقیقه',
    400255: 'اطلاع',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: 'من سلب مسئولیت را خوانده ام و با آن موافقم',
    400261: 'چگونه در سوپراکس ارز دیجیتال بخریم؟',
    400262: 'نحوه فروش ارزهای دیجیتال در سوپراکس？',
    400263: 'سفارش ارز دیجیتال خود را انجام دهید',
    400264: 'ارز فیات را که می خواهید با آن پرداخت کنید و کریپتویی را که می خواهید بخرید انتخاب کنید. مبلغی را که می خواهید خرج کنید وارد کنید.',
    400265: 'احرازهویت خود را تأیید کنید',
    400266: 'برای تکمیل پرداخت، تأیید احرازهویت را در سوپراکس و ارائه‌دهنده پرداخت کامل کنید.',
    400267: 'جزئیات سفارش خود را بررسی کنید',
    400268: 'سابقه واریز خود را برای مشاهده وضعیت سفارش خود بررسی کنید.',
    400269: '1. از چه روش های پرداختی می توانم برای خرید ارز دیجیتال استفاده کنم؟',

    400270: 'سوپراکس در حال حاضر از VISA، Mastercard، Apple Pay، Google Pay و سایر روش های پرداخت پشتیبانی می کند. ارائه دهندگان خدمات شخص ثالث پشتیبانی شده عبارتند از Mercuryo و alchemypay.',
    400271: '2. کدام ارزهای رمزنگاری شده را می توانم خریداری کنم؟',
    400272: 'سوپراکس از ارزهای دیجیتال اصلی مانند BTC، ETH، USDT، LTC، USDC، XRP، BCH، CRV و TRX پشتیبانی می کند.',
    400273: '3. آیا سوپراکس بر کیفیت و قیمت ارائه دهندگان خدمات شخص ثالث پشتیبانی می شود؟',
    400274: 'سوپراکس تعدادی از ارائه دهندگان خدمات با شهرت، محبوبیت و امنیت صنعت قابل اعتماد را به دقت انتخاب کرده است تا به کاربران خدمات کارآمد ارائه دهد.',
    400275: '4. برای خرید ارز دیجیتال چه هزینه هایی باید پرداخت کنم؟',
    400276: 'شما هزینه ای را به ارائه دهنده خدمات شخص ثالث و هزینه انتقال زنجیره ای پرداخت می کنید. سوپراکس هیچ هزینه ای دریافت نمی کند.',
    400277: '5. پس از پرداخت چقدر طول می کشد تا ارز دیجیتال را دریافت کنید؟',
    400278: 'پس از تکمیل پرداخت شما در پلتفرم ارائه دهنده خدمات شخص ثالث، ارز دیجیتال شما در حدود 2 تا 10 دقیقه به حساب شما در سوپراکس واریز می شود.',
    400279: '6. اگر در فرآیند خرید با مشکل مواجه شوم چه می شود؟',

    400280: 'در صورت بروز هرگونه مشکل در فرآیند تراکنش با پشتیبانی مشتری تماس بگیرید. اگر پس از تکمیل پرداخت، ارز دیجیتال را دریافت نکرده‌اید، با ارائه‌دهنده خدمات شخص ثالث تماس بگیرید تا جزئیات سفارش را بررسی کنید (این روش معمولاً کارآمدترین روش است). به دلیل IP منطقه فعلی شما یا دلایل خاص سیاست، باید تأیید انسانی را انتخاب کنید.',
    400281: 'محدودیت تک تراکنش:',
    400282: 'حجم را وارد کنید',
    400283: 'ارائه دهنده پرداخت',
    400284: 'گرفتن',
    400285: 'تعادل ناکافی',
    400286: "شما سوپراکس را ترک خواهید کرد و به <span>{name}</span> می‌روید، یک موسسه مالی دارای مجوز که قابلیت‌های پرداخت کارت را به عنوان شخص ثالث ارائه می‌کند. هر اتفاق خطری که در طول استفاده رخ دهد توسط <span>{name}</span> متحمل می‌شود. لطفاً<span>{name}</span> را بخوانید و موافقت کنید.<a class= 'pointer' target='_blank' href={server}>توافقنامه خدمات</a> قبل از استفاده از خدمات آناگر در مورد سپرده کارت اعتباری سؤالی دارید، لطفاً برای جزئیات بیشتر با <a class='pointer' target='_blank' href={Customer}>{name} خدمات مشتری</a> تماس بگیرید.",
    400287: 'سلب مسئولیت',
    400289: 'اکانت اسپات موجود است',
    400290: 'تایید',
    400291: 'منطقه ET',
    400288: 'امور مالی',
    400292:'حساب مالی',

    'target_1': 'اسپات',
    'target_2': 'حساب P2P',
    'target_3': '币本位合约账户',
    'target_4': 'USDⓢ-M',
    'target_5': '超级合约账户',
    'target_6': 'امور مالی - سود موجود',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'سریع سکه بخرید',

    400295:'لطفاً مرورگر safari را برای استفاده از Applepay باز کنید',




    410000:'فرودگاه SCS',
    410001:'عوامل',

    410002:'مالی',
    100756: 'بدست اوردن',
    100219: 'فعالیت',
    101154: 'تاریخچه اشتراک',



    122000:"شبکه جهانی DAO و لینک‌ رسانه های اجتماعی",
    122001:"دوستان را برای دریافت پاداش دعوت کنید",
    122002:"طرح توسعه سوپراکس",
    122003:"راه‌حل‌هایی برای مشکلات رایجی که در حین استفاده با آن مواجه می‌شوید",
    122004:"وظایف را کامل کنید تا پاداش بگیرید",
    122005:"مقدمه ای بر ET",
    122006:"مقدمه ای بر سوپراکس",
    122007:"تخفیف برای ارجاع نماینده",
    122008:"هزینه کم برای خرید ارز دیجیتال",
    122009:"خرید سریع ارزهای دیجیتال",
    122010:"بازار معاملات ارزهای دیجیتال",
    122011:"معاملات توکن در Free Market و Main Market",
    122012:"بازار معاملات فیوچرز",
    122013:"پلتفرم IEO برای پروژه های با ارزش بالا",
    122014:"پس انداز پول و مدیریت پول برای کسب درآمد",
    122015:"خرید با قیمت بسیار پایین",
    122016:"مرکز فعالیت‌های هیجان‌انگیز",
    122017:"با سکه های جدید در اکوسیستم بیت کوین پیشروی کنید",
    122018:"دریافت ایردراپ SCS در مرکز وظیفه",
    122019:"وب سایت رسمی زنجیره SCS",
    122020:"مرورگر شبکه SCS",
    122021:"مروگر تست نت شبکه SCS",
    122022:"اسنادهای توسعه دهنده",
    122023:"مرور آخرین اخبار",
    122024:"مرور سریع از آخرین رویدادها",
    122025:"بررسی SuperEx در یوتیوب",
    122026:"یادگیری همه چیز درباره بلاکچین",
    122027:"جعبه‌رمز و راز را با دوستان خود باز کنید",
    122028: 'پاداش mystery box',

    //合约积分
    9100007: 'امتیاز معاملاتی',
    9100031: 'مرکز فعالیت امتیاز معاملاتی',

    9100032: 'وجوه کمی',
    9100033: 'مدیریت حرفه ای دارایی',

    8100000: 'بازخورد کاربر',
    8000153: 'فهرست میم',
    8000154: 'بازارهای پروتکل BTC',
    6000072: 'سفارش‌های بازار آزاد',
    6000073: 'حساب بازار آزاد',

    9100162: 'مدیریت API',
    8100013: 'کانال تأیید رسمی',
    8100014: 'کانال فروش',
    ...ac_fa,
    ...faLocal
}
export default fa;
