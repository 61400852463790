import router from './router'
import {getToken} from './utils/common' // 验权
import store from './store'
import urls from "@/utils/baseUrl";
// const whiteList = ['/login', '/404', '/index'] // 不重定向白名单
const BlockList = [] //未登录时，进入这些页面会直接返回到登录页
router.beforeEach((to, from, next) => {
    if (getToken()) {
        if (to.path === '/login') {
            next({path: '/'})
        } else {
            store.dispatch('GetInfo').then(res => { // 拉取用户信息
                next()
            }).catch((err) => {
                store.dispatch('FedLogOut').then(() => {
                    next({path: '/'})
                })
            })
        }
    } else {
        if (BlockList.indexOf(to.path) == -1) {
            next()
        } else {
            window.location.href = urls.linkUrl+'/login'
        }
    }
})
router.afterEach((to, from, next) => {

})

