import axios from "@/utils/axios";

var user = "/user";
var resource = "/resource";

// 退出登录
export function loginOut() {
  return axios({
    url: user + "/logout",
    method: "post",
  });
}

export function getLanguageList() {
  return axios({
    url: user + "/config/languages",
    method: "get",
  });
}

export function getRateList() {
  return axios({
    url: user + "/config/rates",
    method: "get",
  });
}
// 获取用户基本信息
export function getUserInfo() {
  return axios({
    url: user + "/user/base",
    method: "get",
  });
}
// 获取等级配置
export function levelConfigApi() {
  return axios({
    url: user + "/vip/level/config",
    method: "get",
  });
}
// 未读消息数
export function unreadList() {
  return axios({
    url: user + "/internal-msgs/unread",
    method: "get",
  });
}

// 分页查询所有站内信
export function getNoticeList(data) {
  return axios({
    url: user + "/internal-msgs",
    method: "get",
    data,
  });
}
// 消息已读
export function readMsg(id) {
  return axios({
    url: user + `/internal-msgs/${id}`,
    method: "post",
  });
}
// 全部清除
export function removeAllMsg() {
  return axios({
    url: user + "/internal-msgs/remove/all",
    method: "get",
  });
}
// 获取裂变域名
export function getDomains() {
  return axios({
    url: user + "/invite/domains",
    method: "get",
  });
}

// 活动视频
export function videoApi() {
  return axios({
    url: resource + "/college/video",
    method: "get",
  });
}
export function linksApi(data) {
  return axios({
    url: resource + "/contacts",
    method: "get",
    data,
  });
}
// 行为验证校验 2.0（第四代极验）
export function verifyValidationV2(data) {
  return axios({
    url: user + "/validation/v2/behavior-verify",
    method: "post",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function sendCode(type, data) {
  return axios({
    url: user + "/validation" + (type == "phone" ? "/sms" : "/email"),
    method: "post",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    data,
  });
}
//在线--获取验证码（当次数大于两次调用极限验证）
export function onlineSendCode(type, data) {
  return axios({
    url: user + "/validation/online" + (type == "phone" ? "/sms" : "/email"),
    method: "post",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    data,
  });
}
export function loginSendCode(type, data) {
  return axios({
    url: user + "/validation/limit" + (type == "phone" ? "/sms" : "/email"),
    method: "get",
    data,
  });
}
export function bindSendCode(type, data) {
  return axios({
    url: user + "/validation/binding" + (type == "phone" ? "/sms" : "/email"),
    method: "get",
    data,
  });
}
// 钱包绑定邮箱获取验证码
export function walletBindCode(data) {
  return axios({
    url: user + "/validation/binding/wallet-email",
    method: "get",
    data,
  });
}

// 开通合约
export function openSwap() {
  return axios({
    url: user + "/user/swap/open",
    method: "post",
  });
}
