const ac_fr = {
    300001: 'Renvoyer dans {timeout}s',
    300002: 'Envoyer le Code',
    300003: 'Vérification de sécurité',
    300004: 'Pour la sécurité de votre compte, veuillez effectuer les vérifications suivantes',
    300005: "Code D'Authentication",
    300006: 'Mobile',
    300007: 'Code de vérification par SMS',
    300008: 'Code de vérification par e-mail',
    300009: 'Soumettre',
    300010: 'Un code à 6 chiffres sera envoyé sur votre téléphone {phone}',
    300011: 'Veuillez entrer le code à 6 chiffres de SuperEx/Google/Microsoft',
    300012: 'Le code sera envoyé à @{email}',
    300013: 'Confirmer',
    300014: 'Annuler',
    300015: 'Veuillez entrer un code à 6 chiffres',
    300016: 'Annonceurs certifiés',
    300017: 'Dépôt de garantie',
    300018: 'Utilisateur vérifié',
    300019: 'Utilisateur non vérifié',
    300020: 'Vérification par e-mail',
    300021: 'Vérification par téléphone',
    300022: "Vérification d'identité",
    300023: 'Devenir annonceur',
    300024: 'Libérer le dépôt de garantie',
    300025: 'Taux de réussite de 30 jours',
    300026: 'Commandes de 30 jours',
    300027: 'Temps moyen de libération',
    300028: 'Temps moyen de libération : temps moyen de confirmation des actifs libérés après réception du paiement lors de la vente au cours des 30 derniers jours',
    300029: 'minute(s)',
    300030: 'Paiement moyen',
    300031: 'Compte créé',
    300032: 'J',
    300033: 'Nombre total de transactions',
    300034: 'Fois',
    300035: 'Acheter',
    300036: 'Vendre',
    300037: 'Première transaction à ce jour',
    300038: 'Conversion des transactions sur 30 jours',
    300039: 'Volume total des transactions converties',
    300040: 'Méthode de paiement P2P',
    300041: "Méthode de paiement : La méthode de paiement que vous ajoutez sera affichée à l'acheteur comme votre méthode de paiement lors de la vente de cryptomonnaie dans une transaction P2P. Assurez-vous d'utiliser votre propre compte vérifié pour garantir que l'acheteur peut vous transférer de l'argent en toute simplicité. Vous pouvez ajouter jusqu'à {num}méthodes de paiement.",
    300042: 'Ajouter un moyen de paiement',
    300043: 'Modifier',
    300044: 'Supprimer',
    300045: 'Aucune méthode de paiement',
    300046: 'Supprimer cette méthode de paiement ?',
    300047: "Vous n'avez pas terminé la vérification d'identité",
    300048: "Pour la sécurité de votre compte avec d'autres utilisateurs de trading, veuillez d'abord terminer la vérification d'identité",
    300049: 'Non vérifié',
    300050: 'Obtenir une vérification',
    300051: 'Choisir un moyen de paiement',
    300052: 'Méthode de paiement recommandée',
    300053: 'Aucune donnée',
    300054: 'Toutes les méthodes de paiement',
    300055: 'Rechercher',
    300056: 'Définir un pseudonyme',
    300057: 'Définissez le surnom de votre compte. Il est recommandé de ne pas utiliser votre vrai nom. La longueur du surnom est de 2 à 10 caractères.',
    300058: 'Télécharger',
    300059: '(Support) Format PG/PEG/PNG/BMP, moins de 5 Mo',
    300060: 'Télécharger à nouveau',
    300061: "Rappel : Lorsque vous vendez de la cryptomonnaie, la méthode de paiement que vous choisissez sera affichée à l'acheteur. Veuillez confirmer que les informations sont remplies correctement.",
    300062: 'Voulez-vous renoncer à ajouter une méthode de paiement ?',
    300063: 'Vos modifications actuelles ne seront pas enregistrées après votre sortie.',
    300064: 'Demande soumise',
    300065: 'Logo exclusif',
    300066: "Marque de certification exclusive pour les annonceurs afin d'améliorer la confiance dans les transactions",
    300067: 'Service individuel',
    300068: "Nous offrons un service individuel pour la publicité afin d'améliorer l'efficacité des transactions.",
    300069: 'Région/pays',
    300070: 'Devise fiat émise',
    300071: "Conditions d'application",
    300072: "Vérification d'identité complète",
    300073: "Le montant de l'{currency} dans le compte P2P est supérieur à {num}",
    300074: 'Transfert',
    300075: 'Échec de la demande',
    300076: 'Postuler maintenant',
    300077: 'En cours de vérification',
    300078: 'Le dépôt est-il libéré ?',
    300079: "1. Après avoir libéré le dépôt, vous ne bénéficierez plus des droits et avantages des marchands P2P",
    300080: "2. Lors de la libération du dépôt, vous ne pouvez pas avoir d'annonces ou de commandes en cours de négociation. ",
    300081: 'Pas pour le moment',
    300082: 'La page de demande de marchands est fermée',
    300083: 'OK',
    300084: 'En cours',
    300085: 'Toutes les commandes',
    300086: 'Cryptomonnaie',
    300087: 'Toutes les cryptomonnaies.',
    300088: "Type d'ordre",
    300089: 'Tout',
    300090: 'Acheter',
    300091: 'Vendre',
    300092: 'Statut',
    300093: 'Tous les statuts.',
    300094: 'Paiement en attente',
    300095: 'Payé',
    300096: 'Terminé',
    300097: 'Annulé',
    300098: 'En cours de réclamation',
    300099: 'Date',
    300100: 'Réinitialiser le filtre',
    300101: '',
    300102: 'Commande',
    300103: 'Détails de l’annonceur',
    300104: "Détails de l'utilisateur",
    300105: 'Acheter en ligne',
    300106: 'Acheter auprès du marchand',
    300107: 'Vendre au marchand',
    300108: 'Prix',
    300109: 'Méthode de paiement',
    300110: 'Limite/Quantité',
    300111: 'Trade',
    300112: 'Montant',
    300113: 'Limite',
    300114: 'Processus',
    300115: "D'accord",
    300116: 'Type / Devise',
    300117: 'Montant total',
    300118: 'Pseudo de l’autre partie',
    300119: 'Opérer',
    300120: "Demande d'annonceur certifié",
    300121: 'Informations de base',
    300122: 'Nom complet',
    300123: 'Téléphone',
    300124: 'Entrez le numéro de téléphone',
    300125: 'Indicatif régional',
    300126: 'E-mail',
    300127: "Entrez l'adresse e-mail",
    300128: "Contact d'urgence",
    300129: "Contact d'urgence",
    300130: "Numéro de contact d'urgence",
    300131: "Entrez le numéro de contact d'urgence",
    300132: 'Relation',
    300133: 'Camarde de classe',
    300134: 'Famille',
    300135: 'Autre',
    300136: 'Adresse de résidence permanente',
    300137: "Entrez l'adresse de résidence permanente",
    300138: "Téléphone : utilisé par le personnel pour traiter les commandes de plainte quotidiennes et vérifier rapidement les informations avec vous, veuillez vous assurer de conserver ce numéro de téléphone disponible.",
    300139: "Contact d'urgence et adresse permanente : utilisés pour vérifier plus avant les informations KYC de l'annonceur afin de garantir des transactions plus sécurisées. ",
    300140: 'Identification',
    300141: 'Exemple',
    300142: "*Lorsque vous prenez une photo, tenez l'avant de votre carte d'identité et incluez tout le bras et le haut de votre carte d'identité dans la photo, avec votre visage et toutes les informations sur votre carte d'identité clairs et non obstrués.",
    300143: '*Seuls les formats .jpg/.jpeg/.png sont pris en charge avec une taille maximale de 10 Mo ',
    300144: "Veuillez télécharger le certificat de propriété immobilière/le contrat de location/la facture des services publics (choisissez l'un des trois)",
    300145: "Preuve d'adresse",
    300146: "*Si l'adresse figurant sur le document est différente de l'adresse permanente indiquée dans les informations de base, nous vous demanderons d'expliquer la raison.",
    300147: "*Veuillez vous assurer que les photos que vous téléchargez sont claires et sans reflets et qu'elles ne sont pas obscurcies.",
    300148: 'Vérification vidéo',
    300149: "Tenant devant votre pièce d'identité, veuillez lire à voix haute le texte modèle suivant et enregistrer une vidéo (assurez-vous que la voix et l'image sont claires)",
    300150: "Je suis (nom), mon numéro d'identité est (numéro d'identité), ma source de financement est légale et fiable, je suis volontaire pour échanger des actifs numériques tels que le Bitcoin, je comprends pleinement les monnaies numériques et les risques potentiels, j'ai la capacité de résister aux risques et suis prêt à assumer tous les risques. De plus, je confirme que toutes les opérations sur ce compte sont effectuées par moi-même et que toutes les responsabilités légales découlant de ce compte seront assumées personnellement par moi.",
    300151: 'Télécharger la vidéo de vérification',
    300152: `La vidéo prend en charge jusqu'à 100 Mo, d'une durée recommandée de moins de 3 minutes, d'une résolution supérieure à 360P
Formats MP4/.WMV/.DGG/.MOV`,
    300153: '*MP4/.WMV/.DGG/.MOV',
    300154: 'Rappel',
    300155: 'Votre solde {currency} est insuffisant de {num}',
    300156: 'Soumettre la demande',
    300157: "Acceptez de bloquer <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span> en tant que dépôt d'annonceur et acceptez les <a href='https://support.superex.com/hc/zh-hk/articles/15269071722265'>《Conditions de l'Annonceur》</a>",
    // 300158: '',
    300159: 'Envoyez un code de vérification vocale à {phone}',
    300160: 'Vérification Vocale',
    300161: 'Paiement moyen : temps moyen de paiement pour les achats de cryptomonnaie au cours des 30 derniers jours',
    300162: '0 frais',
    300163: '1 Semaine',
    300164: '1 Mois',
    300165: '3 Mois',
    300166: "Veuillez effectuer une vérification d'identité avant de commencer les transactions.",
    300167: "Votre commande a été annulée {num} fois aujourd'hui, vous ne pouvez pas continuer la transaction. Veuillez réessayer après UTC{Symbol}{string}.",
    300168: "Vous avez atteint le nombre maximum de commandes en attente ({num}), veuillez d'abord terminer la commande en cours.",
    300169: "Cet annonceur a atteint le nombre maximum de commandes en attente, veuillez effectuer l'opération ultérieurement ou essayer une autre annonce.",
    300170: "Vous ne répondez pas aux exigences de l'annonceur, veuillez essayer une autre annonce.",
    300171: 'Configuration réussie',
    300172: "Vérifier les détails de l'autre partie",
    300173: "Contactez l'autre partie",
    300174: "Seuls les formats d'image gif/jpg/png sont pris en charge",
    300175: "La taille de l'image téléchargée ne peut pas dépasser 5MB!",
    300176: 'Téléchargement réussi',
    300177: 'Échec du téléchargement, réessayez ultérieurement',
    300178: 'Veuillez définir un pseudo',
    300179: 'La longueur du pseudo doit être comprise entre 2 et 10 caractères',
    300180: "Vous pouvez ajouter jusqu'à {num} types de méthodes de paiement",
    300181: 'Format de numéro de téléphone mobile incorrect',
    300182: "Format d'e-mail incorrect",
    300183: 'Doit contenir entre 2 et 30 caractères',
    300184: "La longueur de l'adresse doit être comprise entre 5 et 120 caractères",
    300185: 'Sélectionner la relation avec la personne',
    300186: "Photo d'identité non téléchargée",
    300187: "Preuve d'adresse non téléchargée",
    300188: 'Vidéo de vérification non téléchargée',
    300189: 'Soumis avec succès',
    300190: 'Veuillez télécharger un format vidéo correct',
    300191: 'La taille de la vidéo ne peut pas dépasser 100 Mo',
    300192: "Échec de l'envoi",
    300193: '{num}minutes',
    300194: "Veuillez effectuer une vérification d'identité avant de commencer les transactions.",
    300195: 'Copié avec succès',
    300196: 'Copie échouée',
    300197: 'Dates',
    300198: 'Autorisation de publicité',
    300199: "Les annonceurs peuvent librement publier des annonces, ce qui rend l'achat et la vente plus flexibles et pratiques",
    300200: 'Contact',
    300201: "Veuillez télécharger une photo de vous tenant votre pièce d'identité",
    300202: 'Supprimé',
    300203: 'Téléversement',
    300204: 'Veuillez confirmer que le paiement a été effectué au vendeur selon la méthode de paiement suivante.',
    300205: 'Numéro de commande',
    300206: 'Créé',
    300207: 'Prix total',
    300208: "Veuillez effectuer le paiement à l'annonceur dans les {expiresTimeMinute}, les commandes seront automatiquement annulées après ce délai.",
    300209: "Veuillez vous assurer de cliquer sur le bouton ci-dessous pour notifier le vendeur du paiement après que le transfert soit terminé, sinon la commande sera automatiquement annulée après l'expiration du délai de paiement, entraînant une perte d'actifs !",
    300210: "J'ai effectué le paiement. Étape suivante",
    300211: 'Annuler la commande',
    300212: "En attente du paiement de l'acheteur",
    300213: `Le paiement de l'acheteur devrait être reçu dans les <span style="color: #ffc72b;"> 3minutes </span>`,
    300214: "J'ai confirmé le paiement.",
    300215: "S'il y a un problème avec la transaction, contacter directement l'autre partie est le moyen le plus efficace. Vous pouvez télécharger les reçus de paiement et les informations de compte dans la fenêtre de discussion pour que les deux parties négocient et vérifient.",
    300216: "Instructions d'appel",
    300217: "Lancer une réclamation ne peut pas vous aider à récupérer directement vos actifs, veuillez attendre l'intervention du service clientèle pour voir comment procéder.",
    300218: "Après l'intervention du service clientèle, cela peut prendre de 12 à 48 heures pour traiter la réclamation, veuillez suivre les progrès du traitement de la réclamation.",
    300219: "Les réclamations malveillantes perturbent l'ordre normal d'exploitation de la plate-forme, les comptes seront gelés en cas de gravité. ",
    300220: "Voici comment le vendeur reçoit le paiement : Le paiement nécessite de quitter la plateforme et d'utiliser votre compte vérifié pour transférer de l'argent à l'autre partie.",
    300221: 'Confirmer le paiement',
    300222: "Veuillez confirmer que vous avez effectué le paiement au vendeur en utilisant le mode de paiement suivant. Si vous n'avez pas payé, ne cliquez pas sur « Paiement effectué ».",
    300223: 'Conseil : Je comprends que lors du paiement, je dois quitter la plateforme pour effectuer le transfert moi-même. SuperEx ne débitera pas automatiquement les fonds.',
    300224: "J'ai utilisé un compte correspondant à la Vérification de Vrai Nom SuperEx pour effectuer un paiement.",
    300225: 'Annuler',
    300226: 'Confirmer',
    300227: 'Veuillez payer dans les <span style="color: #ffc72b;">{timer}</span>',
    300228: 'Moyen de paiement',
    300229: 'En attente de confirmation de réception du vendeur',
    300230: "Si vous avez déjà payé, veuillez contacter l'autre partie pour confirmer.",
    300231: 'Appel',
    300232: 'Annuler',
    300233: 'Assurez-vous de vous connecter à votre compte pour confirmer que le montant est disponible dans votre "Solde disponible".',
    300234: 'Je confirme avoir reçu le montant correct',
    300235: 'Confirmer la réception',
    300236: 'Commande en cours de réclamation',
    300237: "L'autre partie a payé, veuillez confirmer la réception.",
    300238: 'Retour',
    300239: 'Vous avez vendu {quantity}{currency} avec succès',
    300240: 'Terminé',
    300241: 'Voir les actifs',
    300242: 'La transaction a réussi',
    300243: 'Vous avez avec succès',
    300244: 'Méthode de paiement',
    300245: 'Moyen de paiement',
    300246: 'Conseils de sécurité',
    300247: `1. Ne participez pas à la fraude et à toute activité criminelle (comme acheter en tant que tiers, blanchiment d'argent, prêt à taux élevé, projet de fonds, arnaques en ligne et rencontres sur Internet, etc.);
     2. Il est interdit d'utiliser des fonds illégaux (tels que le jeu, les fonds, les escroqueries, etc.) pour les transactions de devises numériques. `,
    300248: 'Après <span style="color:#FFC72B">{appealTime}</span>, vous pouvez faire appel',
    300249: '1.Si vous avez déjà payé le vendeur, veuillez ne pas annuler la transaction.',
    300250: "2. Règles d'annulation : Si l'acheteur a accumulé {num} annulations le même jour, il ne peut pas passer une autre commande le même jour.",
    300251: "La raison de l'appel sera affichée simultanément aux deux parties et au service client, veuillez noter la protection des informations privées.",
    300252: "Veuillez ne pas croire n'importe quelle raison de se dépêcher de libérer les cryptomonnaies et libérer les actifs crypto après avoir confirmé la réception du paiement pour éviter les pertes. ",
    300253: "Raison de l'appel (obligatoire)",
    300254: 'Contact',
    300255: 'Coordonnées',
    300256: 'Je souhaite faire appel',
    300257: "Veuillez sélectionner le motif de l'appel",
    300258: 'Description de la plainte',
    300259: 'Veuillez décrire autant que possible les informations de la réclamation',
    300260: 'Entrez votre adresse e-mail',
    300261: 'La commande a été annulée',
    300262: 'Vous avez dépassé le délai de paiement. La commande a été annulée automatiquement.',
    300263: "Le mode de paiement n'est pas disponible",
    300264: 'Si vous avez des questions, veuillez contacter le service clientèle',
    300265: "L'appel a-t-il été annulé ?",
    300266: '1. Vous pouvez continuer à trader après avoir retiré votre appel, et votre commande ne sera pas annulée.',
    300267: "2. Si vous rencontrez d'autres problèmes, vous pouvez faire appel à nouveau ",
    300268: 'Temporairement non requis',
    300269: 'Annuler la réclamation',
    300270: 'Vous ne pouvez pas faire appel dans les {appealMinMinute} minutes',
    300271: 'Vous avez acheté avec succès {quantity}{currency}',
    300272: 'Vous avez vendu {quantity}{currency} avec succès',
    300273: "La description de l'appel ne peut pas être vide",
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_fr;