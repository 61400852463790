const ac_es = {
    300001: 'Reenviar en {timeout} segundos',
    300002: 'Enviar código',
    300003: 'Verificación de Seguridad',
    300004: 'Para la seguridad de su cuenta, complete las siguientes operaciones de verificación',
    300005: 'Código de autenticación',
    300006: 'Móvil',
    300007: 'Código de verificación del teléfono',
    300008: 'Código de verificación de correo electrónico',
    300009: 'Presentar',
    300010: 'El código de 6 dígitos se enviará a {phone}',
    300011: 'Introduzca el código de verificación SuperEx/Google/Microsoft de 6 dígitos',
    300012: 'El código se enviará a {email}',
    300013: 'Confirmar',
    300014: 'Cancelar',
    300015: 'Introduce el código de verificación de 6 dígitos',
    300016: 'Anunciantes certificados',
    300017: 'Depósito de seguridad',
    300018: 'Usuario verificado',
    300019: 'Usuarios no casados',
    300020: 'Verificación de correo electrónico',
    300021: 'Verificación telefónica',
    300022: 'Verificación de identidad ',
    300023: 'Conviértete en anunciante',
    300024: 'Liberar el depósito de seguridad',
    300025: 'Tasa de éxito de 30 días',
    300026: 'Órdenes de 30 días',
    300027: 'Tiempo medio de envío',
    300028: 'Tiempo promedio de envío: el tiempo promedio de confirmación de las monedas liberadas después de recibir el pago al vender monedas en los últimos 30 días',
    300029: 'minutos',
    300030: 'Pago promedio',
    300031: 'Cuenta creada',
    300032: 'Días',
    300033: 'Número total de pedidos',
    300034: ' veces',
    300035: 'Comprar',
    300036: 'Vender',
    300037: 'Primera transacción hasta la fecha',
    300038: 'Conversión de transacciones de 30 días',
    300039: 'Volumen total de transacciones convertido',
    300040: 'Método de pago P2P',
    300041: 'Método de pago: el método de pago que agregue se mostrará al comprador como su método de pago al vender criptomonedas en una transacción P2P. Asegúrese de usar su propia cuenta verificada para asegurarse de que el comprador pueda transferirle dinero sin problemas. Puedes añadir hasta {num} métodos de pago.',
    300042: 'Añadir método de pago',
    300043: 'Editar',
    300044: 'Eliminar ',
    300045: 'Sin método de pago',
    300046: '¿Eliminar este método de pago?',
    300047: 'No has completado la verificación de identidad',
    300048: 'Para la seguridad de su cuenta con otros usuarios comerciales, complete primero la verificación de identidad',
    300049: 'Sin verificar',
    300050: 'Obtener la Verificación',
    300051: 'Seleccionar método de pago',
    300052: 'Método de pago recomendado',
    300053: 'Sin datos',
    300054: 'Todos los métodos de pago',
    300055: 'Búsqueda',
    300056: 'Establecer el apodo',
    300057: 'Establece el apodo de tu cuenta. Se recomienda no usar tu nombre real. La longitud del apodo es de 2 a 10 caracteres.',
    300058: 'Subir',
    300059: '*(Soporte) Formato PG/PEG/PNG/BMP, menos de 5 MB',
    300060: 'Subir de nuevo',
    300061: 'Recordatorio: Cuando venda criptomonedas, el método de pago que elija se mostrará al comprador, confirme que la información se ha rellenado correctamente.',
    300062: '¿Quieres optar por no añadir un método de pago?',
    300063: 'Tus ediciones actuales no se guardarán después de salir de las modificaciones',
    300064: 'Solicitud presentada',
    300065: 'Logotipo exclusivo',
    300066: 'Marca de certificación exclusiva para que los anunciantes mejoren la confianza de las transacciones',
    300067: 'Servicio uno a uno',
    300068: 'Proporcionamos un servicio individual de publicidad para mejorar la eficiencia de las transacciones',
    300069: 'Región/país',
    300070: 'Moneda fiduciaria que se puede emitir',
    300071: 'Condiciones de la solicitud',
    300072: 'Verificación de identidad completa',
    300073: 'La cantidad de {currency} en la cuenta P2P es superior a {num}',
    300074: 'Ir a transferencia',
    300075: 'Error en la solicitud',
    300076: 'Aplicar ahora',
    300077: 'En revisión',
    300078: '¿Se ha liberado el depósito?',
    300079: '1. Después de liberar el depósito, no disfrutará de los derechos e intereses de los comerciantes de P2P ',
    300080: '2. Al liberar el depósito, no se pueden intercambiar anuncios u órdenes',
    300081: 'Por ahora no',
    300082: 'El portal de aplicaciones para comerciantes está cerrado.',
    300083: 'Ok',
    300084: 'En curso',
    300085: 'Todos los pedidos',
    300086: 'Criptomoneda',
    300087: 'Todas las criptomonedas',
    300088: 'Tipo de pedido',
    300089: 'Todo',
    300090: 'Comprar',
    300091: 'Vender',
    300092: 'Estatus',
    300093: 'Todo el estado',
    300094: 'Pago pendiente',
    300095: 'Remunerado',
    300096: 'Completado',
    300097: 'Cancelado',
    300098: 'Queja en curso',
    300099: 'Fecha',
    300100: 'Restablecer filtro',
    300101: '',
    300102: 'Órdenes',
    300103: 'Detalles del anunciante',
    300104: 'Detalles del usuario',
    300105: 'Comprar en línea',
    300106: 'Comprar al comerciante',
    300107: 'Vender al comerciante',
    300108: 'Precio',
    300109: 'Método de pago',
    300110: 'Límite / Cantidad',
    300111: 'Comercio',
    300112: 'Cantidad',
    300113: 'Limitación',
    300114: 'Proceso',
    300115: 'Ok',
    300116: 'Tipo / Moneda',
    300117: 'Importe total',
    300118: 'Apodo de la otra parte',
    300119: 'Funcionar',
    300120: 'Solicitud de anunciante certificado',
    300121: 'Información básica',
    300122: 'Nombre completo',
    300123: 'Teléfono',
    300124: 'Introduce el número de teléfono',
    300125: 'Código de la zona',
    300126: 'Correo electrónico',
    300127: 'Introduzca la dirección de correo electrónico',
    300128: 'Contacto de emergencia',
    300129: 'Introduzca la persona de contacto de emergencia',
    300130: 'Número de contacto de emergencia',
    300131: 'Introduzca el número de contacto de emergencia',
    300132: 'Relación',
    300133: 'Compañero de clase',
    300134: 'Familia',
    300135: 'Otro',
    300136: 'Dirección de residencia permanente',
    300137: 'Introduzca la dirección de residencia permanente',
    300138: 'Teléfono: se utiliza para que el personal procese los pedidos de quejas diarias para verificar rápidamente la información con usted, asegúrese de mantener este número de teléfono disponible.',
    300139: 'Contacto de emergencia y dirección permanente: se utiliza para verificar aún más la información KYC del anunciante para garantizar transacciones más seguras".',
    300140: 'Designación',
    300141: 'Ejemplo',
    300142: "*Cuando tome una foto, sostenga la parte delantera de su tarjeta de identificación y tome todo el brazo y la parte superior del cuerpo de su tarjeta de identificación en la foto, con su cara y toda la información de su tarjeta de identificación clara y sin obstáculos.",
    300143: '*Solo se admite el formato .jpg/.jpeg/.png con un tamaño máximo de 10 MB',
    300144: 'Por favor, cargue el certificado de bienes raíces/contrato de alquiler/factura de servicios públicos (elija uno de los tres)',
    300145: 'Prueba de domicilio',
    300146: '*Si la dirección en el documento es diferente de la dirección permanente en la información básica, le pediremos que explique el motivo.',
    300147: '*Asegúrese de que las imágenes que cargue estén claras y sin reflejos y de que no estén oscurecidas.',
    300148: 'Verificación de vídeo',
    300149: 'Sosteniendo la parte delantera de su identificación, lea el siguiente texto del modelo en voz alta y haga una grabación de vídeo (asegúre una voz clara e impacto)',
    300150: 'Yo (nombre), número de identificación (número de identificación), mi fuente de fondos es legal y confiable, comercio voluntariamente activos digitales como Bitcoin, entiendo completamente las criptomonedas y los riesgos potenciales, tengo la capacidad de resistirme a los riesgos y estoy dispuesto a asumir todos los riesgos. Además, estoy seguro de que todas las operaciones de esta cuenta son operadas por mí, y todas las responsabilidades legales derivadas de esta cuenta serán responsabilidad de mi cargo personalmente.',
    300151: 'Sube el vídeo de verificación',
    300152: '* Soporte de vídeo de hasta 100 M, duración recomendada de menos de 3 minutos, resolución superior a 360P',
    300153: '* .MP4/.WMV/.DGG/.MOV',
    300154: 'Recordatorio',
    300155: 'Su saldo {currency} es inferior a {num}',
    300156: 'Enviar solicitud',
    300157: 'Acepte bloquear <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span> como depósito del anunciante y acepte el <a href="https://support.superex.com/hc/en-001/articles/15269117544345">《Acuerdo del anunciante》</a>',
    // 300158: '《广告发布方协议》',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',

    300161: 'Pago promedio: el tiempo promedio de pago de las compras de criptomonedas en los últimos 30 días',
    300162: '0 Tarifa',
    300163: '1 semana',
    300164: '1 mes',
    300165: '3 meses',
    300166: 'Antes de comenzar a operar, complete la verificación de identidad',
    300167: 'Su pedido se ha cancelado {num} veces hoy y no puede continuar con la transacción. Vuelva a intentarlo después de UTC{Symbol}{string} puntos.。',
    300168: 'Ha alcanzado el número máximo de pedidos pendientes ({num}), complete primero el pedido en curso.',
    300169: 'Este anunciante ha alcanzado el número máximo de pedidos pendientes, por favor, opere más tarde o pruebe con otro anuncio.',
    300170: 'No ha cumplido con los requisitos del anunciante, pruebe con otro anuncio.',
    300171: 'Configuración exitosa',
    300172: 'Comprueba los detalles de la otra parte',
    300173: 'Ponte en contacto con la otra parte',
    300174: 'Solo se admiten imágenes en formato jpg/jpeg/png',
    300175: '¡El tamaño de la imagen cargada no puede superar los 5 MB!',
    300176: 'Cargado',
    300177: 'Error al cargar, inténtalo de nuevo más tarde',
    300178: 'Establece un apodo',
    300179: 'La longitud del apodo es de entre 2 y 10 caracteres',
    300180: 'Se pueden añadir hasta {numb} tipos de métodos de pago',
    300181: 'El formato del número de teléfono móvil es incorrecto',
    300182: 'Formato de correo electrónico incorrecto',
    300183: 'Tiene que tener entre 2 y 30 caracteres',
    300184: 'La longitud de la dirección debe tener entre 5 y 120 caracteres',
    300185: 'Selecciona la relación con la persona',
    300186: 'No se ha subido la foto del ID',
    300187: 'Foto de prueba de dirección no subida',
    300188: 'Verificación de vídeo no cargada',
    300189: 'Enviado con éxito',
    300190: 'Sube el formato de vídeo correcto',
    300191: 'El tamaño del video no puede exceder los 100 MB',
    300192: 'Error en la carga',
    300193: '{num} minutos',
    300194: 'Antes de comenzar a operar, complete la verificación de identidad',
    300195: 'Copia correcta',
    300196: 'Error en la copia',
    300197: 'Hora',
    300198: 'Permiso de publicidad',
    300199: 'Los anunciantes pueden publicar anuncios libremente, haciendo que la compra y venta sean más flexibles y convenientes',
    300200: 'Contacto',
    300201: 'Para la identificación, cargue una identificación con foto de mano',
    300202: 'Eliminado',
    300203: 'Subiendo',
    300204: 'Por favor, confirme que el pago se ha realizado al vendedor mediante el siguiente método de pago',
    300205: 'Número de pedido',
    300206: 'Creado',
    300207: 'Precio total',
    300208: 'Realice el pago al anunciante en un plazo de {expiresTimeMinute} minutos, las órdenes se cancelarán automáticamente después de ese tiempo',
    300209: 'Asegúrese de hacer clic en el botón de abajo para notificar al vendedor del pago después de que se complete la transferencia, de lo contrario, el pedido se cancelará automáticamente después de que caduque el tiempo de pago, ¡lo que resultará en la pérdida de activos!',
    300210: 'He completado el pago. Siguiente paso',
    300211: 'Cancelar orden',
    300212: 'Esperando el pago de los compradores',
    300213: 'Se espera que el pago del comprador se reciba en un plazo de <span style="color: #ffc72b;"> 3minutos </span>',
    300214: 'He confirmado el pago',
    300215: 'Si hay un problema con la transacción, contactar directamente con la otra parte es la forma más efectiva. Puede cargar los recibos de pago y la información de la cuenta en la ventana de chat para que ambas partes negocien y verifiquen.',
    300216: 'Instrucciones para la queja',
    300217: ' Iniciar una queja no puede ayudarle a recuperar sus activos directamente, espere a que el servicio de atención al cliente intervenga y se ocupe de la situación según corresponda. 3. Las quejas maliciosas son actos que perturban el orden normal de operación de la plataforma, y la cuenta se bloqueará en casos graves',
    300218: 'Se tarda de 12 a 48 horas en procesar la queja después de la intervención del servicio al cliente, por favor, preste atención al progreso del procesamiento de la queja.',
    300219: ' Las quejas maliciosas son actos que perturban el orden normal de operación de la plataforma, y la cuenta se bloqueará en casos graves"',
    300220: 'Así es como el vendedor recibe el pago: El pago requiere que salga de la plataforma y utilice su cuenta verificada para transferir dinero a la otra parte.',
    300221: 'Confirmar pago',
    300222: 'Por favor, asegúrese de haber pagado al vendedor a través del siguiente método de pago. Si no has pagado, no hagas clic en Pago.',
    300223: 'Consejo: Entiendo que cuando hago un pago necesito dejar la plataforma para completar la transferencia por mi cuenta, SuperEx no cargará automáticamente los fondos.',
    300224: 'He utilizado una cuenta que coincide con la verificación de nombre real de SuperEx para hacer un pago.',
    300225: 'Cancelar',
    300226: 'Confirmar',
    300227: 'Por favor, pague dentro de &nbsp;<span style="color: #ffc72b;">{timer}</span>',
    300228: 'Método de pago',
    300229: 'Esperando la confirmación de la recepción del vendedor',
    300230: 'Si ha completado el pago, póngase en contacto con la otra parte para confirmar',
    300231: 'Protesta',
    300232: 'Cancelar',
    300233: 'Asegúrese de iniciar sesión en su cuenta para confirmar que la cantidad está disponible en su "Balance disponible.',
    300234: 'Confirmo que he recibido la cantidad correcta',
    300235: 'Confirmar recibo',
    300236: 'Orden de queja en curso',
    300237: 'La otra parte ha pagado, por favor confirme la recepción',
    300238: 'Volver atrás ',
    300239: 'Has vendido con éxito {quantity}{currency}.',
    300240: 'Completo',
    300241: 'Ver activos',
    300242: 'Transacción exitosa',
    300243: '您已成功',
    300244: 'Método de pago',
    300245: 'Método de pago',
    300246: 'Consejos de seguridad',
    300247: "1. No participe en estafas ni en ningún acto ilegal y criminal, como comprar para otros, lavado de dinero, préstamos y arbitraje de mudanzas, citas en línea, etc.2. El uso de cualquier fondo ilegal (como juegos de azar, mercado monetario, fraude, etc.) está prohibido para el comercio de criptomonedas.",
    300248: 'Después de <span style="color:#FFC72B">{appealTime}</span> puede apelar',
    300249: '1. Si ya ha pagado al vendedor, no cancele la transacción',
    300250: '2. Reglas de cancelación: si el comprador ha acumulado {num} cancelaciones el mismo día y no puede hacer otro pedido el mismo día',
    300251: 'El motivo de la queja se mostrará a ambas partes y al servicio al cliente al mismo tiempo, tenga en cuenta la protección de la información de privacidad.',
    300252: 'Por favor, no creas ninguna razón para apresurarse a liberar las monedas y liberar los criptoactivos después de confirmar el recibo del pago para evitar pérdidas.',
    300253: 'Motivo de la queja (requerido)',
    300254: 'Persona de contacto',
    300255: 'Información de contacto',
    300256: 'Quiero quejarme',
    300257: 'Por favor, seleccione el motivo de su queja',
    300258: 'Descripción de la queja',
    300259: 'Describe la queja lo más completa posible',
    300260: 'Introduce tu dirección de correo electrónico',
    300261: 'Lệnh đã bị hủy',
    300262: 'Su pago se ha agotado y su pedido se ha cancelado automáticamente',
    300263: 'No se puede ver el método de pago',
    300264: 'En caso de duda, póngase en contacto con el servicio de atención al cliente.',
    300265: '¿Se ha cancelado la queja?',
    300266: '1. Puede continuar negociando después de retirar su queja, y su pedido no será cancelado.',
    300267: '2. Si te encuentras con otros problemas, puedes apelar de nuevo.',
    300268: 'Temporalmente no es necesario',
    300269: 'Cancelar queja',
    300270: 'No puedes apelar en {appealMinMinute} minutos',
    300271: 'Has comprado con éxito {quantity}{currency}',
    300272: 'Has vendido con éxito {quantity}{currency}.',
    300273: 'La descripción de la apelación no puede estar vacía',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_es;
