const ac_uk = {
    300001: '{timeout} c',
    300002: 'Надіслати код',
    300003: 'Перевірка безпеки',
    300004: 'Заради безпеки вашого облікового запису, виконайте наступні операції перевірки',
    300005: 'Код автентифікації',
    300006: 'Мобільний',
    300007: 'Код підтвердження телефону',
    300008: 'Код підтвердження електронної пошти',
    300009: 'Надіслати',
    300010: '6-значний код буде надіслано на {phone}',
    300011: 'Введіть код перевірки',
    300012: 'Код буде надіслано на адресу {email}',
    300013: 'Підтвердити',
    300014: 'Скасувати',
    300015: 'Введіть 6-значний код перевірки',
    300016: 'Верифікований продавець',
    300017: 'Гарантійний внесок',
    300018: 'Верифікований користувач',
    300019: 'Неперевірені користувачі',
    300020: 'Верифікація пошти',
    300021: 'Верифікація номера телефону',
    300022: 'Верифікація особи',
    300023: 'Стати продавцем',
    300024: 'Списання гарантійного внеску',
    300025: 'Рейтинг успішності за 30 днів',
    300026: 'Замовлення за 30 днів',
    300027: 'Середній час надсилання',
    300028: 'Середній час надсилання: середній час надсилання зарезервованих монет при продажі за останні 30 днів.',
    300029: 'хв.',
    300030: 'Середня сума транзакції',
    300031: 'Акаунт створено',
    300032: 'дні',
    300033: 'Загальна кількість ордерів',
    300034: ' разів',
    300035: 'Купити',
    300036: 'Продати',
    300037: 'Перша транзакція на сьогоднішній день',
    300038: '30-денний торгівельний еквівалент',
    300039: 'Загальний обсяг угод в еквіваленті',
    300040: 'Метод оплати P2P',
    300041: "Метод оплати: доданий вами метод оплати буде відображатися покупцеві як ваш метод оплати при продажу цифрової валюти при транзакції P2P, тому, будь ласка, переконайтеся, що ви використовуєте свій власний рахунок з реальним ім'ям, щоб покупець міг перевести вам гроші без будь-яких проблем. Ви можете додати до {num} методів оплати.",
    300042: 'Додати метод оплати',
    300043: 'Редагувати',
    300044: 'Видалити',
    300045: 'Немає методу оплати',
    300046: 'Видалити цей метод оплати?',
    300047: 'Ви не пройшли верифікацію особи',
    300048: 'Для безпеки вашого облікового запису з іншими користувачами, будь ласка, спочатку пройдіть верифікацію особи',
    300049: 'Не верифіковано',
    300050: 'Пройдіть верифікацію',
    300051: 'Оберіть метод оплати',
    300052: 'Рекомендований метод оплати',
    300053: 'Дані відсутні',
    300054: 'Всі методи оплати',
    300055: 'Пошук',
    300056: 'Придумайте нікнейм',
    300057: "Встановіть нікнейм вашого облікового запису. Рекомендується не використовувати своє справжнє ім'я. Довжина ніку - 2~10 символів.",
    300058: 'Завантажити',
    300059: '*(Підтримує) PG/PEG/PNG/BMP формат, менше 5MB',
    300060: 'Завантажте ще раз',
    300061: 'Зверніть увагу: При продажу цифрової валюти обраний вами метод оплати буде відображений покупцеві, тому, будь ласка, переконайтеся, що інформацію вказано вірно.',
    300062: 'Ви хочете відмовитися від додавання способу оплати?',
    300063: 'Ваші поточні зміни не будуть збережені після виходу з модифікацій',
    300064: 'Заявку подану',
    300065: 'Ексклюзивний логотип',
    300066: 'Ексклюзивний сертифікаційний знак для рекламодавців для підвищення довіри до транзакцій',
    300067: 'Сервіс сам-на-сам',
    300068: 'Ми надаємо індивідуальний сервіс для підвищення ефективності транзакцій',
    300069: 'Регіон/країна',
    300070: 'Фіатна валюта',
    300071: 'Умови використання',
    300072: 'Повна перевірка особи',
    300073: 'P2P акаунти з обсягом більше {num} {currency}',
    300074: 'Перейти до переказу',
    300075: 'Помилка застосування',
    300076: 'Подати заявку зараз',
    300077: 'На розгляді',
    300078: 'Чи повертається депозит?',
    300079: "1. Після повернення депозиту ви не зможете користуватися правами P2P-торговців",
    300080: '2. При знятті депозиту у вас не може бути оголошень або замовлень, якими ведеться торгівля.',
    300081: 'Поки що ні',
    300082: 'Портал торгової програми закрито',
    300083: 'OK',
    300084: 'У процесі',
    300085: 'Всі ордери',
    300086: 'Криптовалюта',
    300087: 'Всі криптовалюти',
    300088: 'Тип замовлення',
    300089: 'Всі',
    300090: 'Купити',
    300091: 'Продати',
    300092: 'Статус',
    300093: 'Всі статуси',
    300094: 'Очікується платіж',
    300095: 'Оплачені',
    300096: 'Завершено',
    300097: 'Скасовано',
    300098: 'Скарга на розгляді',
    300099: 'Дата',
    300100: 'Скинути фільтр',
    300101: '',
    300102: 'Замовлення',
    300103: 'Дані продавця',
    300104: 'Дані користувача',
    300105: 'Купити онлайн',
    300106: 'Купити у продавця',
    300107: 'Продати продавцю',
    300108: 'Ціна',
    300109: 'Метод оплати',
    300110: 'Ліміт / Кількість',
    300111: 'Торгувати',
    300112: 'Сума',
    300113: 'ЛІміт',
    300114: 'У процесі',
    300115: 'OK',
    300116: 'Тип / Валюта',
    300117: 'Загальна сума',
    300118: 'Нікнейм іншої сторони',
    300119: 'Опрацьовується',
    300120: 'Заявка сертифікованого продавця',
    300121: 'Основна інформація',
    300122: 'ПІБ',
    300123: 'Телефон',
    300124: 'Введіть номер телефону',
    300125: 'Код країни',
    300126: 'Електронна пошта',
    300127: 'Введіть адресу електронної пошти',
    300128: 'Контакт для екстрених випадків',
    300129: 'Введіть контакт для екстрених випадків',
    300130: 'Телефон для екстрених випадків',
    300131: 'Введіть телефон для екстрених випадків',
    300132: 'Відносини',
    300133: 'Однокласник',
    300134: 'Родич',
    300135: 'Інше',
    300136: 'Адреса постійного проживання',
    300137: 'Введіть адресу постійного проживання',
    300138: 'Телефон: Используется персоналом для обработки ежедневных апелляций, чтобы быстро проверить информацию о вас, пожалуйста, убедитесь, что этот номер телефона доступен.',
    300139: 'Контакт для экстренной связи и постоянный адрес: Используется для дальнейшей проверки KYC информации рекламодателя для обеспечения более безопасных транзакций.',
    300140: 'Підтвердження особи',
    300141: 'Приклад',
    300142: '*Сфотографуйтеся з лицьовою стороною посвідчення особи в руці, щоб на фото була вся рука і верхня частина тулуба посвідчення особи, щоб ваше обличчя і вся інформація на посвідченні особи були чіткими і безперешкодними',
    300143: '*Підтримуються тільки формати .jpg/.jpeg/.png, максимум 10 Мб',
    300144: 'Будь ласка, завантажте документ на право власності/договір оренди/рахунок за комунальні послуги (оберіть один з трьох)',
    300145: 'Підтвердження адреси',
    300146: 'Якщо адреса в документі відрізняється від постійної адреси в основній інформації, ми попросимо Вас пояснити причину.',
    300147: 'Будь ласка, переконайтеся, що фотографії, які ви завантажуєте, є чіткими, без віддзеркалень і не затемнені.',
    300148: 'Відеоверифікація',
    300149: 'Тримаючи передню частину посвідчення особи, будь ласка, прочитайте вголос наступний типовий текст та зробіть відеозапис ( переконайтеся, що звук чіткий та гучний)',
    300150: 'Я (ПІБ), ідентифікаційний номер (ідентифікаційний номер), моє джерело доходів є законним і достовірним, я добровільно торгую цифровими активами, такими як Bitcoin, я повністю розумію криптовалюту і потенційні ризики, я маю можливість протистояти ризикам і готовий взяти на себе всю відповідальність. Крім того, я впевнений, що всі операції по цьому рахунку здійснюються мною, і всі юридичні зобов язання, що випливають з цього рахунку, буду нести я особисто.',
    300151: 'Завантажте перевірочне відео',
    300152: '* Підтримка відео до 100 Мб, рекомендована тривалість до 3 хвилин, якість понад 360P',
    300153: '* MP4/.WMV/.DGG/.MOV',
    300154: 'Нагадування',
    300155: 'Ваш баланс менше {num} {currency}',
    300156: 'Подати заявку',
    300157: 'Згоден заблокувати <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span> в якості депозиту продавця та погоджуюсь з <a href="https://support.superex.com/hc/en-001/articles/15269117544345">《Угодою продавця》</a>.',
    // 300158: '《广告发布方协议》',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',

    300161: 'Середня сума транзакції: середня сума платежу при купівлі цифрової валюти за останні 30 днів',
    300162: '0 Комісія',
    300163: '1 тиждень',
    300164: '1 місяць',
    300165: '3 місяці',
    300166: 'Будь ласка, пройдіть перевірку особи перед початком транзакції',
    300167: 'Ваше замовлення було скасовано {num} рази сьогодні, і ви не можете продовжити трансакцію. Повторіть спробу після UTC{Symbol}{string} балів.',
    300168: 'Ви досягли максимальної кількості відкладених ордерів ({num}), будь ласка, завершіть спочатку поточний ордер.',
    300169: 'Цей продавець досягнув максимальної кількості відкладених ордерів, будь ласка, спробуйте пізніше або скористайтеся іншим оголошенням.',
    300170: 'Ви не відповідаєте вимогам подавця, будь ласка, спробуйте інше оголошення.',
    300171: 'Налаштовано успішно',
    300172: 'Перевірте деталі іншої сторони',
    300173: 'Зв язатися з іншою стороною',
    300174: 'Підтримуються лише зображення у форматі gif/jpg/png',
    300175: 'Розмір зображення не може перевищувати 5 МБ!',
    300176: 'Завантажено',
    300177: 'Помилка завантаження, спробуйте пізніше',
    300178: 'Встановіть нікнейм',
    300179: 'Довжина нікнейма від 2 до 10 символів',
    300180: 'Можна додати до {num} способів оплати',
    300181: 'Неправильний формат номера телефону',
    300182: 'Невірний формат електронної пошти',
    300183: 'Має бути 2-30 символів',
    300184: 'Довжина адреси має бути від 5 до 120 символів',
    300185: 'Виберіть стосунки з людиною',
    300186: 'Фото посвідчення не завантажено',
    300187: 'Фото для підтвердження адреси не завантажено',
    300188: 'Відео-верифікацію не завантажено',
    300189: 'Успішно надіслано',
    300190: 'Завантажте правильний формат відео',
    300191: 'Розмір відео не може перевищувати 100 Мб',
    300192: 'Помилка завантаження',
    300193: '{num}Хвилина ',
    300194: 'Будь ласка, пройдіть перевірку особи перед початком транзакції',
    300195: 'Копіювання успішно завершено',
    300196: 'Не вдалося скопіювати',
    300197: 'Час',
    300198: 'Дозвіл продавця',
    300199: 'Продавці можуть вільно розміщувати оголошення, що робить купівлю та продаж більш гнучкими та зручними',
    300200: 'Контакт',
    300201: 'Будь ласка, завантажте посвідчення особи з фотографією',
    300202: 'Видалено',
    300203: 'Завантаження',
    300204: 'Будь ласка, підтвердіть, що оплата була здійснена продавцю за допомогою вказаних способів оплати',
    300205: 'Номер ордера',
    300206: 'Час створення',
    300207: 'Загальна вартість',
    300208: 'Будь ласка, оплатіть продавцеві протягом {expiresTimeMinute} хвилин, після закінчення цього часу ордер буде автоматично скасовано',
    300209: 'Будь ласка, обов язково натисніть кнопку нижче, щоб повідомити продавця про оплату після здійснення переказу, інакше замовлення буде автоматично скасовано після закінчення часу оплати, що призведе до втрати коштів!',
    300210: 'Я завершив оплату. Наступний крок',
    300211: 'Скасувати ордер',
    300212: 'Зачекайте оплати від покупця',
    300213: 'Очікується надходження платежу від покупця протягом <span style="color: #ffc72b;"> 3хв. </span>',
    300214: 'Я підтверджую оплату',
    300215: 'Якщо виникла проблема з транзакцією, найефективнішим способом є прямий контакт з іншою стороною. Ви можете завантажити квитанції про оплату та інформацію про рахунок у вікно чату, щоб обидві сторони могли провести переговори та перевірити їх.',
    300216: 'Інструкція щодо подання скарг',
    300217: 'Будь ласка, зачекайте, поки служба підтримки клієнтів втрутиться і вирішить ситуацію належним чином.',
    300218: 'Обробка скарги після втручання служби підтримки займе 12-48 годин, будь ласка, зверніть увагу на хід обробки скарги.',
    300219: 'Зловмисні скарги - це дії, які порушують нормальну роботу платформи і призведуть до заморожування вашого рахунку.',
    300220: "Нижче написані реквізити продавця, для оплати ви повинні вийти з платформи та перевести кошти на вказані реквізити, використовуючи свій реальний рахунок на ваше ім'я.",
    300221: 'Підтвердити оплату',
    300222: 'Будь ласка, переконайтеся, що ви заплатили продавцю за допомогою наведеного нижче методу оплати. Якщо ви не оплатили, будь ласка, не натискайте кнопку "Оплачено".',
    300223: 'Увага: Я розумію, що при здійсненні платежу мені необхідно покинути платформу, щоб завершити переказ самостійно, SuperEx не буде автоматично списувати кошти.',
    300224: 'Для здійснення платежу я використовував обліковий запис, який відповідає перевірці справжнього імені SuperEx.',
    300225: 'Скасувати',
    300226: 'Підтвердити',
    300227: 'Оплатіть протягом &nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp;',
    300228: 'Метод оплати',
    300229: 'Зачекайте підтвердження отримання від продавця',
    300230: "Якщо ви виконали платіж, будь ласка, зв'яжіться з іншою стороною для підтвердження",
    300231: 'Поскаржитися',
    300232: 'Скасувати',
    300233: "Обов'язково увійдіть до свого облікового запису, щоб підтвердити наявність суми в 'Доступному балансі'.",
    300234: 'Я підтверджую, що отримав правильну суму',
    300235: 'Підтвердіть отримання',
    300236: 'Скарга на ордер у процесі розгляду',
    300237: 'Інша сторона оплатила, будь ласка, підтвердіть отримання',
    300238: 'Повернення',
    300239: 'Ви успішно продали {quantity} {currency}.',
    300240: 'Завершено',
    300241: 'Переглянути активи',
    300242: 'Успішна транзакція',
    300243: '您已成功',
    300244: 'Метод оплати',
    300245: 'Метод оплати',
    300246: 'Поради щодо безпеки',
    300247: '1. Не беріть участь у шахрайстві та будь-яких незаконних діях, таких як купівля для інших, відмивання грошей, позики та руховий арбітраж, онлайн-знайомства тощо. 2. Використання будь-яких незаконних коштів (таких як азартні ігри, грошовий ринок, шахрайство тощо) заборонені для торгівлі криптовалютою.',
    300248: '<span style="color:#FFC72B">{appealTime}</span> може оскаржити пізніше',
    300249: '1. Якщо ви вже заплатили продавцю, будь ласка, не скасовуйте угоду',
    300250: '2. Правила скасування: Якщо у покупця накопичилося {num} скасування за один день, то він не може розмістити ще одне замовлення протягом цього ж дня.',
    300251: 'Причина скарги буде показана обом сторонам та службі підтримки одночасно, будь ласка, зверніть увагу захист конфіденційної інформації.',
    300252: 'Будь ласка, не вірте жодним причинам поспішного звільнення монет і звільняйте криптоактиви після підтвердження отримання платежу, щоб уникнути втрат.',
    300253: "Причина скарги (обов'язково)",
    300254: 'Контактна особа',
    300255: 'Контактна інформація',
    300256: 'Я хочу поскаржитися',
    300257: 'Будь ласка, оберіть причину вашої скарги',
    300258: 'Опишіть суть скарги',
    300259: 'Будь ласка, опишіть скаргу якомога повніше',
    300260: 'Введіть адресу вашої електронної пошти',
    300261: 'Замовлення скасовано',
    300262: 'Ваш платіж не було виконано вчасно і замовлення було автоматично скасовано',
    300263: 'Не вдалося переглянути спосіб оплати',
    300264: ' У разі виникнення питань, будь ласка, зверніться до служби підтримки клієнтів.',
    300265: 'Якщо скаргу скасовано?',
    300266: '1. Ви можете продовжити торгівлю після відкликання скарги, і ваш ордер не буде скасовано.',
    300267: '2. Якщо у вас виникнуть інші проблеми, ви можете подати апеляцію повторно.',
    300268: 'Наразі не вимагається',
    300269: 'Скасувати скаргу',
    300270: 'Ви не можете подати апеляцію протягом {appealMinMinute} хвили',
    300271: 'Ви успішно придбали {quantity}{currency}',
    300272: 'Ви успішно продали {quantity}{currency}.',
    300273: 'Зернення не може бути порожнім',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_uk;
