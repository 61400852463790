const ac_vi = {
    300001: 'Gửi lại sau {timeout} giây',
    300002: 'Gửi mã',
    300003: 'Xác minh bảo mật',
    300004: 'Để bảo mật tài khoản của bạn, vui lòng hoàn thành các thao tác xác minh sau',
    300005: 'Mã xác thực',
    300006: 'Di động',
    300007: 'Mã xác minh điện thoại',
    300008: 'Mã xác minh email',
    300009: 'Gửi đi',
    300010: 'Mã gồm 6 chữ số sẽ được gửi đến {phone}',
    300011: 'Vui lòng nhập mã xác minh SuperEx/Google/Microsoft gồm 6 chữ số',
    300012: 'Mã sẽ được gửi đến {email}',
    300013: 'Xác nhận',
    300014: 'Hủy bỏ',
    300015: 'Nhập mã xác minh gồm 6 chữ số',
    300016: 'Nhà quảng cáo được chứng nhận',
    300017: 'Tiền gửi ký quỹ',
    300018: 'Người dùng đã được xác minh',
    300019: 'Người dùng chưa được xác minh',
    300020: 'Email xác thực',
    300021: 'Xác minh điện thoại',
    300022: 'Xác minh danh tính',
    300023: 'Trở thành nhà quảng cáo',
    300024: 'Giải phóng tiền đặt cọc',
    300025: 'Tỷ lệ thành công trong 30 ngày',
    300026: 'Đơn hàng 30 ngày',
    300027: 'Thời gian gửi trung bình',
    300028: 'Thời gian gửi trung bình: thời gian xác nhận trung bình của tiền được phát hành sau khi nhận được khoản thanh toán khi bán tiền trong 30 ngày qua',
    300029: ' phút',
    300030: 'Thanh toán trung bình',
    300031: 'Tài khoản đã được tạo',
    300032: 'Ngày',
    300033: 'Tổng số đơn đặt hàng',
    300034: 'lần',
    300035: 'Mua',
    300036: 'Bán',
    300037: 'Giao dịch đầu tiên cho đến nay',
    300038: 'Chuyển đổi giao dịch trong 30 ngày',
    300039: 'Tổng khối lượng giao dịch quy đổi',
    300040: 'Phương thức thanh toán P2P',
    300041: 'Phương thức thanh toán: Phương thức thanh toán bạn thêm sẽ được hiển thị cho người mua dưới dạng phương thức thanh toán của bạn khi bán tiền điện tử trong giao dịch P2P. Đảm bảo sử dụng tài khoản đã được xác minh của chính bạn để đảm bảo người mua có thể chuyển tiền cho bạn một cách suôn sẻ. Bạn có thể thêm tối đa {num} phương thức thanh toán.',
    300042: 'Thêm phương thức thanh toán',
    300043: 'Chỉnh sửa',
    300044: 'Xóa bỏ',
    300045: 'Không có phương thức thanh toán',
    300046: 'Xóa phương thức thanh toán này?',
    300047: 'Bạn chưa hoàn thành xác minh danh tính',
    300048: 'Để bảo mật tài khoản của bạn với những người dùng giao dịch khác, trước tiên vui lòng hoàn tất xác minh danh tính',
    300049: 'Chưa được xác minh',
    300050: 'Được xác minh',
    300051: 'Chọn phương thức thanh toán',
    300052: 'Phương thức thanh toán được đề xuất',
    300053: 'Không có dữ liệu',
    300054: 'Tất cả các phương thức thanh toán',
    300055: 'Tìm kiếm',
    300056: 'Đặt biệt hiệu',
    300057: 'Đặt tên tài khoản của bạn. Khuyến cáo không nên sử dụng tên thật. Độ dài biệt danh là 2~10 ký tự.',
    300058: 'Tải lên',
    300059: '*(Hỗ trợ) Định dạng PG/PEG/PNG/BMP, nhỏ hơn 5MB',
    300060: 'Tải lên lại',
    300061: 'Nhắc nhở: Khi bạn bán tiền điện tử, phương thức thanh toán bạn chọn sẽ được hiển thị cho người mua, vui lòng xác nhận rằng thông tin được điền chính xác.',
    300062: 'Bạn có muốn từ chối thêm phương thức thanh toán không?',
    300063: 'Các chỉnh sửa hiện tại của bạn sẽ không được lưu sau khi bạn thoát khỏi các sửa đổi',
    300064: 'Hồ sơ đã nộp',
    300065: 'Logo độc quyền',
    300066: 'Dấu chứng nhận độc quyền cho nhà quảng cáo để nâng cao niềm tin giao dịch',
    300067: 'Dịch vụ một kèm một',
    300068: 'Chúng tôi cung cấp dịch vụ trực tiếp cho quảng cáo để nâng cao hiệu quả giao dịch',
    300069: 'Vùng/Quốc gia',
    300070: 'Tiền pháp định có thể phát hành',
    300071: 'Điều kiện ứng dụng',
    300072: 'Hoàn thành xác minh danh tính',
    300073: 'Số {currency} trong tài khoản P2P lớn hơn {num}',
    300074: 'Đi tới chuyển khoản',
    300075: 'Ứng dụng không thành công',
    300076: 'Áp dụng ngay bây giờ',
    300077: 'Đang xem xét',
    300078: 'Tiền đặt cọc có được hoàn trả không?',
    300079: "1. Sau khi hoàn trả khoản tiền gửi, bạn sẽ không được hưởng các quyền và lợi ích của thương nhân P2P",
    300080: '2. Khi giải phóng tiền đặt cọc, bạn không thể có quảng cáo hoặc đơn đặt hàng được giao dịch.',
    300081: 'Không phải bây giờ',
    300082: 'Cổng đăng kí làm thương nhân đã đóng',
    300083: 'Đồng ý',
    300084: 'Trong tiến trình',
    300085: 'Tất cả các đơn đặt hàng',
    300086: 'Tiền điện tử',
    300087: 'Tất cả tiền điện tử',
    300088: 'Kiểu đặt lệnh',
    300089: 'Tất cả ',
    300090: 'Mua',
    300091: 'Bán',
    300092: 'Trạng thái',
    300093: 'Tất cả các trạng thái',
    300094: 'Đang chờ thanh toán',
    300095: 'Đã trả',
    300096: 'Hoàn thành',
    300097: 'Đã hủy',
    300098: 'Đang khiếu nại',
    300099: 'Ngày',
    300100: 'Đặt lại bộ lọc',
    300101: '',
    300102: 'Đặt hàng',
    300103: 'Chi tiết về nhà quảng cáo',
    300104: 'Chi tiết người dùng',
    300105: 'Mua hàng trực tuyến',
    300106: 'Bán cho thương nhân',
    300107: 'Bán cho Ta',
    300108: 'Giá bán',
    300109: 'Phương thức thanh toán',
    300110: 'Giới hạn / Số lượng',
    300111: 'Giao dịch',
    300112: 'Số lượng',
    300113: 'Giới hạn',
    300114: 'Quá trình',
    300115: 'Đồng ý',
    300116: 'Loại / Tiền tệ',
    300117: 'Tổng cộng',
    300118: 'Biệt danh của bên kia',
    300119: 'Vận hành',
    300120: 'Ứng dụng dành cho nhà quảng cáo được chứng nhận',
    300121: 'Thông tin cơ bản',
    300122: 'Họ và tên',
    300123: 'Điện thoại',
    300124: 'Nhập số điện thoại',
    300125: 'Mã vùng',
    300126: 'E-mail',
    300127: 'Nhập địa chỉ email',
    300128: 'Liên hệ khẩn cấp',
    300129: 'Nhập người liên hệ khẩn cấp',
    300130: 'Số liên lạc khẩn cấp',
    300131: 'Nhập số liên lạc khẩn cấp',
    300132: 'Mối quan hệ',
    300133: 'Bạn cùng lớp',
    300134: 'Gia đình',
    300135: 'Khác',
    300136: 'Địa chỉ thường trú',
    300137: 'Nhập địa chỉ thường trú',
    300138: 'Điện thoại: Dùng cho nhân viên xử lý đơn hàng khiếu nại hàng ngày để xác thực nhanh thông tin với quý khách, quý khách vui lòng lưu số điện thoại này.',
    300139: 'Địa chỉ liên hệ khẩn cấp và địa chỉ thường trú: Được sử dụng để xác minh thêm thông tin KYC của nhà quảng cáo nhằm đảm bảo giao dịch an toàn hơn.',
    300140: 'Nhận dạng',
    300141: 'Ví dụ',
    300142: '*Khi chụp ảnh, giơ mặt trước của CMND và chụp cả cánh tay và thân trên của CMND vào ảnh, khuôn mặt và mọi thông tin trên CMND rõ ràng, không bị che.',
    300143: '*Chỉ hỗ trợ định dạng .jpg/.jpeg/.png với kích thước tối đa 10MB',
    300144: 'Vui lòng tải lên giấy chứng nhận bất động sản/hợp đồng thuê nhà/hóa đơn tiện ích (chọn một trong ba)',
    300145: 'Chứng minh địa chỉ',
    300146: 'Nếu địa chỉ trong tài liệu khác với địa chỉ thường trú trong thông tin cơ bản, chúng tôi sẽ yêu cầu bạn giải thích lý do.',
    300147: 'Vui lòng đảm bảo rằng hình ảnh bạn tải lên rõ ràng, không bị phản chiếu và không bị che khuất.',
    300148: 'Xác minh video',
    300149: 'Cầm mặt trước giấy tờ tùy thân của bạn, vui lòng đọc to văn bản mẫu sau và quay video (đảm bảo giọng nói rõ ràng và có tác động)',
    300150: 'Tôi (tên), số ID (số ID), nguồn tiền của tôi là hợp pháp và đáng tin cậy, tôi tự nguyện giao dịch các tài sản kỹ thuật số như Bitcoin, tôi hoàn toàn hiểu về tiền điện tử và các rủi ro tiềm ẩn, tôi có khả năng chống lại rủi ro và sẵn sàng chấp nhận mọi rủi ro. Ngoài ra, tôi chắc chắn rằng tất cả các hoạt động của tài khoản này đều do tôi điều hành và mọi trách nhiệm pháp lý phát sinh từ tài khoản này sẽ do cá nhân tôi chịu trách nhiệm.',
    300151: 'Tải lên video xác minh',
    300152: '* Hỗ trợ video lên tới 100M, độ dài khuyến nghị dưới 3 phút, độ phân giải trên 360P',
    300153: '* .MP4/.WMV/.DGG/.MOV',
    300154: 'Lời nhắc nhở',
    300155: 'Đồng ý khóa {num} {currency} làm khoản tiền gửi của nhà quảng cáo và đồng ý với [Thỏa thuận dành cho nhà quảng cáo]',
    300156: 'Gửi đơn đăng ký',
    300157: 'Đồng ý khóa <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span> làm khoản tiền gửi của nhà quảng cáo và đồng ý với <a href="https://support.superex.com/hc/en-001/articles/15269117544345">《Thỏa thuận dành cho nhà quảng cáo》',

    // 300158: '《广告发布方协议》',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',

    300161: 'Thanh toán trung bình: thời gian thanh toán trung bình cho giao dịch mua tiền điện tử trong 30 ngày qua',
    300162: '0 phí',
    300163: '1 tuần',
    300164: '1 tháng',
    300165: '3 tháng',
    300166: 'Trước khi bạn bắt đầu giao dịch, vui lòng hoàn thành xác minh danh tính',
    300167: 'Đơn đặt hàng của bạn đã bị hủy {num} lần hôm nay và bạn không thể tiếp tục giao dịch. Vui lòng thử lại sau UTC{Symbol}{string} điểm.',
    300168: 'Bạn đã đạt đến số lượng đơn hàng đang chờ xử lý tối đa ({num}), vui lòng hoàn thành đơn hàng đang xử lý trước.',
    300169: 'Nhà quảng cáo này đã đạt đến số lượng đơn đặt hàng đang chờ xử lý tối đa, vui lòng hoạt động sau hoặc thử một quảng cáo khác.',
    300170: 'Bạn chưa đáp ứng yêu cầu của nhà quảng cáo, vui lòng thử quảng cáo khác.',
    300171: 'Thiết lập thành công',
    300172: 'Kiểm tra thông tin chi tiết của bên kia',
    300173: 'Liên lạc với bên kia',
    300174: 'Chỉ hỗ trợ hình ảnh định dạng gif/jpg/png',
    300175: 'Kích thước hình ảnh tải lên không được vượt quá 5MB!',
    300176: 'Đã tải lên',
    300177: 'Tải lên không thành công, hãy thử lại sau',
    300178: 'Đặt biệt danh',
    300179: 'Độ dài biệt danh nằm trong khoảng từ 2 đến 10 ký tự',
    300180: 'Có thể thêm tối đa {num} loại phương thức thanh toán',
    300181: 'Định dạng số điện thoại không chính xác',
    300182: 'Định dạng email không chính xác',
    300183: 'Cần phải có 2-30 ký tự',
    300184: 'Độ dài địa chỉ cần nằm trong khoảng từ 5 đến 120 ký tự',
    300185: 'Chọn mối quan hệ',
    300186: 'Ảnh ID không được tải lên',
    300187: 'Ảnh chứng minh địa chỉ không được tải lên',
    300188: 'Xác minh video không được tải lên',
    300189: 'Gửi thành công',
    300190: 'Tải lên đúng định dạng video',
    300191: 'Kích thước video không được vượt quá 100 MB',
    300192: 'Tải lên không thành công',
    300193: '{num}Phút',
    300194: 'Trước khi bạn bắt đầu giao dịch, vui lòng hoàn thành xác minh danh tính',
    300195: 'Sao chép thành công',
    300196: 'Sao chép không thành công',
    300197: 'Thời gian',
    300198: 'Giấy phép quảng cáo',
    300199: 'Nhà quảng cáo có thể tự do đăng tin rao vặt, giúp việc mua bán trở nên linh hoạt và thuận tiện hơn',
    300200: 'Tiếp xúc',
    300201: 'Để nhận dạng, vui lòng tải lên ID ảnh cầm tay',
    300202: 'Đã xóa',
    300203: 'Đang tải lên',
    300204: 'Vui lòng xác nhận rằng thanh toán đã được thực hiện cho người bán bằng phương thức thanh toán sau',
    300205: 'Số thứ tự',
    300206: 'Tạo',
    300207: 'Tổng giá',
    300208: 'Vui lòng thanh toán cho nhà quảng cáo trong vòng {expiresTimeMinute}, đơn hàng quá giờ sẽ tự động bị hủy',
    300209: 'Hãy nhớ bấm vào nút bên dưới để thông báo thanh toán cho người bán sau khi chuyển khoản xong, nếu không đơn hàng sẽ tự động bị hủy sau khi hết thời gian thanh toán dẫn đến mất mát tài sản!',
    300210: 'Tôi đã thanh toán xong. Bước tiếp theo',
    300211: 'Hủy đơn đặt hàng',
    300212: 'Chờ thanh toán từ người mua',
    300213: 'Thanh toán của người mua dự kiến ​​sẽ được nhận trong vòng <span style="color: #ffc72b;"> 3phút </span>',
    300214: 'Tôi đã xác nhận thanh toán',
    300215: 'Nếu có vấn đề với giao dịch, liên hệ trực tiếp với bên kia là cách hiệu quả nhất. Bạn có thể tải lên biên lai thanh toán và thông tin tài khoản trong cửa sổ trò chuyện để hai bên thương lượng và xác minh.',
    300216: 'Hướng dẫn khiếu nại',
    300217: 'Việc khởi xướng khiếu nại không thể giúp bạn trực tiếp lấy lại tài sản của mình, vui lòng chờ dịch vụ khách hàng can thiệp và xử lý tình huống phù hợp.',
    300218: 'Mất 12-48 giờ để xử lý khiếu nại sau khi can thiệp dịch vụ khách hàng, vui lòng chú ý đến tiến độ xử lý khiếu nại.',
    300219: ' Khiếu nại ác ý là hành vi làm xáo trộn trật tự hoạt động bình thường của nền tảng và tài khoản sẽ bị khóa trong trường hợp nghiêm trọng.',
    300220: 'Đây là cách người bán nhận thanh toán: Thanh toán yêu cầu bạn rời khỏi nền tảng và sử dụng tài khoản đã xác minh của mình để chuyển tiền cho bên kia.',
    300221: 'Xác nhận thanh toán',
    300222: 'Vui lòng đảm bảo rằng bạn đã thanh toán cho người bán thông qua phương thức thanh toán bên dưới. Nếu bạn chưa thanh toán, vui lòng không nhấp vào Đã thanh toán.',
    300223: 'Mẹo: Tôi hiểu rằng khi thực hiện thanh toán, tôi cần rời khỏi nền tảng để tự mình hoàn tất giao dịch chuyển tiền, SuperEx sẽ không tự động ghi nợ số tiền này.',
    300224: 'Tôi đã sử dụng một tài khoản phù hợp với Xác minh tên thật của SuperEx để thực hiện thanh toán.',
    300225: 'Hủy bỏ',
    300226: 'Xác nhận',
    300227: 'Vui lòng thanh toán trong vòng &nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp;',
    300228: 'Phương thức thanh toán',
    300229: 'Chờ xác nhận đã nhận hàng từ người bán',
    300230: 'Nếu bạn đã thanh toán xong, vui lòng liên hệ với bên kia để xác nhận',
    300231: 'Khiếu nại',
    300232: 'Hủy bỏ',
    300233: 'Đảm bảo đăng nhập vào tài khoản của bạn để xác nhận rằng số tiền có sẵn trong "Số dư khả dụng" của bạn.',
    300234: 'Tôi xác nhận rằng tôi đã nhận được đúng số tiền',
    300235: 'Xác nhận',
    300236: 'Đang khiếu nại đơn hàng',
    300237: 'Bên kia đã thanh toán, vui lòng xác nhận đã nhận',
    300238: 'Trở về',
    300239: 'Bạn đã bán thành công {quantity} {currency}.',
    300240: 'Hoàn thành',
    300241: 'Xem tài sản',
    300242: 'Giao dịch thành công',
    300243: '您已成功',
    300244: 'Phương thức thanh toán',
    300245: 'Phương thức thanh toán',
    300246: 'Mẹo bảo mật',
    300247: '1. Không tham gia vào các trò gian lận và bất kỳ hành vi phạm tội và bất hợp pháp nào như mua giúp người khác, rửa tiền, cho vay và chênh lệch giá di chuyển, hẹn hò trực tuyến, v.v. 2. Nghiêm cấm sử dụng bất kỳ khoản tiền bất hợp pháp nào (chẳng hạn như cờ bạc, thị trường tiền tệ, lừa đảo, v.v.) để giao dịch tiền điện tử.',
    300248: '<span style="color:#FFC72B">{appealTime}</span> có thể khiếu nại sau',
    300249: '1. Nếu bạn đã thanh toán cho người bán, vui lòng không hủy giao dịch',
    300250: '2. Quy tắc hủy: Nếu người mua đã tích lũy {num} lần hủy trong cùng một ngày và họ không thể đặt đơn hàng khác trong cùng ngày.',
    300251: 'Lý do khiếu nại sẽ được hiển thị cho cả hai bên và dịch vụ khách hàng cùng một lúc, vui lòng lưu ý bảo vệ thông tin riêng tư.',
    300252: 'Vui lòng không tin vào bất kỳ lý do nào khiến việc phát hành tiền xu một cách vội vàng và chỉ phát hành tài sản tiền điện tử sau khi xác nhận thanh toán để tránh mất tiền.',
    300253: 'Lý do khiếu nại (bắt buộc)',
    300254: 'Người liên hệ',
    300255: 'Thông tin liên lạc',
    300256: 'Tôi muốn khiếu nại',
    300257: 'Vui lòng chọn lý do khiếu nại của bạn',
    300258: 'Mô tả khiếu nại',
    300259: 'Mô tả khiếu nại càng đầy đủ càng tốt',
    300260: 'Nhập địa chỉ email của bạn',
    300261: 'Lệnh đã bị hủy',
    300262: 'Thanh toán của bạn đã hết hạn và đơn đặt hàng của bạn đã tự động bị hủy',
    300263: 'Không thể xem phương thức thanh toán',
    300264: 'Nếu thắc mắc, xin vui lòng liên hệ với dịch vụ khách hàng.',
    300265: 'Khiếu nại có bị hủy không?',
    300266: '1. Bạn có thể tiếp tục giao dịch sau khi rút lại khiếu nại và lệnh của bạn sẽ không bị hủy.',
    300267: '2. Nếu gặp vấn đề khác, bạn có thể khiếu nại lại.',
    300268: 'Tạm thời không bắt buộc',
    300269: 'Hủy bỏ đơn khiếu nại',
    300270: 'Bạn không thể khiếu nại trong vòng {appealMinMinute} phút',
    300271: 'Bạn đã mua thành công {quantity}{currency}',
    300272: 'Bạn đã bán thành công {quantity}{currency}.',
    300273: 'Mô tả khiếu nại không được để trống',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_vi;
