<template>
  <div class="model" v-if="languageModel">
    <div class="language-model">
      <div class="tab">
        <div>
          <!--语言和地区-->
          <span class="inline-block bold pointer" @click="changeTab('languageTab')" :class="{active:activeTab == 'languageTab',marR:!isDirection,marL:isDirection}">{{$t('400056')}}</span>
          <!--汇率-->
          <span class="inline-block bold pointer" @click="changeTab('rateTab')" :class="{active:activeTab == 'rateTab',marR:!isDirection,marL:isDirection}">{{$t('400057')}}</span>
        </div>
        <i class="el-icon-close pointer" @click="handleClose"></i>
      </div>
      <div class="table-box">
        <!--选择语言和地区-->
        <p class="table-box-title bold">{{ activeTab == 'languageTab' ? $t('400058') : $t('400059') }}</p>
        <div class="table-body" v-if="activeTab == 'languageTab'">
          <div class="item pointer" v-for="(item,index) in languageData" :key="index" @click="changeLanguage(item)">
            <span class="inline-block pointer" :class="{active:language == item.language,bold:language == item.language}">{{ item.name }}</span>
          </div>
        </div>
        <div class="table-body" v-else>
          <div class="item pointer" v-for="(item,index) in rateData" :key="index" @click="changeRate(item)">
            <span class="inline-block pointer uppercase" :class="{active:currency == item.currency,bold:currency == item.currency}">{{ item.currency }}-{{ item.symbol }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {setCookie} from "@/utils/common";
import {getLanguageList,getRateList} from "@/apis/user";

export default {
  name: "Language",
  props: {
    languageModel: {
      type: Boolean,
      default: false
    },
    activeLabel: {
      type: String,
      default: 'languageTab'
    }
  },
  inject: ['reload'],
  data() {
    return {
      activeTab: this.activeLabel,
      tableData: [],
      languageList: [],
      rateList: []
    }
  },
  watch: {
    activeLabel() {
      console.log(this.activeLabel, 'activeLabel')
      this.activeTab = this.activeLabel
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'currency',
      'isDirection'
    ]),
    languageData() {
      var obj = {}
      var arr = this.languageList
      arr.forEach((v, i) => {
        if (v.language == this.language) {
          obj = v
          arr.splice(i, 1)
        }
      })
      arr.unshift(obj)
      return arr
    },
    rateData() {
      var obj = {}
      var arr = this.rateList
      arr.forEach((v, i) => {
        if (v.currency == this.currency) {
          obj = v
          arr.splice(i, 1)
        }
      })
      arr.unshift(obj)
      return arr
    }
  },
  created() {
    this.getLanguageList()
    this.getRateList()
  },
  methods: {
    getLanguageList() {
      this.languageList = []
      getLanguageList().then(res => {
        this.languageList = res.data || []
      })
    },
    getRateList() {
      getRateList().then(res => {
        this.rateList = res.data || []
      })
    },
    changeTab(val) {
      this.activeTab = val
    },
    changeLanguage(item) {
      window.location.reload()
      setCookie('language', item.language)
      this.$store.dispatch('setLanguageName', item.name)
      document.documentElement.setAttribute('lang', item.language)
      this.$emit('closeFun')
      setTimeout(() => {
        this.$i18n.locale = item.language
      }, 500)
    },
    changeRate(item) {
      this.reload()
      this.$store.dispatch('setCurrency', item.currency)
      this.$store.dispatch('setCurrencyIcon', item.symbol)
    },
    handleClose() {
      this.$emit('closeFun')
    }
  }
}
</script>
<style lang="scss">
[dir = 'ltr'] .language-model{
  .table-box{
    .table-body{
      .item{
        margin-right: 29px;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
[dir = 'rtl'] .language-model{
  .table-box{
    .table-body{
      .item{
        margin-left: 29px;
        &:nth-child(4n) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import '../../assets/css/variable.scss';
@import "../../assets/css/mixin";

.language-model {
  width: 840px;
  height: auto;
  min-height: 620px;
  border-radius: 4px;
  padding: 32px;
  @include background_color('dialog-bg');

  i.el-icon-close {
    @include font_color('font-color');
  }

  .tab {
    width: 100%;
    height: auto;
    border-bottom: 1px solid;
    @include border_color('border-color-normal');
    display: flex;
    justify-content: space-between;

    span {
      line-height: 24px;
      padding-bottom: 20px;
      font-size: $font-size-normal;
      @include font_color('font-color-1');
    }

    span.marR {
      margin-right: 32px;
    }

    span.marL {
      margin-left: 32px;
    }

    .active {
      @include font_color('font-color');
      border-bottom: 2px solid;
      @include border_color('border-active-color');
    }
  }

  .table-box {
    height: 510px;
    overflow-y: auto;

    &-title {
      margin: 24px 0;
      font-size: $font-size-normal;
      @include font_color('font-color');
    }

    .table-body {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 160px;
        padding: 15px 12px;
        border-radius: 4px;
        @include font_color('font-color');
        &:hover {
          @include background_color('button-bg-normal-hover');
          span {
            @include font_color('font-color-active');
          }
        }

        span {
          font-size: $font-size-small;
          line-height: 21px;
        }

        span.active {
          @include font_color('font-color-active');
        }
      }
    }
  }

  ::v-deep .el-icon-close {
    font-size: $font-size-normal;
  }
}
</style>
