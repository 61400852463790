import axios from "@/utils/axios";

const c2c = '/c2c'

// 获取用户收款方式
export function userPayment() {
    return axios({
        url: c2c + '/v3/user-payment/requirement',
        method: 'get',
        data: {
            effective: 3,
            payment: 'all'
        }
    })
}

// 下拉相关数据
export function selectApi() {
    return axios({
        url: c2c + '/trade/select/rule',
        method: 'get'
    })
}

// 支付方式详情
export function paymentDetailApi(id) {
    return axios({
        url: c2c + `/payment-method/detail/${id}`,
        method: 'get'
    })
}

// c2c用户资产列表
export function C2CAssets() {
    return axios({
        url: c2c + `/v3/assets/all`,
        method: 'get'
    })
}

// 获取用户信息
export function C2CUserInfo() {
    return axios({
        url: c2c + `/v3/user/info`,
        method: 'get'
    })
}

// 常用选项
export function allListApi() {
    return axios({
        url: c2c + '/v3/public/option/box',
        method: 'get',
        data: {
            type: 'all'
        }
    })
}

// 支付方式配置信息
export function paymentConfigApi(data) {
    return axios({
        url: c2c + '/v3/public/payment/detail',
        method: 'get',
        data
    })
}

// 获取认证详情
export function authDetail() {
    return axios({
        url: c2c + '/v3/auth/detail',
        method: 'get'
    })
}
export function uploadImg(data) {
    return axios({
        url: c2c + '/v3/upload/img',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
}
//kyc信息提交
export function kycSubmit(data) {
    return axios({
        url: c2c + '/v3/auth',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
}

//获取kyc详情信息

export function getKycDetail(data) {
    return axios({
        url: c2c + '/v3/auth/detail',
        method: 'get',
        data,
    })
}
//获取c2c用户信息

export function getC2cUserInfo() {
    return axios({
        url: c2c + '/v3/user/info',
        method: 'get',
    })
}
