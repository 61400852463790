import axios from 'axios';
import qs from 'qs';
import store from '../store'
import Vue from "vue";
import {getToken, removeToken, getCookie, removeCookie} from '@/utils/common'
import Router from '@/router/index'
import urls from "./baseUrl";
import JSON_BIG from 'json-bigint'

// 创建axios实例
const service = axios.create({
    timeout: 50000,
    baseURL: urls.baseUrl,   // api的base_url
    // transformResponse: [function (data) {
    //   return jsonlint.parse(data)
    // }]
    transformResponse: data => {
        try {
            return JSON_BIG.parse(data);
        } catch (err) {
            console.log(err);
            return JSON.parse(data)
        }
    }
});
// request拦截器
service.interceptors.request.use(
    config => {

        if (config.method === 'post' || config.method === 'put') {
            if (config.headers?.['Content-Type'] === 'application/json; charset=UTF-8') {

            } else if (config.headers.post?.['Content-Type'] === 'application/x-www-form-urlencoded') {
                config.data = qs.stringify(config.data)
            }
        }


        if (config.method === 'get') {
            if (config.data) {
                Object.keys(config.data).forEach(function (key) {
                    if (config.data[key] == '') {
                        config.data[key] = undefined
                    }
                });
                config.url += '?' + qs.stringify(config.data)
            }
        }
        config.headers['Accept-Language'] = getCookie('language') ? getCookie('language') : 'en'
        //   config.headers['Accept-Language'] = getCookie('language') && getCookie('language') == 'zh-TC' ? 'zh-TC' : 'en'
        if (getToken()) {
            config.headers['token'] = getToken()
        } // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['client'] = '1'    // 1=web 2=ios 3=android
        // 携带userId
        // if (store.state.user.userInfo && store.state.user.userInfo.id) {
        //   config.headers['userId'] = store.state.user.userInfo.id
        // }
        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)
// respone拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (response.status === 200) {
            if (res.code === 403 || res.code === 413) {
                removeToken()
                store.dispatch('FedLogOut')
                removeCookie('userData')
                removeCookie('token')
                window.location.href = urls.linkUrl + '/login'
            }
        }

        if (response.config.url.indexOf('/login') != -1) {
            return response
        } else if (response.config.url.indexOf('/user/reset') != -1) {
            return response
        } else if (response.config.url.indexOf('/img-code') != -1) {
            return response
        } else if (response.config.url.indexOf('/dynamic-auth') != -1) {
            return response
        } else if (response.config.url.indexOf('/register') != -1) {
            return response
        } else if (response.config.url.indexOf('/validation/online') != -1) {
            return response
        } else if (response.config.url.indexOf('/scan/code/status') != -1) {
            return response
        } else if (response.config.url.indexOf('/blind-box/draw') != -1) {
            return response
        } else if (response.config.url.indexOf('/v3/order/proceed') != -1 && response.config.method == 'post') {
            return response
        }else if(response.config.url.indexOf('/c2c/v3/third-party/order') !=-1){
            return response
        }

        if (res.code && res.code != 200 && res.code != 403 && res.code != 413) {
            // Vue.prototype.$notify.error({
            //     message: res.msg,
            // });
            // console.log(response.config.url.indexOf('/validation/online/'))
            if (response.config.url.indexOf('/validation/online/') == -1 && response.config.url.indexOf('/v3/broker/apply/status') == -1) {
                Vue.prototype.$notify({
                    message: res.msg,
                    iconClass: 'iconfont icon-cuowu1',
                    customClass: 'errorIcon flex-center',
                    showClose: false,
                    duration: 2000,
                    position: store.getters.isDirection ? "top-left" : "top-right"
                })
            }
            return Promise.reject(response.data)
        }
        return response.data
    },
    error => {
        // Vue.prototype.$notify.error(error.message)
        // Vue.prototype.$notify({
        //     message: 'Error',
        //     iconClass: 'iconfont icon-cuowu1',
        //     customClass: 'errorIcon flex-center',
        //     showClose: false,
        //     duration:2000
        // })
        return Promise.reject(error)
    }
)
axios.defaults.withCredentials = true
export default service
