import dayjs from "dayjs";
export default (Vue) => {
  Vue.filter("num", (value) => {
    return String((Number(value) || 0).toFixed(2)).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  });

  Vue.filter("upcase", (value) => {
    if (!value) return "";
    return value.toString().toUpperCase();
  });

  Vue.filter("datetime", (value) => {
    if (!value) return "";
    return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
  });
};
