// 手机号脱敏
export function telephoneShow(number) {
  if (number.length == 0) {
    return ''
  }
  var result = ''
  if (number.length > 7) {
    result = number.replace(/(\d{3})\d*(\d{4})/, "$1****$2")
  } else {
    result = number.slice(0, 3) + "****" + number.slice(3, number.length)
  }
  return result
}

// 地址脱敏
export function addressShow(str) {
  if (str.length == 0) {
    return ''
  }
  var result = ''
  if (str.length > 14) {
    result = str.slice(0, 7) + "..." + str.slice(str.length - 7, str.length)
  } else {
    result = str
  }
  return result
}

// 邮箱脱敏
export function emailShow(email) {
  if (email.length == 0) {
    return ''
  }
  var result = ''
  let str = email.split("@")
  result = str[0].substr(0, 2) + "***@" + str[1];
  return result
}

// 姓名脱敏
export function nameShow(name) {
  if (name.length == 0) {
    return ''
  }
  return name.slice(0, 1) + '**'
}

// 证件号脱敏
export function IDShow(number) {
  if (number.length == 0) {
    return ''
  }
  return number.replace(/^(.{4})(?:\w+)(.{4})$/, "$1****$2")
}

// 时间转换
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// 小数位数保留
export function fixNumber(value, unit) {
  var value = parseFloat(value).toFixed(12);
  var unit = unit || 0;
  var isInt = value.indexOf(".") == -1 ? true : false;
  var intNum = value.split(".")[0];
  var floatNum = !isInt ? value.split(".")[1] : "0";
  var floatArry = floatNum.split("");
  var newFloatNum = ".";
  for (var i = 0; i < unit; i++) {
    if (!floatArry[i]) {
      newFloatNum += "0"
    } else {
      newFloatNum += floatArry[i]
    }
  }
  if (unit > 0) {
    return parseFloat(intNum + newFloatNum).toFixed(unit)
  } else {
    return parseInt(intNum)
  }
}
// 解决小数位数失去精度的问题
export function fixNumberCopy(value, unit) {
  var str = typeof value == 'string' ? value : value + ''
  var isDecimal = str.indexOf('.') != -1
  var str1 = ''
  var str2 = ''
  var result = ''
  if (unit && unit != 0) {
    if (isDecimal) {
      str1 = str.split('.')[0]
      str2 = str.split('.')[1]
      if (str2.length >= unit) {
        str2 = str2.slice(0, unit)
      } else {
        var num = unit - str2.length
        for (var i = 0; i < num; i++) {
          str2 += '0'
        }
      }
      result = str1 + '.' + str2
    } else {
      for (var i = 0; i < unit; i++) {
        str2 += '0'
      }
      result = str + '.' + str2
    }
  } else {
    result = isDecimal ? str.split('.')[0] : str
  }
  return result
}

//
export function ucfirst(str) {
  var str = str.toLowerCase();
  var strarr = str.split(' ');
  var result = '';
  for (var i in strarr) {
    result += strarr[i].substring(0, 1).toUpperCase() + strarr[i].substring(1) + ' ';
  }
  return result;
}

// 科学计数法问题处理
export function toNonExponential(num) {
  var m = Number(num).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return Number(num).toFixed(Math.max(0, (m[1] || '').length - m[2]));
}

// 获取两位有效小数
export function toDecimal(x) {
  if (x < 1 && toNonExponential(x).indexOf('.') > -1) {
    var str = toNonExponential(x).split('.')[1] || ''
    var strArr = str.split('')
    var indexArr = []
    var newStr = ''
    for (var i = 0; i < strArr.length; i++) {
      if (strArr[i] != 0) {
        indexArr.push(i)
      }
    }
    if (indexArr[0] == 9) {
      newStr = '0.' + str.slice(0, indexArr[0] + 1)
    } else if (indexArr[0] >= 10) {
      newStr = '0.00'
    } else {
      newStr = '0.' + str.slice(0, indexArr[0] + 2)
    }
    return newStr
  } else {
    return fixNumber(x, 2)
  }
}

export function NumberCheck(num) {
  var str = num;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    var str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换，保留数字和小数点
  str = str.replace(/[^\d^\.]+/g, '')
  //如果需要保留小数点后两位，则用下面公式
  // str = str.replace(/\.\d\d$/,'')
  return str;
}

// 资金千位符
export function toThousands(num) {
  // num = this.toNonExponential(Number(num))
  var decimal = String(num).split('.')[1] || ''; //小数部分
  var tempArr = [];
  var revNumArr = String(num).split('.')[0].split("").reverse(); //倒序
  for (let i in revNumArr) {
    tempArr.push(revNumArr[i]);
    if ((i + 1) % 3 === 0 && i != revNumArr.length - 1) {
      tempArr.push(',');
    }
  }
  var zs = tempArr.reverse().join(''); //整数部分
  return decimal ? zs + '.' + decimal : zs;
}

// 资金千分符--区分正负值
export function thous(num) {
  var flag = 1;
  if (num < 0) {
    flag = 0;
    num = -num;
  }
  var str = num.toString();
//n为小数部分
  var n = str.slice(str.lastIndexOf('.'))
  if (n.indexOf('.') == -1) {
    n = "";
  }
//str为整数部分
  var str = parseInt(num).toString();

  var list = str.split("").reverse();

  for (var i = 0; i < list.length; i++) {
    if (i % 4 == 3) {
      list.splice(i, 0, ",");

    }
  }
  if (flag == 1) {
    return list.reverse().join("") + n;
  } else {
    return "-" + list.reverse().join("") + n;
  }
}

export function nFormatter(num, unit = 5) {
  const si = [
    {value: 1, symbol: ""},
    {value: 1E3, symbol: "K"},
    {value: 1E6, symbol: "M"},
    {value: 1E9, symbol: "G"},
    {value: 1E12, symbol: "T"},
    {value: 1E15, symbol: "P"},
    {value: 1E18, symbol: "E"}
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  // return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  var newlength = fixNumber((num / si[i].value).toString().replace(rx, "$1"), 0).toString().length
  return fixNumber((num / si[i].value).toString().replace(rx, "$1"), unit - newlength) + si[i].symbol;
}

// websocket 相关
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/*小数相乘*/
export function numMultiply(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length
  } catch (e) {
  }
  try {
    m += s2.split(".")[1].length
  } catch (e) {
  }
  var tradeAmount = Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
  return tradeAmount;
}

export function preHandle(n1, n2) { //#noadd
  if (!((typeof n1 === 'number' || (typeof n1 === 'string' && /^[\d]+(\.[\d]+)?$/g.test(n1))) &&
    (typeof n2 === 'number' || (typeof n2 === 'string' && /^[\d]+(\.[\d]+)?$/g.test(n2)))
  )) {
    return NaN;
  }
  var s1 = n1.toString(),
    s2 = n2.toString(),
    a1 = s1.split("."),
    a2 = s2.split(".");
  s1 = s1.replace(".", "");
  s2 = s2.replace(".", "");
  return {
    s1: s1,
    s2: s2,
    n1: parseInt(s1, 10),
    n2: parseInt(s2, 10),
    d1: a1.length > 1 ? a1[1].length : 0, //小数部分长度
    d2: a2.length > 1 ? a2[1].length : 0 //小数部分长度
  };
}

/*除法运算*/
export function numDivide(n1, n2) {
  var t1 = 0,
    t2 = 0,
    r1, r2;
  try {
    t1 = n1.toString().split(".")[1].length
  } catch (e) {
  }
  try {
    t2 = n2.toString().split(".")[1].length
  } catch (e) {
  }
  r1 = Number(n1.toString().replace(".", ""))
  r2 = Number(n2.toString().replace(".", ""))
  return (r1 / r2) * Math.pow(10, t2 - t1);
  /*var oT = preHandle(n1, n2),
      out = !(oT === oT) ? oT : (oT.n1 / oT.n2 / Math.pow(10, oT.d1 - oT.d2));
  return parseFloat(out);*/
}

//张数量，实时价格，单张合约价格(面值)，交易对  （张转币）
export function toCoin(zhang, newPrice, price, contractCode) {
  // if(!contractCode) return alert('没有传交易区')
  if (contractCode.split("_")[1].toLowerCase() == 'usdt' || contractCode.split("_")[1].toLowerCase() == 'tusdt') {
    return numMultiply(zhang, price)
  } else {
    return numDivide(numMultiply(zhang, price), newPrice)
  }
}

//币数量，单张合约价格(面值)，实时价格，交易对  (币转张)
export function toZhang(coin, price, newPrice, contractCode) {
  // if(!contractCode) return alert('没有传交易区')
  if (contractCode.split("_")[1].toLowerCase() == 'usdt' || contractCode.split("_")[1].toLowerCase() == 'tusdt') {
    return parseInt(numDivide(coin, price))
  } else {
    return parseInt(numMultiply(numDivide(coin, price), newPrice))
  }

}

// 钱包地址脱敏
export function ellipseAddress(address, width = 6) {
  if (!address) {
    return ''
  }else{
    return `${address.slice(0, width)}......${address.slice(-width+2)}`;
  }
}


// 获取语言
export function langFilter (lang) {
  if (lang == 'zh-TW' || lang == 'zh-HK') {
    return 'zh-TC'
  } else if (lang.indexOf('fa') > -1) {
    return 'fa'
  } else {
    return lang.indexOf('-') > -1 ? lang.split('-')[0] : lang
  }
}
let clickTimer = 0
export function clickThrottle(interval = 3000) {
  let now = +new Date(); // 获取当前时间的时间戳
  let timer = clickTimer; // 记录触发事件的事件戳

  if (now - timer < interval) {
    // 如果当前时间 - 触发事件时的事件 < interVal，那么不符合条件，直接return false，
    // 不让当前事件继续执行下去
    return false;
  } else {
    // 反之，记录符合条件触发了事件的时间戳，并 return true，使事件继续往下执行
    clickTimer = now;
    return true;
  }
}

// 小数相加
export function accAdd(arg1, arg2) {
  if (isNaN(arg1)) {
    arg1 = 0;
  }
  if (isNaN(arg2)) {
    arg2 = 0;
  }
  arg1 = Number(arg1);
  arg2 = Number(arg2);
  var r1, r2, m, c;
  try {
    r1 = arg1.toString().split(".")[1].length;
  }
  catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  }
  catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", "")) * cm;
    } else {
      arg1 = Number(arg1.toString().replace(".", "")) * cm;
      arg2 = Number(arg2.toString().replace(".", ""));
    }
  } else {
    arg1 = Number(arg1.toString().replace(".", ""));
    arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
}

// 将秒转化成时分秒
export function secTotime(s) {
  var t = '';
  if(s > -1){
    var hour = Math.floor(s/3600)
    var min = Math.floor(s/60) % 60
    var sec = s % 60
    if(hour < 10) {
      t = '0'+ hour + ":"
    } else {
      t = hour + ":"
    }
    if(min < 10){
      t += "0"
    }
    t += min + ":"
    if(sec < 10){
      t += "0"
    }
    t += sec
  }
  return t
}