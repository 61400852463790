import { Dialog, Message, MessageBox,Loading, Notification, Form, FormItem, Input, Empty, Button, Pagination, Breadcrumb, BreadcrumbItem, Skeleton, SkeletonItem, Table, TableColumn, Tabs, TabPane, Switch, Select, Checkbox, CheckboxGroup, CheckboxButton, Option, Tooltip, DatePicker, Progress, Badge, Carousel, CarouselItem, Slider, Menu, MenuItem, MenuItemGroup, Submenu, Cascader, Dropdown, DropdownMenu, DropdownItem, InputNumber, RadioGroup, RadioButton, Radio, Image, Tag, InfiniteScroll, Backtop, Icon, Popover, Row, Col, Divider, Link, Card, Upload,Collapse,CollapseItem } from 'element-ui'


const element = {
    install: function (Vue) {
        Vue.use(Form);
        Vue.use(Button);
        Vue.use(FormItem);
        Vue.use(Input);
        Vue.use(Empty);
        Vue.use(Pagination);
        Vue.use(Breadcrumb);
        Vue.use(BreadcrumbItem);
        Vue.use(Skeleton);
        Vue.use(SkeletonItem);
        Vue.use(Dialog)
        Vue.use(Table);
        Vue.use(TableColumn);
        Vue.use(Tabs);
        Vue.use(TabPane);
        Vue.use(Switch);
        Vue.use(Select);
        Vue.use(Option);
        Vue.use(Checkbox);
        Vue.use(CheckboxGroup);
        Vue.use(CheckboxButton);
        Vue.use(Tooltip);
        Vue.use(DatePicker);
        Vue.use(Progress);
        Vue.use(Badge);
        Vue.use(Carousel);
        Vue.use(CarouselItem);
        Vue.use(Slider);
        Vue.use(Menu);
        Vue.use(MenuItem);
        Vue.use(MenuItemGroup);
        Vue.use(Submenu);
        Vue.use(Cascader);
        Vue.use(Dropdown);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);
        Vue.use(InputNumber);
        Vue.use(RadioGroup);
        Vue.use(RadioButton);
        Vue.use(Radio);
        Vue.use(Image);
        Vue.use(Tag);
        Vue.use(InfiniteScroll);
        Vue.use(Backtop);
        Vue.use(Icon);
        Vue.use(Popover);
        Vue.use(Row);
        Vue.use(Col);
        Vue.use(Divider);
        Vue.use(Link);
        Vue.use(Card);
        Vue.use(Upload)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
        Vue.use(Loading)
        Vue.prototype.$message = Message;
        Vue.prototype.$confirm = MessageBox.confirm;
        Vue.prototype.$prompt = MessageBox.prompt;
        // Vue.prototype.$alert = MessageBox.alert;
        Vue.prototype.$notify = Notification;

    }
}

export default element
