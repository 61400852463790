const ac_zhHK = {
    300001: '{timeout}秒後重發',
    300002: '發送驗證碼',
    300003: '安全驗證',
    300004: '為了您的賬戶安全,請完成以下驗證操作',
    300005: '身份驗證碼',
    300006: '手機',
    300007: '短信驗證碼',
    300008: '郵箱驗證碼',
    300009: '提交',
    300010: '6位驗證碼將發送至您的手機{phone}',
    300011: '請輸入6位SuperEx/谷歌/微軟驗證碼',
    300012: '6位驗證碼將發送至您的郵箱{email}',
    300013: '確定',
    300014: '取消',
    300015: '請輸入6位驗證碼',
    300016: '認證廣告方',
    300017: '保證金',
    300018: '認證用戶',
    300019: '普通用戶',
    300020: '郵箱認證',
    300021: '手機認證',
    300022: '身份認證',
    300023: '成為廣告方',
    300024: '釋放保證金',
    300025: '30日成單率',
    300026: '30日成單數',
    300027: '平均放行',
    300028: '平均放行：近30日賣幣時收款後放幣的平均確認時間',
    300029: '分鐘',
    300030: '平均付款',
    300031: '賬戶已創建',
    300032: '天',
    300033: '總成單數',
    300034: '次',
    300035: '買',
    300036: '賣',
    300037: '首次交易至今',
    300038: '30日交易折合',
    300039: '總交易量折合',
    300040: 'P2P收款方式',
    300041: '收款方式：您添加的收款方式將在P2P交易出售數字貨幣時向買方展示作為您的收款方式，請務必使用您本人的實名賬戶確保買方可以順利給您轉賬。您最多可添加{num}種收款方式。',
    300042: '添加收款方式',
    300043: '編輯',
    300044: '刪除',
    300045: '暫無收款方式',
    300046: '確定刪除此收款方式嗎？',
    300047: '您尚未完成身份認證',
    300048: '為了您與您交易用戶的賬號安全，請先完成身份認證',
    300049: '暫不認證',
    300050: '去認證',
    300051: '選擇收款方式',
    300052: '推薦收款方式',
    300053: '暫無數據',
    300054: '全部收款方式',
    300055: '搜索',
    300056: '設置暱稱',
    300057: '請設置您的賬號暱稱,建議不要使用真實姓名.暱稱長度2~10個字符.',
    300058: '上傳',
    300059: '*（支持）PG/PEG/PNG/BMP格式，小於5MB',
    300060: '重新上傳',
    300061: '溫馨提示：當您出售數字貨幣時，您選擇的收款方式將向買家展示，請確認信息填寫準確無誤。',
    300062: '是否要退出添加收款方式？',
    300063: '退出修改後，您當前的編輯將不被保存',
    300064: '申請已提交',
    300065: '專屬標識',
    300066: '廣告方專屬認證標識，增強交易信任',
    300067: '一對一服務',
    300068: '我們針對廣告提供一對一服務，提高交易效率',
    300069: '所在地區/國家 ',
    300070: '可發布的法幣',
    300071: '申請條件',
    300072: '完成身份驗證',
    300073: 'P2P賬戶{currency}數量大於{num}',
    300074: '去劃轉',
    300075: '申請失敗',
    300076: '立即申請',
    300077: '審核中',
    300078: '是否釋放保證金？',
    300079: "1.釋放保證金後，將不會享有P2P商戶權益",
    300080: '2.釋放保證金時不能有已上架廣告，也不能有正在交易的訂單',
    300081: '暫時不要',
    300082: '商戶申請入口已關閉',
    300083: '我知道了',
    300084: '進行中',
    300085: '全部訂單',
    300086: '幣種',
    300087: '所有幣種',
    300088: '訂單類型',
    300089: '全部',
    300090: '購買',
    300091: '出售',
    300092: '狀態',
    300093: '所有狀態',
    300094: '待付款',
    300095: '已付款',
    300096: '已完成',
    300097: '已取消',
    300098: '申訴中',
    300099: '日期',
    300100: '重置篩選',
    300101: '',
    300102: '訂單',
    300103: '廣告主詳情',
    300104: '用戶詳情',
    300105: '線上購買',
    300106: '向Ta購買',
    300107: '向Ta出售',
    300108: '價格',
    300109: '支付方式',
    300110: '限額/數量',
    300111: '交易',
    300112: '數量',
    300113: '限額',
    300114: '去處理',
    300115: '好的',
    300116: '類型/幣種',
    300117: '總額',
    300118: '對方暱稱',
    300119: '操作',
    300120: '認證廣告方申請',
    300121: '基本信息',
    300122: '姓名',
    300123: '電話',
    300124: '請輸入本人電話',
    300125: '區號',
    300126: '郵箱',
    300127: '請輸入郵箱地址',
    300128: '緊急聯繫人',
    300129: '請輸入緊急聯繫人',
    300130: '緊急聯繫人電話',
    300131: '請輸入緊急聯繫人電話',
    300132: '與本人關係',
    300133: '同學',
    300134: '家人',
    300135: '其他',
    300136: '常住地址',
    300137: '請輸入您本人常住地址',
    300138: "電話：用於工作人員處理日常申訴訂單與您快速核實信息，請務必保持該電話暢通。",
    300139: '緊急聯繫人與常住地址：用於進一步核實廣告方KYC信息，確保交易更安全。',
    300140: '身份證明',
    300141: '示例',
    300142: '*拍攝時，手持本人身份證正面，將持證的手臂和上半身整個拍進照片，臉部、身份證上所有信息清晰且不能被遮擋',
    300143: '*僅支持 .jpg/.jpeg/.png格式，最大不超過10M',
    300144: '請上傳房產證/租房合同/水電費清單（三選一）',
    300145: '地址證明',
    300146: '*房產證、租房合同、水電費清單是為了確保您的地址信息真實有效；證件中地址如與基本信息中所填常住地址不同，我們會要求您說明原因',
    300147: '*房產證需可見房主姓名、房產地及填發單位蓋章；租房合同可見租房人姓名、租房地址、業主蓋章及雙方身份證號；水電費清單需近日期並可見繳費地址，請確保上傳的圖片無遮擋且清晰可見、無反光。',
    300148: '視頻認證',
    300149: '請手持證件正面，朗讀以下範文並進行視頻錄製（保證聲音和影像清晰）',
    300150: '人（姓名），證件號（證件號碼），我的資金來源合法可靠，自願交易比特幣等數字資產，本人充分了解數字貨幣及潛在風險，本人具有抗風險的能力並願意承擔一切風險。另外，我確定此賬號所有操作均為本人操作，該賬號所產生的一切法律責任，由我個人承擔。',
    300151: '請上傳認證視頻',
    300152: '*視頻最大支持100M，建議時長3分鐘以下，分辨率超過360P',
    300153: '*視頻格式支持 .MP4/.WMV/.DGG/.MOV',
    300154: '溫馨提示',
    300155: '您的{currency}餘額不足{num}',
    300156: '提交申請',
    300157: "同意凍結<span>&nbsp;{num}&nbsp;{currency}&nbsp;</span>作為廣告方保證金，且同意<a href='https://support.superex.com/hc/zh-hk/articles/15269071722265'>《廣告發布方協議》</a>",
    // 300158: '',
    300159: '向{phone}發送語音驗證碼',
    300160: '語音驗證碼',
    300161: '平均付款：近30日購買數字貨幣平均付款的時間',
    300162: '0手續費',
    300163: '最近7天',
    300164: '最近1月',
    300165: '最近3月',
    300166: '開始交易前，請先完成身份認證',
    300167: '您的今日訂單取消次數已達{num}次，無法繼續交易，請在UTC{Symbol}{string}點之後再嘗試。',
    300168: '您的待處理訂單已達上限（{num}個），請先完成正在進行的訂單。',
    300169: '該廣告主待處理訂單已達上限，請稍後操作或嘗試其他廣告。',
    300170: '您未滿足廣告方要求，請嘗試其他廣告。',
    300171: '設定成功',
    300172: '查看對方詳情',
    300173: '聯繫對方',
    300174: '僅支持jpg/jpeg/png格式的圖片',
    300175: '上傳圖片大小不能超過5MB！',
    300176: '上傳成功',
    300177: '上傳失敗，請稍後重試',
    300178: '請設定昵稱',
    300179: '昵稱長度為2-10個字元之間',
    300180: '最多可添加{num}種收款方式',
    300181: '手機號碼格式不正確',
    300182: '郵箱格式不正確',
    300183: '需2-30個字元',
    300184: '地址長度需在5-120個字元之間',
    300185: '請選擇與本人關係',
    300186: '身份證明照片未上傳',
    300187: '地址證明照片未上傳',
    300188: '視頻認證未上傳',
    300189: '提交成功',
    300190: '請上傳正確的視頻格式',
    300191: '視頻大小不能超過100 MB',
    300192: '上傳失敗',
    300193: '{num}分鐘',
    300194: '開始交易前，請先完成身份認證',
    300195: '複製成功',
    300196: '複製失敗',
    300197: '時間',
    300198: '廣告發布權限',
    300199: '廣告方可以自由發布廣告，買賣更靈活、更便捷',
    300200: '聯繫',
    300201: '請上傳手持證件照片',
    300202: '删除成功',
    300203: '上傳中',
    300204: '請確認已通過以下支付方式向賣家付款',
    300205: '訂單號',
    300206: '創建時間',
    300207: '總價',
    300208: '請在{expiresTimeMinute}分鐘內付款給廣告方，超時訂單將自動取消',
    300209: '轉賬完成後請務必點擊下方按鈕，通知賣家收款，否則付款時間到期後訂單會自動取消，造成資產損失！',
    300210: '我已完成付款，下一步',
    300211: '取消訂單',
    300212: '等待買家付款',
    300213: '預計將在<span style="color: #ffc72b;"> 3分钟 </span>內收到買家付款',
    300214: '我已確認收款',
    300215: '如存在交易問題，直接與對方聯繫處理是最有效的方式。您可在聊天窗口上傳付款憑證及賬號信息，以供雙方協商核實。',
    300216: '申訴須知',
    300217: '發起申訴無法幫您直接追回資產，請等待客服介入後視情況處理。',
    300218: '客服介入後需要12-48小時的時間來處理申訴，煩請關注申訴處理進度。',
    300219: '惡意申訴屬於擾亂平台正常運營秩序的行為，情節嚴重將凍結賬戶。',
    300220: '下面是賣家的收款方式，付款時需要您離開平台，使用本人實名賬戶向對方轉賬。',
    300221: '確認付款',
    300222: '請確認已通過以下支付方式向賣家付款。若未付款，請勿點擊已付款。',
    300223: '提示： 我明白付款時需要離開平台自主完成轉賬，SuperEx不會自動扣款。',
    300224: '我已使用與SuperEx實名驗證相匹配的賬戶進行付款。',
    300225: '取消',
    300226: '確定',
    300227: '請於&nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp;內付款',
    300228: '付款方式',
    300229: '等待賣家確認收款',
    300230: '如您已經完成付款，請聯繫對方確認',
    300231: '申訴',
    300232: '撤銷',
    300233: '請務必登錄收款賬號確認“可用餘額”中有該筆款項到賬。',
    300234: '我已確認收款無誤',
    300235: '請確認收款',
    300236: '訂單申訴中',
    300237: '對方已付款，請確認收款',
    300238: '返回',
    300239: '您已成功出售{quantity}{currency}',
    300240: '完成',
    300241: '查看資產',
    300242: '交易成功',
    300243: '您已成功',
    300244: '收款方式',
    300245: '付款方式',
    300246: '安全提示',
    300247: '1、不參與幫人代買、洗錢、高利貸、資金盤項目和搬磚套利、網絡交友等騙局和任何違法犯罪行為；2、禁止將任何不法資金（如賭博、資金盤、詐騙等）用於數字貨幣交易行為。',
    300248: '<span style="color:#FFC72B">{appealTime}</span>後可申訴',
    300249: '1.如果您已經向賣家付款，請千萬不要取消交易',
    300250: '2.取消規則：買家當日累計{num}筆取消，您當天不可再下單。',
    300251: '申訴原因將同時展示給雙方和客服，請注意隱私信息保護。',
    300252: '請不要相信任何催促放幣的理由，確認收款項後再釋放數字資產，避免造成損失',
    300253: '申訴理由（必填）',
    300254: '聯繫人',
    300255: '聯繫方式',
    300256: '我要申訴',
    300257: '請選擇申訴理由',
    300258: '申訴描述',
    300259: '請盡可能完整的描述申訴信息',
    300260: '請輸入郵件地址',
    300261: '訂單已取消。',
    300262: '您付款超時，訂單已自動取消',
    300263: '不可查看支付方式',
    300264: '如有疑問請聯繫客服',
    300265: '是否撤銷申訴？',
    300266: '1.撤銷申訴後可以繼續交易，訂單不會被取消。',
    300267: '2.如遇到其他問題，您可以再次申訴。',
    300268: '暫不需要',
    300269: '撤銷申訴',
    300270: '{appealMinMinute}分鐘內不能申訴',
    300271: '您已成功購買{quantity}{currency}',
    300272: '您已成功出售{quantity}{currency}',
    300273: '申訴描述不可為空',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_zhHK;