const ac_fa = {
    300001: '{timeout}s',
    300002: 'کد ارسال شد',
    300003: 'تایید امنیتی',
    300004: 'برای امنیت حساب خود، لطفاً عملیات تأیید زیر را تکمیل کنید',
    300005: 'رمز شناسایی',
    300006: 'موبایل',
    300007: 'کد تایید تلفن',
    300008: 'کد تایید ایمیل',
    300009: 'ارسال',
    300010: 'کد 6 رقمی به آدرس {phone} ارسال می شود.',
    300011: 'لطفاً کد تأیید 6 رقمی سوپراکس/گوگل/مایکروسافت را وارد کنید',
    300012: 'کد به ایمیل {email} ارسال می شود.',
    300013: 'تایید',
    300014: 'لغو',
    300015: 'کد تایید 6 رقمی را وارد کنید',
    300016: 'تبلیغ کنندگان تایید شده',
    300017: 'سپرده امنیتی',
    300018: 'کاربر تایید شده',
    300019: 'کاربران تایید نشده',
    300020: 'تایید ایمیل',
    300021: 'تایید تلفن',
    300022: 'تایید هویت',
    300023: 'تبلیغ کننده شوید',
    300024: 'سپرده تضمینی را آزاد کنید',
    300025: 'نرخ موفقیت 30 روزه',
    300026: 'سفارشات 30 روزه',
    300027: 'میانگین زمان ارسال',
    300028: 'میانگین زمان ارسال: میانگین زمان تأیید سکه‌هایی که پس از دریافت پرداخت در هنگام فروش سکه‌ها در 30 روز گذشته آزاد می‌شوند.',
    300029: 'دقیقه',
    300030: 'متوسط پرداخت',
    300031: 'حساب کاربری ایجاد شد',
    300032: 'روزها',
    300033: 'تعداد کل سفارشات',
    300034: ' بار',
    300035: 'خرید',
    300036: 'فروش',
    300037: 'اولین معامله تا به امروز',
    300038: 'تبدیل تراکنش 30 روزه',
    300039: 'کل حجم تراکنش تبدیل شده است',
    300040: 'روش پرداخت P2P',
    300041:'روش پرداخت: روش پرداختی که اضافه می کنید هنگام فروش ارز دیجیتال در تراکنش P2P به عنوان روش پرداخت شما به خریدار نشان داده می شود. اطمینان حاصل کنید که از حساب تأیید شده خود استفاده می کنید تا اطمینان حاصل کنید که خریدار می تواند پول را بدون مشکل به شما منتقل کند. می توانید حداکثر {num} روش پرداخت اضافه کنید.',
    300042: 'افزودن روش پرداخت',
    300043: 'ویرایش کنید',
    300044: 'حذف',
    300045: 'بدون روش پرداخت',
    300046: 'این روش پرداخت حذف شود؟',
    300047: 'شما تأیید هویت را تکمیل نکرده اید',
    300048: 'برای امنیت حساب خود با سایر کاربران تجاری، لطفاً ابتدا تأیید هویت را تکمیل کنید',
    300049: 'تایید نشده',
    300050: 'تأیید شود',
    300051: 'روش پرداخت را انتخاب کنید',
    300052: 'روش پرداخت پیشنهادی',
    300053: 'اطلاعاتی وجود ندارد',
    300054: 'تمامی روش های پرداخت',
    300055: 'جستجو کردن',
    300056: 'نام مستعار را تنظیم کنید',
    300057: 'نام مستعار حساب خود را تنظیم کنید. توصیه می شود از نام واقعی خود استفاده نکنید. طول نام مستعار 2 تا 10 کاراکتر است.',
    300058: 'بارگزاری',
    300059: '*(پشتیبانی) فرمت PG/PEG/PNG/BMP، کمتر از 5 مگابایت',
    300060: 'دوباره آپلود کنید',
    300061: 'یادآوری: هنگامی که ارز دیجیتال را می فروشید، روش پرداختی که انتخاب می کنید به خریدار نمایش داده می شود، لطفاً تأیید کنید که اطلاعات به درستی پر شده است.',
    300062: 'آیا می‌خواهید از افزودن روش پرداخت انصراف دهید؟',
    300063: 'پس از خروج از تغییرات، ویرایش‌های فعلی شما ذخیره نمی‌شوند',
    300064: 'درخواست ارسال شده است',
    300065: 'لوگوی انحصاری',
    300066: 'علامت گواهی انحصاری برای تبلیغ کنندگان برای افزایش اعتماد معاملات',
    300067: 'خدمات یک به یک',
    300068: 'ما خدمات یک به یک را برای تبلیغات برای بهبود کارایی تراکنش ارائه می دهیم',
    300069: 'منطقه/کشور',
    300070: 'ارز فیات قابل صدور',
    300071: 'شرایط درخواست',
    300072: 'تایید هویت کامل',
    300073: 'مقدار {currency} در حساب P2P بیشتر از {num} است',
    300074: 'به انتقال بروید',
    300075: 'درخواست ناموفق بود',
    300076: 'اکنون درخواست دهید',
    300077: 'تحت بررسی',
    300078: 'آیا سپرده آزاد می شود؟',
    300079: "1. پس از آزادسازی سپرده، از حقوق و منافع تجار P2P برخوردار نخواهید شد ",
    300080: '2. هنگام آزاد کردن سپرده، شما نمی توانید تبلیغات یا سفارشات معامله شوند.',
    300081: 'الان نه',
    300082: 'پورتال برنامه تجاری بسته است',
    300083: 'باشه',
    300084: 'در حال پیش رفت',
    300085: 'همه سفارشات',
    300086: 'ارز دیجیتال',
    300087: 'تمام ارزهای دیجیتال',
    300088: 'نوع سفارش',
    300089: 'همه',
    300090: 'خرید کنید',
    300091: 'فروش',
    300092: 'وضعیت',
    300093: 'همه وضعیت',
    300094: 'در انتظار پرداخت',
    300095: 'پرداخت شده',
    300096: 'تکمیل شد',
    300097: 'لغو شد',
    300098: 'شکایت در جریان است',
    300099: 'تاریخ',
    300100: 'بازنشانی فیلتر',
    300101: '',
    300102: 'سفارشات',
    300103: 'جزئیات آگهی دهنده',
    300104: 'مشخصات کاربر',
    300105: 'خرید آنلاین',
    300106: 'ز تاجر خرید کنید',
    300107: 'به تاجر بفروش',
    300108: 'قیمت',
    300109: 'روش پرداخت',
    300110: 'محدودیت / مقدار',
    300111: 'تجارت',
    300112: 'میزان',
    300113: 'حد',
    300114: 'روند',
    300115: 'باشه ',
    300116: 'نوع / واحد پول',
    300117: 'مبلغ کل',
    300118: 'نام مستعار طرف مقابل',
    300119: 'عمل کند',
    300120: 'برنامه آگهی دهنده تایید شده',
    300121: 'اطلاعات اولیه',
    300122: 'نام و نام خانوادگی',
    300123: 'تلفن',
    300124: 'شماره تلفن را وارد کنید',
    300125: 'کد منطقه',
    300126: 'ایمیل',
    300127: 'ادرس ایمیل را وارد کن',
    300128: 'تماس اضطراری',
    300129: 'شخص تماس اضطراری را وارد کنید',
    300130: 'شماره تماس اضطراری',
    300131: 'شماره تماس اضطراری را وارد کنید',
    300132: 'ارتباط',
    300133: 'همکلاسی',
    300134: 'خانواده',
    300135: 'دیگر',
    300136: 'آدرس اقامت دائم',
    300137: 'آدرس اقامت دائم را وارد کنید',
    300138: 'تلفن: برای کارمندان برای پردازش سفارش‌های شکایت روزانه به منظور تأیید سریع اطلاعات با شما استفاده می‌شود، لطفاً حتماً این شماره تلفن را در دسترس داشته باشید.',
    300139: 'ماس اضطراری و آدرس دائمی: برای تأیید بیشتر اطلاعات KYC تبلیغ‌کننده برای اطمینان از تراکنش‌های امن‌تر استفاده می‌شود.',
    300140: 'شناسایی',
    300141: 'مثال',
    300142: '*هنگام عکس گرفتن، جلوی شناسنامه خود را بگیرید و تمام بازو و بالاتنه شناسنامه خود را در حالی که صورت و تمام اطلاعات روی شناسنامه واضح و بدون مانع است، داخل عکس بگیرید',
    300143: '*فقط فرمت jpg/.jpeg/.png با حداکثر اندازه 10 مگابایت پشتیبانی می شود',
    300144: 'لطفا گواهی املاک/قرارداد اجاره/قبض آب و برق را بارگذاری کنید (یکی از این سه مورد را انتخاب کنید)',
    300145: 'اثبات آدرس',
    300146: '*اگر آدرس مندرج در سند با آدرس دائمی در اطلاعات اولیه متفاوت است، از شما می خواهیم که دلیل آن را توضیح دهید.',
    300147: '*لطفاً مطمئن شوید که تصاویری که آپلود می کنید واضح و بدون انعکاس باشند و مبهم نباشند. ',
    300148: 'تأیید ویدیویی',
    300149: 'با نگه داشتن جلوی شناسه خود، لطفاً متن مدل زیر را با صدای بلند بخوانید و یک فیلم ضبط کنید (از صدای واضح و تاثیرگذاری اطمینان حاصل کنید)',
    300150: 'من (نام)، شماره شناسه (شماره شناسه)، منبع وجوه من قانونی و قابل اعتماد است، من داوطلبانه دارایی های دیجیتال مانند بیت کوین را معامله می کنم، ارز دیجیتال و خطرات احتمالی را کاملاً درک می کنم، توانایی مقاومت در برابر خطرات را دارم و مایل به انجام آن هستم. همه خطرات علاوه بر این، مطمئن هستم که کلیه عملیات این حساب توسط اینجانب انجام می شود و کلیه مسئولیت های قانونی ناشی از این حساب شخصاً بر عهده اینجانب خواهد بود.',
    300151: 'ویدیوی تأیید هویت را بارگذاری کنید',
    300152: '* پشتیبانی از ویدئو تا 100M، طول توصیه شده کمتر از 3 دقیقه، وضوح بیش از 360P ',
    300153: '*MP4/.WMV/.DGG/.MOV',
    300154: 'یادآور',
    300155: 'موجودی {currency} شما کمتر از {num} است',
    300156: 'ارسال درخواست ',
    300157:'با قفل کردن<span>&nbsp;{num}&nbsp;{currency}&nbsp;</span>به عنوان سپرده تبلیغ‌کننده موافقت کنید و با <a href="https://support.superex.com/hc/en-001/articles/15269117544345">《توافق‌نامه آگهی‌دهنده》<a/>',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',

    300161: 'میانگین پرداخت: میانگین زمان پرداخت برای خرید ارزهای دیجیتال در 30 روز گذشته',
    300162: '0 کارمزد',
    300163: '1 هفته',
    300164: '1 ماه',
    300165: '3 ماه',
    300166: 'قبل از شروع معامله، لطفاً تأیید هویت را تکمیل کنید',
    300167: 'سفارش شما امروز برای {num} بار لغو شده است و نمی‌توانید تراکنش را ادامه دهید. لطفاً بعد از امتیاز UTC{Symbol}{string} دوباره امتحان کنید.',
    300168: 'شما به حداکثر تعداد سفارشات معلق ({num}) رسیده اید، لطفاً ابتدا سفارش در حال انجام را تکمیل کنید.',
    300169: 'این تبلیغ‌کننده به حداکثر تعداد سفارش‌های معلق رسیده است، لطفاً بعداً فعالیت کنید یا تبلیغ دیگری را امتحان کنید.',
    300170: 'شما شرایط آگهی دهنده را برآورده نکرده اید، لطفاً یک آگهی دیگر را امتحان کنید.',
    300171: 'راه اندازی با موفقیت انجام شد',
    300172: 'جزئیات طرف مقابل را بررسی کنید',
    300173: 'با طرف مقابل تماس بگیرید',
    300174: 'فقط تصاویر با فرمت jpg/jpeg/png پشتیبانی می شوند',
    300175: 'اندازه تصویر آپلود نباید از 5 مگابایت بیشتر باشد!',
    300176: 'بارگزاری شد',
    300177: 'آپلود انجام نشد، بعداً دوباره امتحان کنید',
    300178: 'یک نام مستعار تعیین کنید',
    300179: 'طول نام مستعار بین 2 تا 10 کاراکتر است ',
    300180: 'حداکثر {num} نوع روش پرداخت را می توان اضافه کرد',
    300181: 'قالب شماره تلفن همراه نادرست است',
    300182: 'فرمت ایمیل نادرست است',
    300183: 'باید 2 تا 30 کاراکتر باشد',
    300184: 'طول آدرس باید بین 5 تا 120 کاراکتر باشد',
    300185: 'رابطه با فرد را انتخاب کنید',
    300186: 'عکس شناسنامه آپلود نشد',
    300187: 'عکس اثبات آدرس آپلود نشده است',
    300188: 'ویدیو تایید هویت آپلود نشد',
    300189: 'با موفقیت ثبت شد',
    300190: 'فرمت ویدیوی صحیح را آپلود کنید',
    300191: 'حجم ویدیو نمی تواند از 100 مگابایت بیشتر باشد',
    300192: 'آپلود انجام نشد',
    300193: '{num} دقیقه',
    300194: 'قبل از شروع معامله، لطفاً تأیید هویت را تکمیل کنید',
    300195: 'کپی با موفقیت انجام شد',
    300196: 'کپی نشد',
    300197: 'تاریخ',
    300198: 'مجوز تبلیغات',
    300199: 'تبلیغ‌کنندگان می‌توانند آزادانه آگهی ارسال کنند و خرید و فروش را انعطاف‌پذیرتر و راحت‌تر کنند',
    300200: 'مخاطب',
    300201: 'برای شناسایی، لطفا یک شناسه عکس دستی آپلود کنید',
    300202: 'حذف شده',
    300203: 'در حال آپلود',
    300204: 'لطفاً تأیید کنید که پرداخت از طریق روش پرداخت زیر به فروشنده انجام شده است',
    300205: 'شماره سفارش',
    300206: 'ایجاد شده',
    300207: 'قیمت کل',
    300208: 'در مدت {expiresTimeMinute} دقیقه به تبلیغ کننده پرداخت کنید، سفارشات پس از آن زمان به طور خودکار لغو می شوند ',
    300209: 'لطفاً حتماً دکمه زیر را کلیک کنید تا پس از اتمام نقل و انتقال، مبلغ پرداختی به فروشنده اطلاع داده شود، در غیر این صورت پس از اتمام زمان پرداخت، سفارش به طور خودکار لغو می شود و در نتیجه دارایی ها از بین می رود!',
    300210: 'پرداخت را تکمیل کردم گام بعدی',
    300211: 'سفارش را لغو کنید',
    300212: 'در انتظار پرداخت از سوی خریداران',
    300213: 'انتظار می رود پرداخت خریدار ظرف مدت <span style="color: #ffc72b;">دقیقه 3</span> دریافت شود',
    300214: 'من پرداخت را تایید کرده ام',
    300215: 'در صورت بروز مشکل در معامله، تماس مستقیم با طرف مقابل موثرترین راه است. می‌توانید رسیدهای پرداخت و اطلاعات حساب را در پنجره چت برای هر دو طرف برای مذاکره و تأیید آپلود کنید.',
    300216: 'دستورالعمل شکایت',
    300217: 'شروع یک شکایت نمی تواند به شما کمک کند مستقیماً دارایی های خود را بازیابی کنید، لطفاً منتظر بمانید تا خدمات مشتری مداخله کند و به شرایط مناسب رسیدگی کند.',
    300218: 'رسیدگی به شکایت پس از مداخله خدمات مشتری 12-48 ساعت طول می کشد، لطفا به پیشرفت رسیدگی به شکایت توجه کنید.',
    300219: 'شکایات مخرب اقداماتی هستند که نظم عملکرد عادی پلتفرم را مختل می کنند و در موارد جدی حساب مسدود می شود.',
    300220: 'نحوه دریافت پرداخت توسط فروشنده به این صورت است: برای پرداخت باید پلتفرم را ترک کنید و از حساب تأیید شده خود برای انتقال پول به طرف مقابل استفاده کنید.',
    300221: 'پرداخت را تایید کنید',
    300222: 'لطفاً مطمئن شوید که از طریق روش پرداخت زیر به فروشنده پرداخت کرده اید. اگر پرداخت نکرده اید لطفا روی پرداخت شد کلیک نکنید.',
    300223: 'نکته: می‌دانم که هنگام پرداخت باید پلتفرم را ترک کنم تا به تنهایی انتقال را انجام دهم، سوپراکس به طور خودکار وجوه را کسر نمی‌کند.',
    300224: 'من از حسابی استفاده کرده ام که با تأیید نام واقعی سوپراکس مطابقت دارد تا پرداخت کنم.',
    300225: 'لغو کنید',
    300226: 'تایید',
    300227: 'لطفا ظرف مدت &nbsp;<span style="color: #ffc72b;">{timer}</span>&nbsp; پرداخت کنید ',
    300228: 'روش پرداخت',
    300229: 'در انتظار تایید رسید از فروشنده',
    300230: 'اگر پرداخت را انجام داده اید، لطفاً برای تأیید با طرف مقابل تماس بگیرید',
    300231: 'شکایت',
    300232: 'لغو شد',
    300233: 'حتماً وارد حساب خود شوید تا تأیید کنید که این مبلغ در «موجودی موجود» شما موجود است.',
    300234: 'تأیید می کنم که مبلغ صحیح را دریافت کرده ام',
    300235: 'تایید رسید',
    300236: 'سفارش شکایت در حال انجام است',
    300237: 'طرف مقابل پرداخت کرده است، لطفاً رسید را تأیید کنید',
    300238: 'برگشت',
    300239: 'شما با موفقیت {quantity}{currency} را فروختید.',
    300240: 'کامل',
    300241: 'مشاهده دارایی ها',
    300242: 'معامله با موفقیت انجام شد',
    300243: '您已成功',
    300244: 'روش پرداخت',
    300245: 'روش پرداخت',
    300246: 'نکات امنیتی',
    300247: '"۱- از ​​کلاهبرداری و هرگونه اعمال غیرقانونی و مجرمانه مانند خرید برای دیگران، پولشویی، وام و جابجایی آربیتراژ، دوستیابی آنلاین و غیره خودداری کنید.  2. استفاده از هرگونه وجوه غیرقانونی (مانند قمار، بازار پول، کلاهبرداری و غیره) برای معاملات ارزهای دیجیتال ممنوع است."',
    300248: 'بعد از <span style="color:#FFC72B">{appealTime}</span> می‌توانید درخواست تجدیدنظر کنید',
    300249: '1. اگر قبلاً به فروشنده پرداخت کرده اید، لطفاً معامله را لغو نکنید',
    300250: '2. قوانین لغو: اگر خریدار در همان روز انباشته {num} انصراف داشته باشد و نمی تواند در همان روز سفارش دیگری را ثبت کند.',
    300251: 'دلیل شکایت به طور همزمان به هر دو طرف و خدمات مشتری نشان داده می شود، لطفاً به حفاظت از اطلاعات حریم خصوصی توجه کنید.',
    300252: 'لطفاً هیچ دلیلی برای عجله برای انتشار ارزها باور نکنید و پس از تأیید دریافت پرداخت، دارایی های رمزنگاری شده را آزاد کنید تا از ضرر جلوگیری کنید. ',
    300253: 'دلیل شکایت (الزامی)',
    300254: 'شخص تماس',
    300255: 'اطلاعات تماس',
    300256: 'من می خواهم شکایت کنم',
    300257: 'لطفا دلیل شکایت خود را انتخاب کنید',
    300258: 'شرح شکایت',
    300259: 'شکایت را تا حد امکان کامل شرح دهید',
    300260: 'آدرس ایمیل خود را وارد کنید',
    300261: 'سفارش لغو شد',
    300262: 'زمان پرداخت شما به پایان رسیده است و سفارش شما به طور خودکار لغو شده است',
    300263: 'امکان مشاهده روش پرداخت وجود ندارد',
    300264: 'اگر شک دارید، لطفا با خدمات مشتری تماس بگیرید.',
    300265: 'آیا شکایت منتفی است؟',
    300266: '1. شما می توانید پس از انصراف از شکایت خود به معامله ادامه دهید و سفارش شما لغو نخواهد شد. ',
    300267: '2. اگر با مشکلات دیگری مواجه شدید، می توانید دوباره تجدید نظر کنید.',
    300268: 'به طور موقت مورد نیاز نیست',
    300269: 'لغو شکایت',
    300270: 'شما نمی توانید ظرف {appealMinMinute} دقیقه درخواست تجدید نظر کنید',
    300271: 'شما با موفقیت{quantity}{currency} را خریدید',
    300272: 'شما با موفقیت {quantity}{currency} را فروختید.',
    300273: 'شرح درخواست تجدیدنظر نمی تواند خالی باشد',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_fa;