const ac_ja = {
    300001: '{timeout}秒後に再送します',
    300002: '認証コードを送信',
    300003: 'セキュリティ認証',
    300004: 'アカウント安全のために、次の確認操作を完了してください',
    300005: '認証コード',
    300006: 'モバイル',
    300007: 'SMS認証コード',
    300008: 'メールアドレス認証コード',
    300009: '提出する',
    300010: '6桁の認証コードがお使いの携帯電話{phone}に送信されます',
    300011: '6 桁の SuperEx/Google/Microsoft 確認コードを入力してください',
    300012: '6桁の認証コードがお使いのメール{email}に送信されます',
    300013: 'はい',
    300014: 'キャンセル',
    300015: '6桁の認証コードを入力してください',
    300016: '認定広告主',
    300017: '証拠金',
    300018: '認証済みユーザー',
    300019: '一般利用者',
    300020: 'メール認証',
    300021: 'モバイル認証',
    300022: '認証',
    300023: '広告主になる',
    300024: '絆を解き放つ',
    300025: '30日注文率',
    300026: '30日注文量',
    300027: '平均リリース',
    300028: '平均リリース: 過去 30 日間にコインを販売した場合、入金後にコインをリリースするまでの平均時間',
    300029: '分',
    300030: '平均支払い',
    300031: 'アカウントが作成されました',
    300032: '空',
    300033: '単数での合計',
    300034: '回',
    300035: '購入',
    300036: '売る',
    300037: '今日までの最初の取引',
    300038: '30日換算の出来高',
    300039: '総取引高相当',
    300040: 'P2P決済方法',
    300041: '支払い方法: 追加した支払い方法は、P2P 取引でデジタル通貨を販売する際の支払い方法として購入者に表示されます. 購入者がスムーズに送金できるように、必ず実名のアカウントを使用してください. {num} までの支払い方法を追加できます。',
    300042: 'お支払い方法を追加',
    300043: '編集',
    300044: '消去',
    300045: '支払い方法なし',
    300046: 'この支払い方法を削除してもよろしいですか？',
    300047: '本人確認が完了していません',
    300048: '取引ユーザーとのアカウントのセキュリティのために、最初に身元認証を完了してください',
    300049: 'まだ認定されていません',
    300050: '認証に行く',
    300051: 'お支払い方法の選択',
    300052: 'おすすめのお支払い方法',
    300053: 'データなし',
    300054: 'すべての支払い方法',
    300055: '探す',
    300056: 'ニックネームを設定',
    300057: 'アカウントのニックネームを設定してください. 本名は使用しないことをお勧めします. ニックネームの長さは 2~10 文字です.',
    300058: 'アップロード',
    300059: '*（サポート）PG/PEG/PNG/BPMフォーマット、5 MB未満',
    300060: '再アップロード',
    300061: '注意: デジタル通貨を販売する場合、選択した支払い方法が購入者に表示されます。情報が正しく入力されていることを確認してください。',
    300062: '支払い方法の追加をオプトアウトしますか?',
    300063: '変更を終了すると、現在の編集内容は保存されません',
    300064: '購買依頼が発行されました',
    300065: '専用ロゴ',
    300066: '取引の信頼性を高める広告主独自の認証マーク',
    300067: 'マンツーマンサービス',
    300068: '取引効率を向上させるために、広告に1対1のサービスを提供します',
    300069: '所在地/国',
    300070: '発行可能な法定通貨',
    300071: '応募条件',
    300072: '完全な本人確認',
    300073: 'P2P アカウントの {currency} の金額が {num} を超えている',
    300074: '乗り換え',
    300075: '申請に失敗しました',
    300076: 'すぐに適用',
    300077: '審査中',
    300078: '債券は解放されますか？',
    300079: "1.デポジットを解放すると、P2P加盟店の権利と利益を享受できなくなります",
    300080: '2. 入金が解除されると、棚に広告が掲載されたり、取引中の注文が表示されたりすることはありません。',
    300081: '対処しない',
    300082: 'マーチャント アプリケーション ポータルは閉鎖されています',
    300083: 'そうですか',
    300084: '進行中',
    300085: 'すべての注文',
    300086: '通貨',
    300087: 'すべての通貨',
    300088: '注文の種類',
    300089: '全て',
    300090: '買う',
    300091: '売る',
    300092: '州',
    300093: 'すべての州',
    300094: '支払い待ち',
    300095: '有料',
    300096: '完了',
    300097: 'キャンセル',
    300098: 'アピール',
    300099: '日にち',
    300100: 'フィルタをリセット',
    300101: '',
    300102: '注文',
    300103: '広告主の詳細',
    300104: 'ユーザー詳細',
    300105: 'オンライン購入',
    300106: '商人から購入する',
    300107: '商人に売る',
    300108: '価格',
    300109: '支払方法',
    300110: '指値/利用可能',
    300111: '取引',
    300112: '利用可能',
    300113: '指値',
    300114: '対処する',
    300115: 'はい',
    300116: 'タイプ/通貨',
    300117: '一時金',
    300118: 'ニックネーム',
    300119: '操作します',
    300120: '認定広告主の申請',
    300121: '基本情報',
    300122: '名前',
    300123: '電話',
    300124: '私の電話番号を入力してください',
    300125: '市外局番',
    300126: 'メール',
    300127: 'メールアドレスを入力してください',
    300128: '緊急連絡',
    300129: '緊急連絡先を入力してください',
    300130: '緊急連絡先電話番号',
    300131: '緊急連絡先電話番号を入力してください',
    300132: '私との関係',
    300133: '同級生',
    300134: '家族',
    300135: '他の',
    300136: '住宅アドレス',
    300137: '本籍地を入力してください',
    300138: "電話：スタッフが日々の苦情受付や迅速な情報確認のために使用します。この電話は開いたままにしておいてください。",
    300139: '緊急連絡先と住所: 広告主の KYC 情報をさらに検証して、より安全な取引を確保するために使用されます。',
    300140: '個人ID',
    300141: '例',
    300142: '※写真を撮るときは、IDカードの表側を持ち、腕と上半身の写真を撮ります。顔とIDカードのすべての情報がクリアで、遮ることができません。',
    300143: "※.jpg/.jpeg/.png形式のみ対応、最大10Mまで",
    300144: '不動産証明書・賃貸借契約書・水道・電気料金表をアップロードしてください（3つのうち1つを選択）',
    300145: '住所証明',
    300146: '※物件証明書、賃貸借契約書、公共料金の請求書は、住所情報が真実かつ有効であることを確認するためのものであり、証明書の住所が基本情報の本籍地と異なる場合は、その理由を説明していただきます。',
    300147: '※不動産証明書には所有者の氏名、物件の所在地、発行単位の印鑑が記載されている必要があり、賃貸借契約書には借主の氏名、賃貸人の住所、所有者の印鑑、所有者の印鑑が記載されている必要があります。両当事者の ID 番号; 水道料金と電気料金のリストは最近のもので、支払い先住所が確認できることを確認してください。',
    300148: 'ビデオ認証',
    300149: '証明書の前面を持って、次のサンプル テキストを声に出して読み上げ、ビデオ録画を行ってください (音声と画像が鮮明であることを確認してください)。',
    300150: '私 (名前)、証明書番号 (証明書番号)、私の資金源は合法で信頼できるものであり、ビットコインなどのデジタル資産を自発的に取引します. 私はデジタル通貨と潜在的なリスクを完全に理解しています. 私はリスクに抵抗する能力があり、喜んでいますすべてのリスクを負うこと。 さらに、私は、このアカウントでのすべての操作が私によって実行され、このアカウントから生じるすべての法的責任は私が個人的に負担するものであることを確認します。',
    300151: '認証ビデオをアップロードしてください',
    300152: '* 最大ビデオ サポートは 100M、推奨時間は 3 分未満、解像度は 360P を超えます',
    300153: '*動画フォーマットは.MP4/.WMV/.DGG/.MOVに対応',
    300154: '親切なヒント',
    300155: '{currency} の残高が {num} 未満です',
    300156: '購買依頼の発行',
    300157: '広告主の保証金として <span>&nbsp;{num}&nbsp;{currency}&nbsp;</span>を凍結することに同意し、<a href="https://support.superex.com/hc/en-001/articles/15269117544345">「広告発行者契約」</a> に同意する',
    300159: '向{phone}发送语音验证码',
    300160: '语音验证码',

    300161: '平均支払い: 過去 30 日間のデジタル通貨購入の平均支払い時間',
    300162: '0 手数料',
    300163: '7日間',
    300164: '1ヶ月',
    300165: '3ケ月',
    300166: '取引を開始する前に、本人確認を完了してください',
    300167: 'あなたの注文は本日 {num} 回キャンセルされました。取引を続行することはできません。UTC{Symbol}{string} ポイント後にもう一度お試しください。',
    300168: '保留中の注文が上限 ({num}) に達しました。まず進行中の注文を完了してください。',
    300169: '広告主が保留中のオーダーの上限に達しました。後で操作するか、別の広告を試してください。',
    300170: '広告主の要件を満たしていません。別の広告をお試しください。',
    300171: '設定に成功しました',
    300172: '相手の詳細の表示',
    300173: 'お互いに連絡する',
    300174: 'jpg/jpeg/png形式の画像のみサポート',
    300175: 'アップロード画像のサイズは5 MBを超えてはいけません！',
    300176: 'アップロード成功',
    300177: 'アップロードに失敗しました。後で再試行してください',
    300178: 'ニックネームを設定してください',
    300179: 'ニックネームの長さは2～10文字です',
    300180: '{num} までの支払い方法を追加できます',
    300181: '携帯電話番号の形式が正しくありません',
    300182: 'メールボックスのフォーマットが正しくありません',
    300183: '2～30文字必要',
    300184: 'アドレス長は5～120文字',
    300185: '本人との関係を選択してください',
    300186: '資格証明写真がアップロードされていません',
    300187: '住所証明写真がアップロードされていません',
    300188: 'ビデオ認証がアップロードされていません',
    300189: 'コミット成功',
    300190: '正しいビデオフォーマットをアップロードしてください',
    300191: '動画のサイズは 100 MB を超えることはできません',
    300192: 'アップロード失敗',
    300193: '{num}分',
    300194: '取引を開始する前に、本人確認を完了してください',
    300195: '正常にコピー',
    300196: 'コピー失敗',
    300197: '時間',
    300198: '広告掲載許可',
    300199: '広告主は自由に広告を掲載でき、売買がより柔軟で便利になります',
    300200: '接続',
    300201: 'パスポートの写真をアップロードしてください',
    300202: '削除しました',
    300203: 'アップロード中',
    300204: '以下の支払方法で販売者に支払いが行われたことを確認してください',
    300205: '注文番号',
    300206: '作成時間',
    300207: '合計金額',
    300208: '{expiresTimeMinute} 分 以内に広告主に支払いを行ってください。時間外注文は自動的にキャンセルされます',
    300209: '送金が完了したら、必ず下のボタンをクリックして販売者に支払いを回収するよう通知してください。そうしないと、支払い期限が切れた後に注文が自動的にキャンセルされ、資産が失われます。',
    300210: 'お支払い完了、次のステップ',
    300211: '注文をキャンセルする',
    300212: 'バイヤーの支払いを待っています',
    300213: '購入者の支払いは <span style="color: #ffc72b;"> 3分 </span> 以内に受領される予定です',
    300214: '入金確認しました',
    300215: '取引に問題がある場合は、直接相手方に連絡して対処するのが最も有効な方法です。 両当事者による交渉と検証のために、チャットウィンドウで支払いバウチャーとアカウント情報をアップロードできます。',
    300216: '控訴の通知',
    300217: '苦情を申し立てても、資産を直接回復することはできません。カスタマーサービスが介入し、状況に応じて対処するのを待ってください。',
    300218: 'カスタマーサービスが介入してから苦情を処理するのに12時間から48時間かかります.苦情処理の進行状況に注意してください.',
    300219: '悪意のある苦情は、プラットフォームの正常な動作を妨害する行為であり、状況が深刻な場合、アカウントは凍結されます。',
    300220: '売り手の支払い方法は次のとおりです.支払いの際には、プラットフォームを離れて、実名のアカウントを使用して相手に送金する必要があります.',
    300221: '支払いを確認する',
    300222: '以下の決済方法で、出品者への支払いが完了していることをご確認ください。 支払いをしていない場合は、[支払い済み] をクリックしないでください。',
    300223: '注意: 支払いの際、送金を完了するためにプラットフォームを離れる必要があり、SuperEx が自動的にお金を差し引かないことを理解しています。',
    300224: 'SuperExの実名検証に一致するアカウントを使用して支払いを行いました。',
    300225: 'キャンセル',
    300226: 'もちろん',
    300227: '<span style="color: #ffc72b;">{timer}</span>以内にお支払いください',
    300228: '支払方法',
    300229: '売り手が支払いを確認するのを待っています',
    300230: '支払いが完了したら、相手方に連絡して確認してください',
    300231: '訴える',
    300232: '取り消す',
    300233: '必ず受取口座にログインし、「ご利用可能残高」に入金されていることをご確認ください。',
    300234: 'お支払いが正しいことを確認しました',
    300235: '支払いを確認してください',
    300236: 'オーダーアピール',
    300237: '相手方が支払いました。領収書を確認してください',
    300238: '戻る',
    300239: '{quantity}{currency}の売却に成功しました。',
    300240: '終了',
    300241: 'アセットを表示',
    300242: '成功した取引',
    300243: '您已成功',
    300244: '支払方法',
    300245: '支払方法',
    300246: 'セキュリティのヒント',
    300247: '"1. 他人の購入の手助け、マネー ロンダリング、高利貸し、資金調達プロジェクト、アービトラージ、オンライン デート、違法行為や犯罪行為などの詐欺行為に参加しないでください。2. デジタル通貨取引に違法な資金（ギャンブル、資金取引、詐欺など）を使用することは禁止されています。"',
    300248: '<span style="color:#FFC72B">{appealTime}</span>の後に異議を申し立てることができます',
    300249: '1. 出品者への支払いが完了している場合は、取引をキャンセルしないでください。',
    300250: '2.キャンセル規定：購入者は同日に{num}件のキャンセルを累積しており、同日に別の注文を行うことはできません。',
    300251: '異議申し立ての理由は、双方とカスタマーサービスに同時に表示されますので、プライバシー情報の保護にご注意ください。',
    300252: 'いかなる理由があってもコインのリリースを促すことは信じず、損失を避けるために支払いを確認した後にデジタル資産をリリースしてください。',
    300253: '異議申し立ての理由 (必須)',
    300254: '連絡窓口',
    300255: '連絡先',
    300256: 'アピールしたい',
    300257: '異議申し立ての理由を選択してください',
    300258: '苦情の説明',
    300259: '苦情の内容をできるだけ詳しく説明してください',
    300260: 'メールアドレスを入力してください',
    300261: '注文はキャンセルされました。',
    300262: 'お支払いがタイムアウトになり、注文が自動的にキャンセルされました',
    300263: 'お支払い方法が表示されない',
    300264: 'ご不明な点がございましたら、カスタマーサービスまでお問い合わせください。',
    300265: '控訴は取り下げられますか？',
    300266: '1. 異議申し立てを取り下げた後、取引は続行でき、注文はキャンセルされません。',
    300267: '2. その他の問題が発生した場合は、再度申し立てることができます。',
    300268: '必要ありません',
    300269: '控訴を取り下げる',
    300270: '{appealMinMinute}数分以内に異議申し立て不可',
    300271: '{quantity}{currency}を購入しました',
    300272: '{quantity}{currency}の売却に成功しました。',
    300273: '苦情の説明を空にすることはできません',
    300274: '',
    300275: '',
    300276: '',
    300277: '',
    300278: '',
    300279: '',
    300280: '',
    300281: '',
    300282: '',
    300283: '',
    300284: '',
    300285: '',
    300286: '',
    300287: '',
    300288: '',
    300289: '',
    300290: '',
    300291: '',
    300292: '',
    300293: '',
    300294: '',
    300295: '',
    300296: '',
    300297: '',
    300298: '',
    300299: '',
    300300: '',
}//300001
export default ac_ja;