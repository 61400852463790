import axios from "../utils/axios";
const otc = '/c2c'

// 获取用户收款方式
export function getEnumList(data) {
    return axios({
        url: otc + '/v3/public/option/box',
        method: 'get',
        data
    })
}

// 下拉相关数据
export function search(params) {
    return axios({
        url: otc + '/v3/public/merchant/ad/search',
        method: 'get',
        params
    })
}

export function AllList(url, params) {
    return axios({
        url: otc + url,
        method: 'get',
        params
    })
}
export function getOrderList(params) {
    return axios({
        url: otc + '/v3/order/list',
        params,
        method: 'get',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
}
// 获取用户信息
export function c2cUserInfo() {
    return axios({
        url: otc + '/v3/user/info',
        method: 'get'

    })
}
//获取用户的信息
export function c2cUserOrderInfo(params) {
    return axios({
        url: otc + '/v3/public/user/transaction-data',
        method: 'get',
        params
    })
}
//获取用户的对应的币种的广告
export function c2cUserInfoMessage(params) {
    return axios({
        url: otc + '/v3/public/merchant/all/ad',
        method: 'get',
        params
    })
}
//获取系统配置
export function c2cSystemConfig() {
    return axios({
        url: otc + '/v3/public/config/basics-config',
        method: 'get'
    })
}
//获取用户的所有支付方式
export function c2cPaymentMethods(params) {
    return axios({
        url: otc + '/v3/user-payment/requirement',
        method: 'get',
        params,

    })
}
//上传单个图片
export function uploadImg(data) {
    return axios({
        url: otc + '/v3/upload/img',
        method: 'post',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}
//常用选项接口
export function c2coption(data) {
    return axios({
        url: otc + '/v3/public/option/box',
        method: 'get',
        data
    })
}
//获取自定义支付方式
export function c2cPaymentDetail(data) {
    return axios({
        url: otc + '/v3/public/payment/detail',
        method: 'get',
        data,
    })
}
//删除用户的收付款方式

export function delc2cUserCollection(id) {
    return axios({
        url: otc + `/v3/user-payment/unbind/${id}`,
        method: 'put',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
}

//添加用户的收款方式

export function c2cAdduserCollection(data) {
    return axios({
        url: otc + '/v3/user-payment/save',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
}

//查询成为商户的检验条件
export function c2cCheckUserBeMerchant() {
    return axios({
        url: otc + '/v3/merchant/requirement',
        method: 'get',
    })
}
export function c2cMerchantDetail() {
    return axios({
        url: otc + '/v3/merchant/detail',
        method: 'get'
    })
}

//获取手机号的区号
export function c2cAreaCode(params) {
    return axios({
        url: otc + '/v3/public/option/box',
        method: 'get',
        params
    })
}
//上传视频
export function c2cVideoUpload(data) {
    return axios({
        url: otc + '/v3/upload/video',
        method: 'post',
        data,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
//申请成为商户
export function c2cMerchantApply(data) {
    return axios({
        url: otc + '/v3/merchant/apply',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
}

export function c2cSetNickName(data) {
    return axios({
        url: otc + '/v3/user/nickname-change',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}

export function c2cRelease() {
    return axios({
        url: otc + '/v3/merchant/apply-refund',
        method: 'put',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}
//获取自己的用户交易记录
export function getMyselfInfo() {
    return axios({
        url: otc + '/v3/public/user/oneself/transaction-data',
        method: 'get',
        headers: { 'Content-Type': 'userId' }
    })
}
export function createOrderNewApi(data) {
    return axios({
        url: otc + '/v3/order/proceed',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
}

export function faitPayment(data) {
    return axios({
        url: otc + '/v3/public/option/classify-payment/single',
        method: 'get',
        data
    })
}

//第三方页面法币接口

export function thirdParty_faitList(data) {
    return axios({
        url: otc + '/v3/public/third-party/fiat-list',
        method: 'get',
        data
    })
}
//第三方页面币种接口

export function thirdParty_currencyList(data) {
    return axios({
        url: otc + '/v3/public/third-party/currency-list',
        method: 'get',
        data
    })
}
// 收付款列表
export function  thirdParty_payList(data){
    return axios({
        url: otc + '/v3/public/third-party/payment-list',
        method: 'get',
        data
    })
}
//获取支付渠道列表

export function  thirdParty_channelList(data){
    return axios({
        url: otc + '/v3/public/third-party/supplier-list',
        method: 'get',
        data
    })
}
// 第三方下单

export function placeAnOrder(data){
    return axios({
        url: otc + '/v3/third-party/order',
        method: 'post',
        data
    })
}

//获取用户资产

export  function getUserAmount(data){
    return axios({
        url:  '/spot/spot/balance/available',
        method: 'get',
        data
    })
}
