var wallet = '/wallet'
import axios from "@/utils/axios";
// 获取可划转类型
export function targetList(data) {
    return axios({
        url: wallet + '/account-transfers/target',
        method: 'get',
        data
    })
}

// 获取划转目标可用币种
export function targetCoin(data) {
    return axios({
        url: wallet + '/account-transfers/target-currency',
        method: 'get',
        data
    })
}

// 获取余额
export function balanceData(data) {
    return axios({
        url: wallet + '/account-transfers/balance',
        method: 'get',
        data
    })
}

// 划转
export function transferApi(data) {
    return axios({
        url: wallet + '/account-transfers',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
}
