import axios from "@/utils/axios";

var resource = '/resource'

// 获取Banner
export function getBanner (data) {
    return axios({
        url: resource + '/banners',
        method: 'get',
        data
    })
}

// 获取公告
export function getNotice (data) {
    return axios({
        url: resource + '/notice/news',
        method: 'get',
        data
    })
}

// 新手指导
export function getList () {
    return axios({
        url: resource + '/guides',
        method: 'get'
    })
}


// 获取下载版本信息
export function versionsApi () {
    return axios({
        url: resource + '/versions/web',
        method: 'get'
    })
}

// 币种简介
export function currencyInfo (data) {
    return axios({
        url: resource + '/currency',
        method: 'get',
        data
    })
}

// 币种k线
export function getKine (data) {
    return axios({
        url: resource + '/currency/market/kline',
        method: 'get',
        data
    })
}

// 热门代币
export function getMarket (data) {
    return axios({
        url: resource + '/currency/market',
        method: 'get',
        data
    })
}

// 涨幅榜-跌幅榜
export function getMarketChange (data) {
    return axios({
        url: resource + '/currency/market/change',
        method: 'get',
        data
    })
}

// 活动信息
export function taskCenterActivity () {
    return axios({
        url: resource + '/task/center/activity',
        method: 'get'
    })
}

// 任务中心--未登录
export function taskList () {
    return axios({
        url: resource + '/task/center/task',
        method: 'get'
    })
}

// 任务中心--已登录
export function taskListLogin () {
    return axios({
        url: resource + '/task/center/task/user',
        method: 'get'
    })
}

// 用户上传信息采集
export function infoUpload (data) {
    return axios({
        url: resource + '/task/center/toupload',
        method: 'post',
        data
    })
}

// 奖励金额
export function myAward () {
    return axios({
        url: resource + '/task/center/award',
        method: 'get',
    })
}

// 领取奖励
export function receiveRewards (data) {
    return axios({
        url: resource + '/task/center/receive/award',
        method: 'post',
        data
    })
}

export function linksApi (data) {
    return axios({
        url: resource + '/contacts',
        method: 'get',
        data
    })
}

export function countryApi () {
    return axios({
        url: resource + '/dao-members/areas',
        method: 'get'
    })
}

export function membersApi (data) {
    return axios({
        url: resource + '/dao-members',
        method: 'get',
        data
    })
}

//  盲盒抽奖
export function blindBoxInfo () {
    return axios({
        url: resource + '/blind-box/info',
        method: 'get'
    })
}

// 抽奖数据概览
export function blindSummary () {
    return axios({
        url: resource + '/blind-box/summary',
        method: 'get'
    })
}

// 已获得的奖励列表
export function awardsList (data) {
    return axios({
        url: resource + '/blind-box/details',
        method: 'get',
        data,
    })
}

// 抽奖
export function drawApi () {
    return axios({
        url: resource + '/blind-box/draw',
        method: 'post'
    })
}

// 卡券管理
export function cardApi () {
    return axios({
        url: resource + '/sign/card',
        method: 'get'
    })
}

// 签到配置
export function getSignConfig () {
    return axios({
        url: resource + '/sign/config',
        method: 'get'
    })
}

// 签到我的奖励
export function getSignList () {
    return axios({
        url: resource + '/sign/reward-log',
        method: 'get'
    })
}

// 中奖名单
export function getWinnerList () {
    return axios({
        url: resource + '/sign/winner-list',
        method: 'get'
    })
}

// 补签
export function countersign (data) {
    return axios({
        url: resource + '/sign/countersign',
        data,
        method: 'post',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
}

// 用户签到信息
export function signInfo () {
    return axios({
        url: resource + '/sign/sign-info',
        method: 'get'
    })
}

// 补签-用户签到记录查询
export function signLog () {
    return axios({
        url: resource + '/sign/sign-log',
        method: 'get'
    })
}

// 签到
export function setSign () {
    return axios({
        url: resource + '/sign',
        method: 'post',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
}

// 抵扣记录
export function cardLogsApi (data) {
    return axios({
        url: resource + '/sign/card-deduction',
        method: 'get',
        data
    })
}

// 文章类别查询
export function articleCategory (data) {
    return axios({
        url: resource + '/college/article/categorys',
        method: 'get',
        data
    })
}

// 获取文章详情
export function articleDetail (data) {
    return axios({
        url: resource + '/college/article/detail',
        method: 'get',
        data
    })
}

// 获取文章列表
export function articleList (data) {
    return axios({
        url: resource + '/college/articles',
        method: 'get',
        data
    })
}

// 活动视频
export function videoApi () {
    return axios({
        url: resource + '/college/video',
        method: 'get'
    })
}

//获取世界杯配置
export function worldCupconfig () {
    return axios({
        url: resource + "/world-cup/config",
        method: 'get'
    })
}

//获取世界杯小组赛信息
export function worldcupgroupmsg () {
    return axios({
        url: resource + '/world-cup/group-stage',
        method: "get"
    })
}

//获取投注记录信息
export function getBettingmsg (data) {
    return axios({
        url: resource + '/world-cup/bets',
        method: "get",
        data
    })
}

//投注
export function Betting (data) {
    return axios({
        url: resource + '/world-cup/bets',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
}

//获取淘汰赛信息
export function knockoutConfig (data) {
    return axios({
        url: resource + "/world-cup/knockout",
        method: 'get',
        data
    })
}

export function ieoList (data) {
    return axios({
        url: '/activity/v3/public/ieos',
        method: 'get',
        data
    })
}

export function ieoOrderApi (data) {
    return axios({
        url: '/activity/v3/orders',
        method: 'get',
        data
    })
}
export function ieoDetailApi (id) {
    return axios({
        url: `/activity/v3/public/ieos/${id}`,
        method: 'get'
    })
}
export function subscriptionApi (data) {
    return axios({
        url: `/activity/v3/orders`,
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
}

// 申购推荐
export function recommendApi (data) {
    return axios({
        url: `/activity/v3/orders/recommend`,
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
}


// 获取banner配置
export function getBannerConfig () {
    return axios({
        url: resource + '/v3/public/web-banner',
        method: 'get'
    })
}

export function getPopupConfig(data) {
  return axios({
    url: resource + '/v3/public/popups',
    method: 'get',
    data
  })
}
export  function getActivityLoop(){
    return axios({
        url:resource+'/v3/public/notice',
        method:'get'
    })
}

export function getActivityList(data){
    return axios({
        url:resource + '/v3/public/event',
        data,
        methods:'get'
    })
}

