<!--
 * @Description: 站点导航组件
 * @Author: F-Stone
 * @LastEditTime: 2023-10-17 19:00:45
-->
<template>
    <div
        :class="[$style['m-site-nav'], headType ? $style['m-site'] : '', styleType == 'sunlight' ? $style['themSunlight'] : '']">
        <button :class="$style['btn--close-fold-nav']" class="flex justify-center items-center" @click="close()">
            <UeIcon :class="$style['ic']" name="x-close" />
        </button>
        <div :class="$style['m-site-nav--inner']">
            <div ref="scrollBox" :class="$style['scroll-box']">
                <div v-for="(item, index) in navList" :key="index" :class="$style['nav-item']"
                    :data-active="index === currentOpenFoldIndex">
                    <template v-if="item.sub">
                        <div :class="$style['item-inner']" class="flex items-center" @click="changeCurrentOpen(index)">
                            <span :class="$style['text']"> {{ item.title }}</span>
                            <UeIcon :class="$style['ic']" name="arrow-down" />
                        </div>
                        <SiteNavFoldSub :headType="headType" :class="$style['nav-item--sub']" :sub-nav-list="item.sub"
                            :open="index === currentOpenFoldIndex" />
                    </template>
                    <a v-else :href="`${linkUrl+item.link}`" :class="$style['item-inner']">
                        <span class="text"> {{ item.title }}</span>
                    </a>
                </div>
                <div @click="changeCurrentOpen(999)" :class="[$style['nav-item'], $style['btn--show-service']]">
                    <div :class="$style['dot-group']" class="grid grid-cols-3">
                        <template v-for="i in 3">
                            <div v-for="j in 3" :key="i + '-' + j" :class="$style['dot']" :style="{
                                '--delay': i + j - 2,
                            }"></div>
                        </template>
                    </div>
                </div>
                <SiteNavFoldSub :open="999 === currentOpenFoldIndex" :headType="headType" :class="$style['nav-item--sub']"
                    distance="20px" :sub-nav-list="serviceNavList" />
            </div>
        </div>
    </div>
</template>
<script>
import mitt from "mitt";
import SiteNavFoldSub from "./SiteNavFoldSub.vue";
import { mapGetters } from 'vuex'
import Scrollbar from "smooth-scrollbar";
import urls from '@/utils/baseUrl'
const mittManage = mitt();
export default {
    name: '',
    components: {
        SiteNavFoldSub
    },
    props: {
        navList: {
            type: Array,
            default: []
        },
        headType: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentOpenFoldIndex: -1,
            scrollCtrl: null,
            linkUrl: urls.linkUrl,
        }
    },
    computed: {
        ...mapGetters([
            'chainConfig',
            'look',
            'currency',
            'languageName',
            'token',
            'userInfo',
            'levelConfig',
            'styleType',
            'language',
            'isDirection',
            'walletAddress',
            'walletChainId',
        ]),
        serviceNavList() {
            return [
                {
                    icon: "icon-a-DAOnetwork",
                    title: this.$t('400234'),  //DAO 网络
                    desc:  this.$t('122000'),
                    link: "/dao",
                    type: 1
                },
                {
                    icon: "icon-Invite",
                    title: this.$t('400236'), //邀请好友
                    desc:  this.$t('122001'),
                    link: "/userCenter/commission",
                    type: 1
                },
                {
                    icon: "icon-Roadmap1",
                    title: this.$t('400235'), //Roadmap
                    desc:  this.$t('122002'),
                    link: "/roadmap",
                    type: 1
                },
                {
                    icon: "icon-a-HelpCenter",
                    title: this.$t('400237'), //帮助中心
                    desc:  this.$t('122003'),
                    link: this.language == 'zh-TC'
                        ? 'https://support.superex.com/hc/zh-hk'
                        : this.language == 'ko'
                            ? 'https://support.superex.com/hc/ko'
                            : 'https://support.superex.com/hc/en-001',
                    type: 2
                },
                {
                    icon: "icon-a-TaskCenter",
                    title: this.$t('400245'), //任务中心
                    desc:  this.$t('122004'),
                    link: "/noviceGuide/sevenDay",
                    type: 1
                },
                {
                    icon: "icon-a-ETZone",
                    title: this.$t('400291'), // ET 专区
                    desc:  this.$t('122005'),
                    link: "/et",
                    type: 1
                },
                {
                    icon: "icon-manghe1",
                    title: this.$t('122028'), // 盲盒
                    desc:  this.$t('122027'),
                    link: "/activity/lottery",
                    type: 1
                },
                {
                    icon: "icon-dailishang",
                    title: this.$t('410001'), // 代理商
                    desc:  this.$t('122007'),
                    link: "",
                    type: 4
                },
            ]
        },
    },
    watch: {
    },
    async created() {
    },
    methods: {
        changeCurrentOpen(index) {
            if (this.currentOpenFoldIndex === index) {
                this.currentOpenFoldIndex = -1;
            } else {
                this.currentOpenFoldIndex = index;
            }
        },
        createScrollBar() {
            const scrollBox = this.$refs.scrollBox
            if (!scrollBox) return;
            this.scrollCtrl = Scrollbar.init(scrollBox, {
                continuousScrolling: false,
                thumbMinSize: 4,
            });
            mittManage.on("destroy", () => {
                this.scrollCtrl.destroy();
            });
        },
        close() {
            this.$emit("closeFoldNav", "")
        }
    },
    mounted() {
        this.createScrollBar();
    },
    destroyed() {
    },
    beforeDestroy() {
        mittManage.emit("destroy");
        mittManage.all.clear();
    }
}
</script>
<style lang="scss" module>
@import "../../assets/css/uemo-scss/scss/abstracts/mixins";
@import "../../assets/css/uemo-scss/scss/abstracts/_breakpoint";
@import '../../assets/css/mixin';

.m-site-nav {
    --nav-fold--inner-y: 10px;
    --opacity: 0;
    visibility: hidden;

    width: 290px;
    padding-top: 23px;

    transition: 0.36s ease;

    &.m-site {
        &.themSunlight {
            .m-site-nav--inner {
                background-color: rgba(255, 255, 255, 1);
            }

            .nav-item {
                .item-inner {
                    @include font_color('font-color-2');

                    &:hover {
                        color: #ef9200
                    }

                    .text {
                        @include font_color('font-color-2');

                        &:hover {
                            color: #ef9200
                        }
                    }
                }

                &[data-active] {
                    .item-inner {
                        color: #ef9200 !important
                    }

                    .text {
                        color: #ef9200 !important
                    }
                }
            }


            .btn--show-service {
                position: relative;
                cursor: pointer;
                z-index: 99;
                display: flex;
                align-items: center;
                padding: 16px 4px;

                .dot-group {
                    gap: 4px;
                }

                .dot {
                    @include background_color('font-color');
                    transition: 0.12s ease;
                    transition-delay: calc(var(--delay) * 0.06s);
                }

                &:hover {
                    .dot {
                        background-color: #ef9200;
                    }

                    .service-nav {
                        --nav-sub--inner-y: 0;
                        --opacity: 1;

                        visibility: visible;

                        pointer-events: initial;

                        opacity: 1;
                    }
                }
            }
        }
    }

    .btn--close-fold-nav {
        @include square(32px);
        font-size: 20px;

        position: absolute;
        z-index: var(--g--z-index);
        top: 43px;
        right: 30px;

        transition: 0.36s ease;
        transform: translateY(var(--nav-fold--inner-y));

        opacity: var(--opacity);
        border-radius: 50%;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    :global {
        .scrollbar-track {
            width: 4px;

            background-color: transparent;
        }

        .scrollbar-thumb {
            background-color: color(var(--g--color-bg--default), 1);
        }

        .scrollbar-track-x {
            display: none;
        }
    }
}

.m-site-nav--inner {
    position: relative;
    top: var(--nav-fold--inner-y);

    width: var(--width);
    padding: 46px 0;

    transition: 0.36s ease;

    opacity: var(--opacity);
    color: color(var(--g--color-font--default), 0.6);
    border-radius: 24px;
    background: rgba(22, 23, 25, 0.8);
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
        0 12px 16px -4px rgba(16, 24, 40, 0.08);

    backdrop-filter: blur(25px);

    .scroll-box {
        overflow: auto;

        max-height: calc(100vh - 200px);
        padding: 20px;
    }

    .btn--show-service {
        position: relative;
        cursor: pointer;
        z-index: 99;
        display: flex;
        align-items: center;
        padding: 16px 4px;

        .dot-group {
            gap: 4px;
        }

        .dot {
            @include circle(4px, rgba(var(--g--color-font--default), 1));
            transition: 0.12s ease;
            transition-delay: calc(var(--delay) * 0.06s);
        }

        &:hover {
            .dot {
                background-color: #ef9200;
            }

            .service-nav {
                --nav-sub--inner-y: 0;
                --opacity: 1;

                visibility: visible;

                pointer-events: initial;

                opacity: 1;
            }
        }
    }

    .nav-item {
        // display: block;

        &:last-child {
            margin: 0;
        }

        a.item-inner {
            display: block;
        }

        .item-inner {
            height: 100%;
            padding: 16px 0;
            cursor: pointer;
            transition: 0.26s ease;
            gap: 6px;
            color: #A2A2A3;
        }

        .ic {
            font-size: 20px;
        }

        &[data-active],
        &:hover {
            .item-inner {
                color: #ef9200
            }
        }
    }
}
</style>
