// import {initializeApp} from "firebase/app";
import Vue from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
// import firebase from "firebase/compat";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";
// console.log(firebase)
const firebaseConfig = {
    // 在这里填写你的 Firebase 配置信息
    apiKey: "AIzaSyBjMKJxZwnWaYroe6IwI_5WZzj7wGacEaM",
    authDomain: "app2-77eb6.firebaseapp.com",
    projectId: "app2-77eb6",
    storageBucket: "app2-77eb6.appspot.com",
    messagingSenderId: "704492455359",
    appId: "1:704492455359:web:2b8b69cd4678df57e2cd0a",
    measurementId: "G-DH7F4M2SVT"
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
Vue.prototype.$firebase = analytics;
// export const provider = new GoogleAuthProvider();
// export const auth = getAuth(app);
// provider.setCustomParameters({ prompt: 'select_account' });
// 在页面加载时显示登录界面
// export const siginwithgoole = () =>{signInWithPopup(auth, provider)
//     .then((result) => {
//         // This gives you a Google Access Token. You can use it to access the Google API.
//         const credential = GoogleAuthProvider.credentialFromResult(result);
//         const token = credential.accessToken;
//         // The signed-in user info.
//         const user = result.user;
//         // IdP data available using getAdditionalUserInfo(result)
//         console.log(result,'成功')
//         // ...
//     }).catch((error) => {
//     // Handle Errors here.
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // The email of the user's account used.
//     const email = error.customData.email;
//     // The AuthCredential type that was used.
//     const credential = GoogleAuthProvider.credentialFromError(error);
//     console.log('失败')
//     // ...
// });
// }
