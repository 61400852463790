<!--
 * @Description: 二级导航组件
 * @Author: F-Stone
 * @LastEditTime: 2023-10-17 18:35:31
-->

<template>
    <div ref="dom"
        :class="[$style['site-nav--sub'], headType ? $style['site-nav'] : '', styleType == 'sunlight' ? $style['themSunlight'] : '']">
        <div :class="$style['site-nav--sub-inner']" class="grid">
            <div v-for="(subItem, subIndex) in subNavList" :key="subIndex" :class="$style['nav-sub-item']">
                <a :class="$style['item-inner']" @click="toLink(subItem)" class="grid">
                    <div :class="$style['item-icon']">
                        <!-- <UeIcon v-if="subItem.icon" :name="subItem.icon" /> -->
                        <Exicon v-if="subItem.icon" :name="subItem.icon" :size="24" />
                    </div>
                    <div :class="$style['item-info']">
                        <div :class="$style['item-title']">
                            <span :class="$style['text']">
                                {{ subItem.title }}
                            </span>
                        </div>
                        <div :class="$style['item-desc']">
                            <span :class="$style['text']">
                                {{ subItem.desc }}
                            </span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import mitt from "mitt";
import $ from 'jquery';
import { mapGetters } from 'vuex'
import { checkIsAgentState } from '@/apis/agent.js'
import { videoApi } from '@/apis/resource'
import { getC2cUserInfo } from '@/apis/otc.js'
import { getToken } from '@/utils/common'
import {
    telephoneShow,
    emailShow,
    parseTime,
    ellipseAddress,
} from '@/plugins/methods'
import { gsap } from '../../utils/gsap'
import urls from '@/utils/baseUrl'
export default {
    name: '',
    components: {

    },
    props: {
        subNavList: {
            type: Array,
            default: []
        },
        open: {
            type: Boolean,
            default: false
        },
        headType: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            c2cUserInfo: {},
            linkUrl: urls.linkUrl,
        }
    },
    computed: {
        ...mapGetters([
            'chainConfig',
            'look',
            'currency',
            'languageName',
            'token',
            'userInfo',
            'levelConfig',
            'styleType',
            'language',
            'isDirection',
            'walletAddress',
            'walletChainId',
        ]),
    },
    watch: {
        open(isActive) {
            const dom = this.$refs.dom
            gsap.to(dom, {
                duration: 0.26,
                height: () => {
                    if (!isActive) return 0;
                    return dom.scrollHeight;
                },
            });
        }
    },
    async created() {
    },
    methods: {
        toLink(data) {
            // location跳转 
            if (data.type === 1) {
                window.location.href = this.linkUrl + data.link
                return
            }
            // Open跳转
            if (data.type === 2) {
                window.open(data.link)
                return
            }
            //站内跳转
            if (data.type === 3) {
                this.$router.push(data.link)
                // window.location.href = data.link
                return
            }
            // 代理商的跳转
            if (data.type === 4) {
                this.goAgent()
                return
            }
            // 学院视频的跳转
            if (data.type === 5) {
                this.getVideo()
                return
            }
            // 身份认证跳转
            if (data.type === 6) {
                if (this.token) {
                    this.getC2cUserInfo()
                }
                return
            }
            // 钱包断开连接
            if (data.type === 7) {
                this.$parent.handleWalletLogout()
            }
            // 账号密码退出登录
            if (data.type === 8) {
                this.$store.dispatch('LogOut', getToken() ? getToken() : '')
                this.$route.path == '/thirdParty' ? this.$router.push('/thirdParty') : this.$router.push('/')
                sessionStorage.removeItem('isTrue')
            }
        },
        // 手机号处理
        telephoneShow(phone) {
            return phone ? telephoneShow(phone) : ''
        },
        // 邮箱地址处理
        emailShow(email) {
            return email ? emailShow(email) : ''
        },
        // 钱包地址处理
        ellipseAddress(str) {
            return ellipseAddress(str)
        },
        // 身份认证
        getC2cUserInfo() {
            getC2cUserInfo().then((res) => {
                this.c2cUserInfo = res.data
                let Url = this.c2cUserInfo.isAuth ? '/userCenter/complete' : '/userCenter/identity'
                window.location.href = this.linkUrl + Url
            })
        },
        // 代理商
        goAgent() {
            if (!this.token) {
                window.location.href = this.linkUrl + '/agentSystem/agentHome'
                return
            }
            checkIsAgentState()
                .then((res) => {
                    this.agentStatus = res.data.status
                    // this.agentCode = res.code
                    console.log(res, '200了哦')
                    if (this.agentStatus === 1) {
                        window.location.href = this.linkUrl + '/agentSystem/channel'
                        // this.$router.push('/agentSystem/channel')
                    } else {
                        window.location.href = this.linkUrl + '/agentSystem/agentHome'
                        // this.$router.push('/agentSystem/agentHome')
                    }
                })
                .catch((err) => {
                    console.log(err.code, '返回了500哦')
                    window.location.href = this.linkUrl + '/agentSystem/agentHome'
                    // this.$router.push('/agentSystem/agentHome')
                })
        },
        // 视频
        getVideo() {
            videoApi().then((res) => {
                const videoLink = res.data
                if (videoLink) {
                    window.open(videoLink)
                } else {
                    this.$notify({
                        message: this.$t('101114'), // 该地址不存在
                        iconClass: 'iconfont icon-cuowu1',
                        customClass: 'errorIcon flex-center',
                        showClose: false,
                        duration: 2000,
                        position: this.IsShow() ? 'top-left' : 'top-right',
                    })
                }
            })
        },
    },
    mounted() {

    },
    destroyed() {
    },
    beforeDestroy() {
    }
}

</script>
<style lang="scss" module>
@import "../../assets/css/uemo-scss/scss/abstracts/mixins";
@import "../../assets/css/uemo-scss/scss/abstracts/_breakpoint";

[dir='ltr'] .site-nav--sub {}

[dir='rtl'] .site-nav--sub {
    .item-title {
        text-align: right;
    }

    .item-desc {
        text-align: right;
    }
}

.site-nav--sub {
    overflow: hidden;

    height: 0;
}

.site-nav--sub-inner {
    padding: 16px 0;

    gap: 26px;
}


.site-nav {
    &.themSunlight {
        .site-nav--sub-inner {
            background-color: rgba(255, 255, 255, 0.8);
        }

        .nav-sub-item {
            @supports (background-clip: text) {
                .item-icon {
                    background: linear-gradient(90deg,
                            rgba(var(--g--color-theme--main), 1) 0%,
                            #ffde68 50%,
                            rgba(#7F8490, 0.6) 50%);
                }

                .item-title .text {
                    background: linear-gradient(90deg,
                            rgba(var(--g--color-theme--main), 1) 0%,
                            #ffde68 50%,
                            #000922 50%);
                }

                .item-icon,
                .item-title .text {
                    transition: 0.16s ease;

                    background-position: 100% 0;
                    background-clip: text;
                    background-size: 200% 100%;

                    -webkit-text-fill-color: transparent;
                }

                &:hover {
                    .item-title .text {
                        transition-delay: 0.08s;

                        background-position: 0 0;
                    }

                    .item-icon {
                        transition-delay: 0s;

                        background-position: 0 0;
                    }
                }
            }

            @supports not (background-clip: text) {

                .item-icon,
                .item-title .text {
                    transition: 0.16s ease;
                }

                &:hover {

                    .item-title .text,
                    .item-icon {
                        color: rgba(var(--g--color-theme--main), 1);
                    }
                }
            }

            padding: 12px;

            .item-inner {
                gap: 16px;
                grid-template-columns: auto 1fr;
            }

            .item-icon {
                font-size: 24px;

                transition-delay: 0.08s;
            }

            .item-title {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.6;
                white-space: nowrap;
                text-align: left;
            }

            .item-desc {
                font-size: 12px;
                line-height: 1.4;
                margin-top: 12px;
                color: #000922;
                white-space: pre-wrap;
                text-align: left;
            }
        }
    }
}



.nav-sub-item {
    @supports (background-clip: text) {
        .item-icon {
            background: linear-gradient(90deg,
                    rgba(var(--g--color-theme--main), 1) 0%,
                    #ffde68 50%,
                    rgba(#fff, 0.6) 50%);
        }

        .item-title .text {
            background: linear-gradient(90deg,
                    rgba(var(--g--color-theme--main), 1) 0%,
                    #ffde68 50%,
                    #fff 50%);
        }

        .item-icon,
        .item-title .text {
            transition: 0.16s ease;

            background-position: 100% 0;
            background-clip: text;
            background-size: 200% 100%;

            -webkit-text-fill-color: transparent;
        }

        &:hover {
            .item-title .text {
                transition-delay: 0.08s;

                background-position: 0 0;
            }

            .item-icon {
                transition-delay: 0s;

                background-position: 0 0;
            }
        }
    }

    @supports not (background-clip: text) {

        .item-icon,
        .item-title .text {
            transition: 0.16s ease;
        }

        &:hover {

            .item-title .text,
            .item-icon {
                color: rgba(var(--g--color-theme--main), 1);
            }
        }
    }

    padding: 12px;

    .item-inner {
        gap: 16px;
        grid-template-columns: auto 1fr;
    }

    .item-icon {
        font-size: 24px;

        transition-delay: 0.08s;
    }

    .item-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.6;
        white-space: nowrap;
        text-align: left;
    }

    .item-desc {
        font-size: 12px;
        line-height: 1.4;
        margin-top: 12px;
        color: rgba(var(--g--color-font--default), 0.5);
        white-space: pre-wrap;
        text-align: left;
    }
}
</style>
