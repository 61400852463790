import en from './en';
import zh from './zh';
import kr from './kr';
import zhHK from "./zhHK";
import ja from "./ja"
import ru from './ru'
import fa from './fa'
import uk from './uk'
import es from './es'
import vi from './vi'
import fr from './fr'
import pt from './pt'
export default {
    en: {...en},
    'zh-CN': {...zh},
    ko: {...kr},
    'zh-TC': {...zhHK},
    'zh-Hk': {...zhHK},
    ja: {...ja},
    ru: {...ru},
    fa: {...fa},
    uk: {...uk},
    es: {...es},
    vi: {...vi},
    fr: {...fr},
    pt: {...pt}
}
