<template>
  <div class="loading-model">
    <img style="width: 90px;height: 90px;" :src="staticState+'/static/images/loding1.gif'" alt />
  </div>
</template>

<script>
import urls from "@/utils/baseUrl";
export default {
  name: "Loading",
  data() {
    return {
      staticState: urls.staticState
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/variable.scss";
@import "../../assets/css/mixin";
.loading-model {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 60px;
  align-items: center;
  display: flex;
  z-index: 99;
  @include background_color("background-color-2");
  img {
    display: block;
    margin: auto;
  }
}
</style>
