import Cookies from 'js-cookie'
// import config from '../../config'
const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
  // return !config.isDebug ? Cookies.get(TokenKey) : config.token
}

export function setToken(token) {
  var url = window.location.host
  var Domain = url.slice(url.indexOf('.'))
  if (process.env.NODE_ENV == 'development') {
    return Cookies.set(TokenKey, token, { expires: 7 })
  }

  if (url.split('.').length > 2) {
    return Cookies.set(TokenKey, token, { Domain: Domain, expires: 7 })
  } else {
    return Cookies.set(TokenKey, token, { Domain: `.${url}`, expires: 7 })
  }
}

export function removeToken() {
  var url = window.location.host
  var Domain = url.slice(url.indexOf('.'))
  if (process.env.NODE_ENV == 'development') {
    return Cookies.remove(TokenKey)
  }

  if (url.split('.').length > 2) {
    return Cookies.remove(TokenKey, { Domain: Domain, path: '/' })
  } else {
    return Cookies.remove(TokenKey, { Domain: `.${url}`, path: '/' })
  }
}


export function getCookie(name) {
  return Cookies.get(name)
}

export function setCookie(name, value) {
  var url = window.location.host
  var Domain = url.slice(url.indexOf('.'))
  if (process.env.NODE_ENV == 'development') {
    return Cookies.set(name, value, { expires: 7 })
  }

  if (url.split('.').length > 2) {
    return Cookies.set(name, value, { Domain: Domain, expires: 7 })
  } else {
    return Cookies.set(name, value, { Domain: `.${url}`, expires: 7 })
  }
}

export function removeCookie(name) {
  var url = window.location.host
  var Domain = url.slice(url.indexOf('.'))
  if (process.env.NODE_ENV == 'development') {
    return Cookies.remove(name)
  }
  if (url.split('.').length > 2) {
    return Cookies.remove(name, { Domain: Domain, path: '' })
  } else {
    return Cookies.remove(name, { Domain: `.${url}`, path: '' })
  }
}


export function getTrade(CurrecyKey) {
  var storage = window.localStorage;
  if (storage[CurrecyKey]) {
    return storage[CurrecyKey]
  } else {
    return null;
  }
}

export function setTrade(CurrecyKey, value) {
  var storage = window.localStorage;
  storage.setItem(CurrecyKey, value);
}

export function deleTrade(CurrecyKey) {
  var storage = window.localStorage;
  storage.removeItem(CurrecyKey);
}
export function ProcessingFun(ProcessingData) {
  let fromList = [];
  ProcessingData.forEach(item => {
    fromList.push({
      fieldName: item.fieldName,
      fieldPlaceholder: item.fieldPlaceholder,
      fieldType: item.fieldType,
      errorMsg: item.errorMsg,
      isRequired: item.isRequired,
      fieldKey: item.fieldKey,
      fieldValue: item.fieldValue,
      errorMsgStatus: false
    })
  })
  return fromList;
}

export const useInterval = (cb, delay, immediate = true) => {
  let id;
  const start = () => (id = setInterval(cb, delay));
  const cancel = () => clearInterval(id);
  const getId = () => id;
  if (immediate) cb();
  start();
  return {
    getId,
    cancel,
    start,
  };
};
