import Vue from 'vue'
import '@/utils/getCurrentLanguage'
import App from './App.vue'
import router from './router'
import store from './store'
import element from './element'
import i18n from "./i18n/i18n"
import '@/utils/firebase'
import '@/assets/css/common.css'
import '@/assets/css/elcss.scss'
import '@/assets/font/font.css'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element-variables.scss'
import IconSvg from '@/components/icon-svg'
import '@/permission'
import filter from './filter'
// Geetest init
import "./geet/gt.js"
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import MetaInfo from 'vue-meta-info';
import UeIcon from "./components/NewHome/UeIcon.vue"; //引入新首页公共ICON
import Exicon from "./components/NewHome/Exicon.vue"; //引入新首页公共ICON
import '@/assets/css/entry.scss' //引入新首页公共CSS
Vue.prototype.$video = Video  // 在vue的原生里添加了Video这个标签，增强了vue的功能性
import DirectiveLoading from './directiveLoading' //自定义loading
Vue.use(DirectiveLoading)
Vue.prototype.$initGeet = initGeetest4;
Vue.prototype.$EventBus = new Vue()
Vue.config.productionTip = false
Vue.use(element)
Vue.component('UeIcon', UeIcon)
Vue.component('Exicon', Exicon)
Vue.use(MetaInfo)

filter(Vue)
Vue.component('icon-svg', IconSvg)
new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
