const getters = {
    getEnum(state) {
        return key => {
            return state.enum[key]
        }
    },
    language: state => state.user.language,
    currency: state => state.user.currency,
    languageName: state => state.user.languageName,
    token: state => state.user.token,
    styleType: state => state.user.styleType,
    isDirection: state => state.user.isDirection,
    userInfo: state => state.user.userInfo,
    levelConfig: state => state.user.levelConfig,
    C2CUserInfo:state=>state.user.C2CUserInfo,
    invitationCode:state=>state.user.invitationCode
}
export default getters
