<template>
    <i :class="`iconfont  ${name}`" :style="`font-size:${size}px`"></i>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: Number,
            default: undefined
        },
        color: {
            type: String,
            default: undefined
        }
    }
};
</script>
<style lang="scss" scoped>
.iconfont {
    font-family: "ueicon";
    font-style: normal;
    line-height: 1;
    display: flex;
    width: 1em;
    height: 1em;
    font-size: 16px;
    align-items: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: center;
}
</style>
