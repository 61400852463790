import { getCookie, setCookie, getToken, setToken, removeToken } from '@/utils/common'
import { loginOut, getUserInfo, levelConfigApi } from "@/apis/user";
import { c2cUserInfo } from "@/apis/C2C";

const user = {
    state: {
        language: getCookie('language') ? getCookie('language') : 'en',
        // language: getCookie('language') && getCookie('language') == 'zh-TC' ? 'zh-TC' : 'en',
        currency: getCookie('currency') ? getCookie('currency') : 'usd',  // 汇率
        currencyIcon: getCookie('currencyIcon') ? getCookie('currencyIcon') : '$', // 汇率符
        // languageName: getCookie('languageName') ? getCookie('languageName') : 'English',
        languageName: getCookie('languageName') && getCookie('language') == 'zh-TC' ? '繁體中文' : 'English',
        token: getToken() ? getToken() : false,
        styleType: getCookie('styleType') ? getCookie('styleType') : 'sunlight',   // 默认白天模式
        isDirection: getCookie('language') && getCookie('language') == 'fa' ? true : false,
        // isDirection: false,
        C2CUserInfo: {},
        userInfo: {},
        levelConfig: {},
        isMerchant: false,
        invitationCode:getCookie('invitationCode') || ''
        // styleType: 'sunlight'
    },
    mutations: {
        SET_LANGUAGE: (state, language) => {
            state.language = language
        },
        SET_CURRENCY: (state, currency) => {
            state.currency = currency
            setCookie('currency', currency)
        },
        SET_CURRENCYICON: (state, currencyIcon) => {
            state.currencyIcon = currencyIcon
            setCookie('currencyIcon', currencyIcon)
        },
        SET_LANGUAGENAME: (state, languageName) => {
            state.languageName = languageName
            setCookie('languageName', languageName)
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_STYLE_TYPE: (state, data) => {
            state.styleType = data
        },
        SET_C2C_USER_INFO: (state, data) => {
            state.C2CUserInfo = data
        },
        SET_USERDATA: (state, data) => {
            state.userInfo = data
        },
        SET_LEVELCONFIG: (state, data) => {
            state.levelConfig = data
        },
        SET_INVITATIONCODE: (state,data)=>{
            setCookie('invitationCode',data)
            state.invitationCode = data
        }
    },
    actions: {
        setInvitationCode({commit},data) {
            console.log(data,'121212')
          return new Promise(()=>{
              commit('SET_INVITATIONCODE',data)
          })
        },
        // 设置主题模式
        setStyleType({ commit }, data) {
            return new Promise(() => {
                commit('SET_STYLE_TYPE', data)
            })
        },
        // 登出
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                loginOut(state.token).then((res) => {
                    commit('SET_TOKEN', '')
                    removeToken()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                commit('SET_USERDATA', null)
                removeToken()
                resolve()
            })
        },
        setToken({ commit }, token) {
            return new Promise(resolve => {
                commit('SET_TOKEN', token)
                resolve()
            })
        },
        setLanguage({ commit }, language) {
            return new Promise(() => {
                commit('SET_LANGUAGE', language)
            })
        },
        // 获取等级配置
        getLevelConfigApi({ commit, state }) {
            return new Promise((resolve, reject) => {
                levelConfigApi().then(response => {
                    const data = response.data
                    commit('SET_LEVELCONFIG', data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setCurrency({ commit }, currency) {
            return new Promise(() => {
                commit('SET_CURRENCY', currency)
            })
        },
        setCurrencyIcon({ commit }, currencyIcon) {
            return new Promise(() => {
                commit('SET_CURRENCYICON', currencyIcon)
            })
        },
        setLanguageName({ commit }, languageName) {
            return new Promise(() => {
                commit('SET_LANGUAGENAME', languageName)
            })
        },
        // 获取C2C用户信息
        GetC2CUserInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                c2cUserInfo().then(response => {
                    const data = response.data
                    commit('SET_C2C_USER_INFO', data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getUserInfo().then(response => {
                    const data = response.data
                    commit('SET_USERDATA', data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

    }
}
export default user
