<template>
    <div class="cookie" v-if="isShow">
        <div class="cookie-main">
            <div class="test">
                <!-- <p>By using the SuperEx sites and/or maintaining your account, you agree to our updated <a href="www.baidu.com">Terms of Service</a>, <a href="www.baidu.com">Privacy Statement</a> and <a href="www.baidu.com">Cookie Policy</a>. By clicking "X," you acknowledge you have read and agreed to the aforementioned Terms and Policies.</p> -->
                <p v-html="$t('400243')"></p>
            </div>
            <div class="close" @click="close">
                <i class="iconfont icon-guanbi3"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getCookie , setCookie } from "@/utils/common";
export default {
    name: 'cookie',
    data() {
        return {
            isShow: true,
            hasCookieStored: false, // cookie存储状态，默认为false
        }
    },
    watch: {
        $route: {
            immediate: true, // 立即执行回调函数
            handler(to, from) {        
                    this.isShow = getCookie('isShow') == 'false' ? false : true

            },
        },
    },
    created() {
        window.document.documentElement.setAttribute(
            'dir',
            this.isDirection ? 'rtl' : 'ltr'
        )
        window.document.documentElement.setAttribute(
            'data-theme',
            this.styleType
        )
        // 字符串转布尔
        // this.isShow = localStorage.getItem('isShow') == 'false' ? false : true
        this.isShow = getCookie('isShow') == 'false' ? false : true
        console.log(this.isShow)
    },
    mounted() {
        var hasCookie = document.cookie.includes('token=')
        this.hasCookieStored = hasCookie
        // console.log(this.token, '看看token')
        // console.log(this.hasCookieStored, '----cookie试试说是---mounted')
    },
    computed: {
        ...mapGetters(['token', 'styleType', 'isDirection']),
    },
    methods: {
        close() {
            const stateToStore = false
            // localStorage.setItem('isShow', JSON.stringify(stateToStore))
            setCookie('isShow', JSON.stringify(stateToStore))
            this.isShow = false
        },
    },
}
</script>
<style lang="scss" scoped>
@import '../assets/css/variable.scss';
@import '../assets/css/mixin';
.cookie {
    display: flex;
    align-items: center;
    min-width: max-content;
    width: 100%;
    height: 74px;
    @include background_color('background-color-2');
    z-index: 9999;
    &-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        @include font_color('font-color-2');
        word-wrap: break-word; /* 兼容旧版本浏览器 */
        overflow-wrap: break-word; /* 标准属性 */
        margin: 0 auto;
        .test {
            display: inline-block;
            width: 1145px;
            p{
                @include font_color('font-color-2');
            }
            // a {
            //     text-decoration: underline;
            //     @include font_color('font-color-2');
            //     font-weight: 600;
            // }
        }
        .close {
            display: inline-block;
            width: 14.93px;
            height: 14.93px;
            cursor: pointer;
        }
    }
}
</style>
